import { observable } from 'mobx';
import Model from '../Model';
import { ApplicantStore } from '../../stores/ApplicantStore';
import { ModelItem } from '../ModelItem';
import { GuestCard } from './GuestCardModel';
import { InviteType } from '../../enums/InviteType';
import { ApplicationStep } from '../ApplicationStep';
import { ModelList } from '../ModelList';
import { DocumentModel } from './DocumentModel';
import { Bank } from './BankModel';
import { ResidenceHistory } from './ResidenceHistory';
import { User } from './UserModel';

export class Applicant extends Model {
  static _store: ApplicantStore;

  @observable id: number;

  @observable user_id: number;

  @observable applicant_data_id: number;

  @observable first_name: string;

  @observable last_name: string;

  @observable application_id: number;

  @observable phone_number: number;

  @observable email: string;

  @observable is_primary: boolean;

  @observable role: InviteType;

  @observable guest_card_id: number;

  @observable documents = new ModelList<DocumentModel>(DocumentModel);

  @observable banks = new ModelList<Bank>(Bank);

  @observable residence_histories = new ModelList<ResidenceHistory>(
    ResidenceHistory,
  );

  @observable information_confirmed: boolean;

  @observable signed_document_url: string;

  @observable guestCard = new ModelItem<GuestCard>(GuestCard);

  @observable step: ApplicationStep;

  @observable terms_accepted_at: Date;

  get fullname(): string {
    if (this.last_name) {
      return `${this.first_name} ${this.last_name}`;
    }
    return this.first_name;
  }

  @observable sentilink_scores: any;

  @observable requirements: any;

  @observable organisation_id: number;

  @observable user = new ModelItem<User>(User);

  @observable additional_information: any;

  @observable crs_token_exists: boolean;

  @observable is_monitoring_user: boolean;
}
