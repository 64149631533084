import { action, observable } from 'mobx';
import { debounce } from 'lodash';
import Store from './Store';
import { ApplicationLogs } from '../models/entities/ApplicationLogsModel';
import { PaginatedModelList } from '../models/PaginatedModelList';
import ApiRoutes from '../routes/api/ApiRoutes';

export class ApplicationLogsStore extends Store<ApplicationLogs> {
  constructor() {
    super();
    ApplicationLogs._store = this;
  }

  @observable loading = false;

  @observable loadingMore = false;

  @observable loaded = false;

  @observable applicationLogsPaginatedList = new PaginatedModelList<
    ApplicationLogs
  >(ApplicationLogs);

  @action
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @action
  setLoadingMore(loading: boolean) {
    this.loadingMore = loading;
  }

  @action
  setLoaded(loaded: boolean) {
    this.loaded = loaded;
  }

  @action
  async fetchApplicationLogs(applicationId: number, force = false) {
    if (!this.loading) {
      if (force) {
        this.applicationLogsPaginatedList.setItems([]);
        this.setLoading(true);
      } else if (this.applicationLogsPaginatedList.items.length > 0) {
        this.setLoadingMore(true);
      }
      const fetchData = debounce(async () => {
        try {
          const response = await this.apiService.get(
            ApiRoutes.applications.logs.list(applicationId),
          );
          if (response && response.data) {
            this.applicationLogsPaginatedList.setItems(response.data);
            this.setLoading(false);
            this.setLoadingMore(false);
            this.setLoaded(true);
          }
        } catch (e) {
          this.setLoading(false);
        }
      }, 500);
      await fetchData();
    }
  }

  @action
  async createLog(applicationId: number, logBody: { type: string; meta: any }) {
    const response = await this.apiService.post(
      ApiRoutes.applications.logs.create(applicationId),
      logBody,
    );

    if (response && response.data) {
      this.applicationLogsPaginatedList.setItems([
        response.data,
        ...this.applicationLogsPaginatedList.items,
      ]);
    }
  }

  @action
  clearStore(): void {
    this.applicationLogsPaginatedList = new PaginatedModelList<ApplicationLogs>(
      ApplicationLogs,
    );
    this.setLoaded(false);
    this.setLoading(false);
  }
}
