import { observable } from 'mobx';
import { ApplicationLogsStore } from 'stores/ApplicationLogsStore';
import Model from '../Model';

export enum ApplicationLogType {
  INVITED = 'invited',
  INVITE_UPDATED = 'invite_updated',
  SIGNUP = 'signup',
  DOCUMENT_ADDED = 'document_added',
  DATA_RAW_SUBMITTED = 'data_raw_submitted',
  DATA_PROCESSED_RECEIVED = 'data_processed_received',
  STATUS_UPDATED = 'status_updated',
  INSURANCE_PLAN = 'insurance_plan',
  APPLICATION_UPDATED = 'application_updated',
  GENERATING_REPORT = 'generating_report',
  REQUESTING_ADDITIONAL_DOCUMENT = 'requesting_additional_document',
  SUBMITTING_ADDITIONAL_DOCUMENT = 'submitting_additional_document',
  CLONED = 'application_cloned',
}

export class ApplicationLogs extends Model {
  static _store: ApplicationLogsStore;

  @observable id: number;

  @observable application_id: number;

  @observable organisation_id: number;

  @observable type: ApplicationLogType;

  @observable logged_by: string;

  @observable logged_at: string;

  @observable metadata: JSON;
}
