import { observable } from 'mobx';
import Model from '../Model';
import { BuildingImageStore } from "../../stores/BuildingImageStore";

export class BuildingImage extends Model {
  static _store: BuildingImageStore;

  @observable id: number;

  @observable building_id: number;

  @observable url: string;
}
