import { action, observable } from 'mobx';
import ApiRoutes from '../routes/api/ApiRoutes';
import { PaginatedModelList } from '../models/PaginatedModelList';
import Store from './Store';
import { ResidenceHistory } from '../models/entities/ResidenceHistory';
import { ModelItem } from '../models/ModelItem';

export class ResidenceHistoryStore extends Store<ResidenceHistory> {
  constructor() {
    super();
    ResidenceHistory._store = this;
  }

  @observable residenceHistoryPaginatedList = new PaginatedModelList<
    ResidenceHistory
  >(ResidenceHistory);

  @observable residenceHistoryItem = new ModelItem<ResidenceHistory>(
    ResidenceHistory,
  );

  get residenceHistories() {
    return this.residenceHistoryPaginatedList.items;
  }

  get residenceHistory() {
    return this.residenceHistoryItem.item;
  }

  @action
  async fetchResidenceHistoryFromId(id: number) {
    return this.residenceHistoryItem.load(
      ApiRoutes.residenceHistories.show(id),
    );
  }

  @action
  async fetchResidenceHistories(params?: { [key: string]: any }) {
    return this.residenceHistoryPaginatedList.load(
      ApiRoutes.residenceHistories.list,
      params,
    );
  }

  @action
  async addResidenceHistory(body: { [key: string]: any }) {
    try {
      this.residenceHistoryPaginatedList.setLoading(true);
      const response = await this.apiService.post(
        ApiRoutes.residenceHistories.list,
        body,
      );
      const residenceHistory: ResidenceHistory = ResidenceHistory.fromJson(
        response.data,
      ) as ResidenceHistory;
      this.residenceHistoryPaginatedList.appendItem(residenceHistory);
      this.residenceHistoryItem.setItem(residenceHistory);
      this.residenceHistoryPaginatedList.setLoading(false);
      return residenceHistory;
    } catch (e) {
      this.residenceHistoryPaginatedList.setLoading(false);
      throw e;
    }
  }

  @action
  async deleteResidenceHistory(residenceHistory: ResidenceHistory) {
    residenceHistory.setDeleting(true);
    await this.apiService.delete(
      ApiRoutes.residenceHistories.show(residenceHistory.id),
    );
    residenceHistory.setDeleting(false);
    residenceHistory.delete();
  }

  @action
  async updateResidenceHistory(id: number, body: { [key: string]: any }) {
    try {
      this.residenceHistoryPaginatedList.setLoading(true);
      const response = await this.apiService.patch(
        ApiRoutes.residenceHistories.show(id),
        body,
      );
      const residenceHistory: ResidenceHistory = ResidenceHistory.fromJson(
        response.data,
      ) as ResidenceHistory;
      this.residenceHistoryItem.setItem(residenceHistory);
      this.residenceHistoryPaginatedList.setLoading(false);
      return residenceHistory;
    } catch (e) {
      this.residenceHistoryPaginatedList.setLoading(false);
      throw e;
    }
  }

  @action
  clearStore(): void {
    this.residenceHistoryPaginatedList = new PaginatedModelList<
      ResidenceHistory
    >(ResidenceHistory);
    this.residenceHistoryItem = new ModelItem<ResidenceHistory>(
      ResidenceHistory,
    );
  }
}
