import { WaitListInvite } from 'models/entities/WaitListInviteModel';
import ApiRoutes from 'routes/api/ApiRoutes';
import { action, observable } from 'mobx';
import { PaginatedModelList } from 'models/PaginatedModelList';
import { notification } from 'antd';
import { ModelItem } from 'models/ModelItem';
import Store from './Store';
import { ModelList } from 'models/ModelList';
import { WaitListInviteAnalytics } from 'models/entities/WaitListInviteAnalyticsModel';

export class WaitListInviteStore extends Store<WaitListInvite> {
  constructor() {
    super();
    WaitListInvite._store = this;
    WaitListInviteAnalytics._store = this;
  }

  @observable invitePaginatedList = new PaginatedModelList<WaitListInvite>(
    WaitListInvite,
  );

  @observable inviteItem = new ModelItem<WaitListInvite>(WaitListInvite);

  @observable waitListInviteAnalytics = new ModelList<WaitListInviteAnalytics>(
    WaitListInviteAnalytics,
  );

  get invites(): WaitListInvite[] {
    return this.invitePaginatedList.items;
  }

  @action
  async sendInvite(body: {
    first_name: string;
    email: string;
    organisation_id?: number;
    phone_number?: string;
    last_name?: string;
    building_id?: number;
  }): Promise<void> {
    try {
      const response = await this.apiService.post(
        ApiRoutes.tenantMonitoring.waitlistInvite.create,
        body,
      );

      this.invitePaginatedList.items.unshift(response);
      notification.success({
        message: 'Invite Sent',
        duration: 2,
      });
    } catch (e) {
      notification.error({
        message: (e as any)?.message ?? 'Something went wrong',
        duration: 2,
      });
    }
  }

  @action async fetchById(inviteId: number) {
    return this.inviteItem.load(ApiRoutes.public.waitlistInvite.show(inviteId));
  }

  @action
  async fetchMonitoringTenants(params?: { [key: string]: any }): Promise<void> {
    return this.invitePaginatedList.load(
      ApiRoutes.tenantMonitoring.waitlistInvite.list,
      params,
    );
  }

  @action async getWaitListAnalytics(invite_id: number) {
    try {
      this.waitListInviteAnalytics.setLoading(true);
      const reponse = await this.apiService.get(
        ApiRoutes.tenantMonitoring.waitlistInvite.analytics(invite_id),
      );
      this.waitListInviteAnalytics.setItems(reponse.data);
      this.waitListInviteAnalytics.setLoading(false);
      this.waitListInviteAnalytics.setLoaded(true);
    } catch (e) {
      this.waitListInviteAnalytics.setLoading(false);
      this.waitListInviteAnalytics.setLoaded(false);
      notification.error({
        message: e?.message || 'Something went wrong',
        duration: 2.5,
      });
    }
  }

  @action
  clearStore(): void {}
}
