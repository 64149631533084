import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { notification } from 'antd';

import profiledummyImage from 'assets/profiledummy.png';
import onboadingPageImage from 'assets/onboardingpageImage.png';
import ApiRoutes from 'routes/api/ApiRoutes';
import { ApplicationStep } from 'models/ApplicationStep';
import { getNextApplicationStepUrl } from 'constants/ApplicationStepUrlMapping';
import { StepStore } from 'stores/StepStore';
import Logo from 'components/Logo/Logo';

import HeadingText from '../../../../shared/components/HeadingText/HeadingText';
import MTButton from '../../../../shared/components/MTButton/MTButton';
import {
  AUTH_STORE,
  STEP_STORE,
  UNIT_STORE,
} from '../../../../constants/stores';
import { AuthStore } from '../../../../stores/AuthStore';
import './OnboardingPage.scss';
import { UnitStore } from '../../../../stores/UnitStore';
import { analyticsService } from '../../../../services/AnalyticsService';
import { TrackerEventType } from '../../../../enums/TrackerEventType.enum';

interface RegisterPageProps extends RouteComponentProps {
  [AUTH_STORE]?: AuthStore;
  [UNIT_STORE]?: UnitStore;
  [STEP_STORE]?: StepStore;
}

interface RegisterPageState {
  beginClicked: boolean;
}

class OnboardingPage extends React.Component<
  RegisterPageProps,
  RegisterPageState
> {
  constructor(props) {
    super(props);

    this.state = {
      beginClicked: false,
    };
  }

  componentDidMount() {
    this.unitStore.fetchUnitFromId(this.unitId);
    const currentApplicant = this.authStore.currentApplicant.item;
    if (
      currentApplicant &&
      currentApplicant?.step !== ApplicationStep.LANDING
    ) {
      const { history, authStore } = this.props;
      history.push(
        getNextApplicationStepUrl(
          ApplicationStep[currentApplicant.step.toUpperCase()],
          authStore.loggedInUser.applicationByUnitItem.item,
        ),
      );
    }
  }

  get unitId() {
    const { match } = this.props;
    const { params } = match;
    return (params as any).unitId;
  }

  get unitStore() {
    const { unitStore } = this.props;
    return unitStore;
  }

  get unit() {
    return this.unitStore.unit;
  }

  get authStore() {
    const { authStore } = this.props;
    return authStore;
  }

  get stepStore() {
    const { stepStore } = this.props;
    return stepStore;
  }

  get loggedInUser() {
    return this.authStore.loggedInUser;
  }

  welcome = () => {
    return (
      <span>
        <svg
          width="54"
          height="54"
          viewBox="0 0 54 54"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M27.8241 22.4327C20.8509 15.4604 13.2048 11.8015 10.7451 14.2603C10.4752 14.543 10.2766 14.8859 10.1657 15.2607L10.1262 15.2211L7.77718 24.8473L5.43809 34.4448L2.66537 45.8092L0.910156 53.0064L11.302 49.074L24.5197 44.0729L35.0644 40.0829L35.0518 40.0703C35.4048 39.9577 35.7278 39.7668 35.9965 39.5116C38.4561 37.052 34.7972 29.4059 27.8241 22.4327Z"
            fill="#DD1C4B"
          />
          <path
            d="M27.8234 22.4298C20.8504 15.4569 13.2041 11.7979 10.7448 14.2572C8.28546 16.7166 11.9445 24.3629 18.9174 31.3358C25.8903 38.3088 33.5366 41.9678 35.996 39.5084C38.4553 37.0491 34.7963 29.4028 27.8234 22.4298Z"
            fill="#D1E3EE"
          />
          <path
            d="M34.1072 40.3944C32.8482 40.4164 31.599 40.1686 30.4439 39.6675L21.8252 42.9287L8.60306 47.9254L1.51292 50.6081L0.910156 53.0795L11.302 49.1471L24.5197 44.1459L34.1972 40.4844L34.1072 40.3944Z"
            fill="#B21D48"
          />
          <path
            d="M44.9925 33.287C44.8848 33.2866 44.7779 33.2674 44.6767 33.2303C40.1095 31.5193 35.1726 31.0395 30.3615 31.8394L27.1479 32.3747C26.6561 32.4449 26.2004 32.103 26.1302 31.6111C26.0624 31.1359 26.3796 30.6911 26.8511 30.6006L30.0655 30.0608C35.1883 29.2096 40.4449 29.7201 45.3083 31.5407C45.7759 31.7087 46.0187 32.224 45.8506 32.6916C45.7209 33.0528 45.3763 33.2919 44.9925 33.287Z"
            fill="#FFBF41"
          />
          <path
            d="M28.7987 27.8887C28.3018 27.8857 27.9014 27.4806 27.9043 26.9837C27.9063 26.6407 28.1031 26.3288 28.4118 26.1793L47.3045 17.1828C47.7532 16.9692 48.29 17.1597 48.5037 17.6084C48.7174 18.0571 48.5269 18.5939 48.0782 18.8076L29.1855 27.8041C29.0645 27.8607 28.9323 27.8896 28.7987 27.8887Z"
            fill="#FFBF41"
          />
          <path
            d="M18.278 26.0896C18.1593 26.0898 18.0418 26.0663 17.9325 26.0203C17.4739 25.829 17.2572 25.3023 17.4485 24.8437V24.8435L18.7566 21.7047C20.7692 16.8955 21.2385 11.5799 20.0998 6.49249C19.983 6.00949 20.28 5.52346 20.7629 5.40673C21.2459 5.29 21.7319 5.587 21.8487 6.06988C21.8513 6.08057 21.8536 6.09125 21.8559 6.10204C23.0758 11.5518 22.5728 17.2459 20.4164 22.3974L19.1074 25.5363C18.9678 25.8711 18.6407 26.0892 18.278 26.0896Z"
            fill="#FFBF41"
          />
          <path
            d="M49.4907 36.8857C50.4844 36.8857 51.29 36.0801 51.29 35.0864C51.29 34.0927 50.4844 33.2871 49.4907 33.2871C48.497 33.2871 47.6914 34.0927 47.6914 35.0864C47.6914 36.0801 48.497 36.8857 49.4907 36.8857Z"
            fill="#D5557E"
          />
          <path
            d="M27.8989 11.6953C28.8926 11.6953 29.6982 10.8897 29.6982 9.89598C29.6982 8.90225 28.8926 8.09668 27.8989 8.09668C26.9052 8.09668 26.0996 8.90225 26.0996 9.89598C26.0996 10.8897 26.9052 11.6953 27.8989 11.6953Z"
            fill="#DD95C1"
          />
          <path
            d="M19.8032 3.5986C18.8094 3.5986 18.0039 2.79307 18.0039 1.7993C18.0039 0.805524 18.8094 0 19.8032 0C20.797 0 21.6025 0.805524 21.6025 1.7993C21.6025 2.79307 20.797 3.5986 19.8032 3.5986Z"
            fill="#7FCCCB"
          />
          <path
            d="M44.9934 7.197C44.4966 7.197 44.0938 6.79418 44.0938 6.29735V5.3977C44.0938 4.90087 44.4966 4.49805 44.9934 4.49805C45.4902 4.49805 45.893 4.90087 45.893 5.3977V6.29735C45.893 6.79418 45.4902 7.197 44.9934 7.197Z"
            fill="#2276BB"
          />
          <path
            d="M44.9934 2.69895C44.4966 2.69895 44.0938 2.29613 44.0938 1.7993V0.89965C44.0938 0.402818 44.4966 0 44.9934 0C45.4902 0 45.893 0.402818 45.893 0.89965V1.7993C45.893 2.29613 45.4902 2.69895 44.9934 2.69895Z"
            fill="#2276BB"
          />
          <path
            d="M47.6919 4.49803H46.7922C46.2954 4.49803 45.8926 4.09521 45.8926 3.59838C45.8926 3.10155 46.2954 2.69873 46.7922 2.69873H47.6919C48.1887 2.69873 48.5915 3.10155 48.5915 3.59838C48.5915 4.09521 48.1887 4.49803 47.6919 4.49803Z"
            fill="#2276BB"
          />
          <path
            d="M43.1938 4.49803H42.2942C41.7973 4.49803 41.3945 4.09521 41.3945 3.59838C41.3945 3.10155 41.7973 2.69873 42.2942 2.69873H43.1938C43.6907 2.69873 44.0935 3.10155 44.0935 3.59838C44.0935 4.09521 43.6907 4.49803 43.1938 4.49803Z"
            fill="#2276BB"
          />
          <path
            d="M8.05098 26.09C7.95022 25.7049 7.86026 25.3163 7.77659 24.9204L5.4375 34.5179L5.61743 36.7121C6.00956 41.3902 8.01916 45.7854 11.3005 49.1426L24.5191 44.1459C24.4471 44.1234 24.3724 44.1072 24.3004 44.0829C16.2851 41.0776 10.2268 34.3691 8.05098 26.09Z"
            fill="white"
          />
          <path
            d="M24.5187 44.1456C24.4467 44.1231 24.3721 44.1069 24.3001 44.0826C23.4342 43.7548 22.586 43.3816 21.7595 42.9644L9.85352 47.4689C10.3049 48.0543 10.7881 48.6145 11.3011 49.1468L24.5187 44.1456Z"
            fill="#F6F6E7"
          />
          <path
            d="M38.6949 10.7769V15.294H34.1777V10.7769H38.6949Z"
            fill="#C9DA53"
          />
          <path
            d="M22.501 26.9897C22.0042 26.9896 21.6015 26.5867 21.6016 26.0899C21.6017 25.835 21.7097 25.5922 21.8991 25.4217L30.8956 17.3248C31.2713 16.9997 31.8395 17.0407 32.1646 17.4165C32.4807 17.7817 32.4519 18.3314 32.0993 18.6617L23.1029 26.7585C22.9377 26.9073 22.7233 26.9896 22.501 26.9897Z"
            fill="#FFBF41"
          />
          <path
            d="M48.5911 23.3908C48.0942 23.3908 47.6914 22.988 47.6914 22.4912V21.5915C47.6914 21.0947 48.0942 20.6919 48.5911 20.6919C49.0879 20.6919 49.4907 21.0947 49.4907 21.5915V22.4912C49.4907 22.988 49.0879 23.3908 48.5911 23.3908Z"
            fill="#FFDB43"
          />
          <path
            d="M51.7727 22.0727C51.534 22.0734 51.3049 21.9785 51.1367 21.8091L50.5006 21.1731C50.1555 20.8157 50.1653 20.2461 50.5226 19.901C50.8713 19.5643 51.424 19.5643 51.7727 19.901L52.4088 20.537C52.7601 20.8884 52.76 21.458 52.4085 21.8093C52.24 21.9779 52.0112 22.0727 51.7727 22.0727Z"
            fill="#FFDB43"
          />
          <path
            d="M53.0903 18.8921H52.1907C51.6938 18.8921 51.291 18.4893 51.291 17.9924C51.291 17.4956 51.6938 17.0928 52.1907 17.0928H53.0903C53.5871 17.0928 53.99 17.4956 53.99 17.9924C53.99 18.4893 53.5871 18.8921 53.0903 18.8921Z"
            fill="#FFDB43"
          />
          <path
            d="M51.1357 16.3482C50.6389 16.3481 50.2362 15.9453 50.2363 15.4483C50.2363 15.2098 50.3311 14.9811 50.4997 14.8125L51.1357 14.1764C51.4931 13.8312 52.0626 13.8411 52.4079 14.1985C52.7445 14.5471 52.7445 15.0998 52.4079 15.4485L51.7718 16.0846C51.6031 16.2533 51.3744 16.3482 51.1357 16.3482Z"
            fill="#FFDB43"
          />
          <path
            d="M48.5911 15.2937C48.0942 15.2937 47.6914 14.8909 47.6914 14.394V13.4944C47.6914 12.9975 48.0942 12.5947 48.5911 12.5947C49.0879 12.5947 49.4907 12.9975 49.4907 13.4944V14.394C49.4907 14.8909 49.0879 15.2937 48.5911 15.2937Z"
            fill="#FFDB43"
          />
          <path
            d="M46.0461 16.3481C45.8075 16.3481 45.5788 16.2532 45.4101 16.0845L44.774 15.4485C44.4289 15.0911 44.4387 14.5215 44.7961 14.1763C45.1447 13.8397 45.6974 13.8397 46.0461 14.1763L46.6822 14.8124C47.0335 15.1638 47.0334 15.7334 46.682 16.0847C46.5134 16.2533 46.2847 16.3481 46.0461 16.3481Z"
            fill="#FFDB43"
          />
          <path
            d="M5.4084 0.899414L6.79836 3.71622L9.90665 4.16784L7.65753 6.36029L8.18832 9.45598L5.4084 7.99405L2.62849 9.45598L3.15928 6.36029L0.910156 4.16784L4.01845 3.71622L5.4084 0.899414Z"
            fill="#FFDB43"
          />
          <path
            d="M53.7048 48.7305C53.2769 47.214 52.2917 45.9155 50.9465 45.095L50.5759 44.539C48.4976 41.4311 45.2295 39.3178 41.5425 38.6976C41.0506 38.6274 40.5949 38.9693 40.5247 39.4612C40.4569 39.9364 40.7742 40.3812 41.2456 40.4717C43.905 40.9231 46.3285 42.2742 48.1108 44.2988C46.6123 44.5684 45.3018 45.4684 44.5122 46.7702C43.5433 48.5055 44.1646 50.6976 45.8999 51.6665C47.6352 52.6354 49.8273 52.0141 50.7963 50.2788C51.1664 49.5644 51.3662 48.7739 51.3801 47.9694C52.3374 49.3597 52.414 51.1753 51.5772 52.6413C51.3237 53.0686 51.4646 53.6205 51.8919 53.874C52.3193 54.1275 52.8712 53.9866 53.1247 53.5593C53.1328 53.5455 53.1405 53.5317 53.148 53.5175C53.9653 52.0602 54.1658 50.3365 53.7048 48.7305ZM49.2273 49.4007C48.7471 50.2708 47.6526 50.5868 46.7826 50.1066C45.9125 49.6264 45.5965 48.5319 46.0767 47.6619C46.0794 47.6571 46.0821 47.6522 46.0848 47.6473C46.6157 46.778 47.5112 46.1953 48.5211 46.0621C48.6775 46.0579 48.8323 46.0939 48.9709 46.1665C49.7302 46.5902 49.7707 48.4273 49.2273 49.4007Z"
            fill="#2BB3CE"
          />
          <path
            d="M34.6669 41.2328C35.3871 41.1224 36.0547 40.7891 36.5759 40.2801C37.4684 39.3885 38.2358 37.5505 36.8224 33.8647C36.654 33.3972 36.1385 33.1548 35.6711 33.3233C35.2036 33.4918 34.9613 34.0073 35.1297 34.4746C35.1339 34.4861 35.1383 34.4975 35.1428 34.5088C35.9633 36.65 36.0226 38.2873 35.3038 39.0071C34.433 39.8761 32.2648 39.6044 29.5011 38.2819C25.8007 36.3612 22.4375 33.8514 19.5429 30.8508C12.495 23.803 9.58733 16.8874 11.3857 15.089C11.96 14.636 12.7061 14.4619 13.4216 14.614C13.9148 14.6747 14.3638 14.3241 14.4244 13.8309C14.4851 13.3378 14.1345 12.8888 13.6414 12.8282C13.6261 12.8262 13.6107 12.8248 13.5953 12.8237C12.3444 12.6023 11.0611 12.967 10.1136 13.8133C6.81821 17.1132 11.8562 25.7093 18.2743 32.1238C21.3127 35.2683 24.8434 37.8964 28.7274 39.9049C29.4311 40.2417 30.1562 40.5317 30.8982 40.7731L24.5017 43.1922C16.8201 40.263 11.0211 33.8109 8.92519 25.8614C8.70927 25.0382 8.53294 24.197 8.40069 23.3604C8.33772 22.8675 7.88711 22.519 7.39432 22.5819C6.90143 22.6449 6.55293 23.0955 6.6159 23.5883C6.61804 23.6053 6.62074 23.6223 6.62389 23.6391C6.76513 24.5388 6.95406 25.4384 7.18527 26.3183C9.25604 34.1673 14.7115 40.6881 22.0718 44.1116L11.5504 48.0925C11.5025 48.0119 11.4416 47.9395 11.3704 47.8784C8.69668 45.7319 7.01917 42.5836 6.72915 39.1671L6.30901 34.112C6.27595 33.6222 5.8521 33.2519 5.36235 33.2849C5.35403 33.2855 5.34571 33.2862 5.33739 33.287C4.84224 33.328 4.47417 33.7626 4.51511 34.2577L4.93614 39.3146C5.25327 42.9662 6.95653 46.3576 9.69619 48.7924L2.19221 51.6335L3.54169 46.0953C3.65943 45.6123 3.36344 45.1253 2.88045 45.0076C2.39745 44.8899 1.91051 45.1859 1.79277 45.6688L0.0375521 52.866C-0.0801895 53.3487 0.215683 53.8355 0.698345 53.9533C0.768068 53.9704 0.839477 53.979 0.911112 53.979C1.01986 53.9789 1.1277 53.9595 1.22959 53.9214L34.5166 41.3264C34.5699 41.3006 34.6203 41.2692 34.6669 41.2328Z"
            fill="black"
          />
          <path
            d="M30.0676 30.0644L26.8541 30.6042C26.362 30.6726 26.0184 31.1269 26.0868 31.6192C26.1552 32.1114 26.6095 32.4548 27.1017 32.3864C27.1179 32.3842 27.1341 32.3815 27.1501 32.3783L30.3636 31.8431C35.175 31.0432 40.1122 31.5229 44.6797 33.2339C44.7803 33.2696 44.8862 33.2876 44.9928 33.287C45.4896 33.2878 45.8931 32.8857 45.8939 32.3888C45.8946 32.0126 45.661 31.6757 45.3086 31.5444C40.4458 29.724 35.1899 29.2137 30.0676 30.0644Z"
            fill="black"
          />
          <path
            d="M27.99 27.3762C28.2035 27.8244 28.7399 28.0148 29.1883 27.8017L48.081 18.8052C48.5273 18.5869 48.7121 18.048 48.4938 17.6017C48.2789 17.1623 47.7521 16.9751 47.3082 17.1804L28.4155 26.1769C27.9669 26.3905 27.7763 26.9272 27.9899 27.3759C27.9899 27.376 27.99 27.376 27.99 27.3762Z"
            fill="black"
          />
          <path
            d="M17.9351 26.0206C18.0446 26.0666 18.162 26.0901 18.2806 26.0899C18.6437 26.0899 18.9712 25.8717 19.111 25.5366L20.4191 22.4013C22.5759 17.2499 23.0789 11.5557 21.8585 6.10597C21.7595 5.61903 21.2846 5.30449 20.7977 5.40345C20.3108 5.50241 19.9962 5.97732 20.0952 6.46425C20.0973 6.47505 20.0998 6.48573 20.1024 6.49642C21.2419 11.5837 20.7729 16.8994 18.7601 21.7086L17.4511 24.8475C17.2622 25.305 17.4786 25.8293 17.9351 26.0206Z"
            fill="black"
          />
          <path
            d="M46.791 35.0862C46.791 36.5768 47.9994 37.7851 49.49 37.7851C50.9806 37.7851 52.1889 36.5768 52.1889 35.0862C52.1889 33.5955 50.9806 32.3872 49.49 32.3872C47.9994 32.3872 46.791 33.5955 46.791 35.0862ZM50.3896 35.0862C50.3896 35.583 49.9868 35.9858 49.49 35.9858C48.9931 35.9858 48.5903 35.583 48.5903 35.0862C48.5903 34.5893 48.9931 34.1865 49.49 34.1865C49.9868 34.1865 50.3896 34.5893 50.3896 35.0862Z"
            fill="black"
          />
          <path
            d="M30.5991 9.89621C30.5991 8.40561 29.3907 7.19727 27.9001 7.19727C26.4095 7.19727 25.2012 8.40561 25.2012 9.89621C25.2012 11.3868 26.4095 12.5952 27.9001 12.5952C29.3907 12.5952 30.5991 11.3868 30.5991 9.89621ZM27.0005 9.89621C27.0005 9.39938 27.4033 8.99656 27.9001 8.99656C28.397 8.99656 28.7998 9.39938 28.7998 9.89621C28.7998 10.393 28.397 10.7959 27.9001 10.7959C27.4033 10.7959 27.0005 10.393 27.0005 9.89621Z"
            fill="black"
          />
          <path
            d="M19.8032 3.5986C20.7969 3.5986 21.6025 2.79302 21.6025 1.7993C21.6025 0.805574 20.7969 0 19.8032 0C18.8095 0 18.0039 0.805574 18.0039 1.7993C18.0039 2.79302 18.8095 3.5986 19.8032 3.5986Z"
            fill="black"
          />
          <path
            d="M44.0938 6.29735C44.0938 6.79418 44.4966 7.197 44.9934 7.197C45.4902 7.197 45.893 6.79418 45.893 6.29735V5.3977C45.893 4.90087 45.4902 4.49805 44.9934 4.49805C44.4966 4.49805 44.0938 4.90087 44.0938 5.3977V6.29735Z"
            fill="black"
          />
          <path
            d="M44.9934 0C44.4966 0 44.0938 0.402818 44.0938 0.89965V1.7993C44.0938 2.29613 44.4966 2.69895 44.9934 2.69895C45.4902 2.69895 45.893 2.29613 45.893 1.7993V0.89965C45.893 0.402818 45.4902 0 44.9934 0Z"
            fill="black"
          />
          <path
            d="M47.6919 2.69873H46.7922C46.2954 2.69873 45.8926 3.10155 45.8926 3.59838C45.8926 4.09521 46.2954 4.49803 46.7922 4.49803H47.6919C48.1887 4.49803 48.5915 4.09521 48.5915 3.59838C48.5915 3.10155 48.1887 2.69873 47.6919 2.69873Z"
            fill="black"
          />
          <path
            d="M43.1938 2.69873H42.2942C41.7973 2.69873 41.3945 3.10155 41.3945 3.59838C41.3945 4.09521 41.7973 4.49803 42.2942 4.49803H43.1938C43.6907 4.49803 44.0935 4.09521 44.0935 3.59838C44.0935 3.10155 43.6907 2.69873 43.1938 2.69873Z"
            fill="black"
          />
          <path
            d="M38.6953 16.1939C39.1922 16.1939 39.595 15.7911 39.595 15.2942V10.7771C39.595 10.2803 39.1922 9.87744 38.6953 9.87744H34.1809C33.6841 9.87744 33.2812 10.2803 33.2812 10.7771V15.2942C33.2812 15.7911 33.6841 16.1939 34.1809 16.1939H38.6953ZM35.0778 11.6767H37.7957V14.3946H35.0805L35.0778 11.6767Z"
            fill="black"
          />
          <path
            d="M21.8341 26.6915C22.1664 27.0609 22.7352 27.091 23.1046 26.7586C23.1048 26.7584 23.1051 26.7582 23.1053 26.7581L32.1018 18.6612C32.4643 18.3215 32.483 17.7523 32.1433 17.3896C31.813 17.037 31.2633 17.0082 30.898 17.3244L21.9015 25.4212C21.5322 25.7534 21.502 26.3221 21.8341 26.6915Z"
            fill="black"
          />
          <path
            d="M49.4907 22.4912V21.5915C49.4907 21.0947 49.0879 20.6919 48.5911 20.6919C48.0942 20.6919 47.6914 21.0947 47.6914 21.5915V22.4912C47.6914 22.988 48.0942 23.3908 48.5911 23.3908C49.0879 23.3908 49.4907 22.988 49.4907 22.4912Z"
            fill="black"
          />
          <path
            d="M50.5025 21.1731L51.1386 21.8092C51.4959 22.1544 52.0654 22.1445 52.4107 21.7872C52.7474 21.4385 52.7474 20.8858 52.4107 20.5371L51.7746 19.901C51.4172 19.5558 50.8478 19.5657 50.5025 19.9231C50.1658 20.2718 50.1658 20.8245 50.5025 21.1731Z"
            fill="black"
          />
          <path
            d="M53.0903 17.0933H52.1907C51.6938 17.0933 51.291 17.4961 51.291 17.9929C51.291 18.4897 51.6938 18.8926 52.1907 18.8926H53.0903C53.5871 18.8926 53.99 18.4897 53.99 17.9929C53.99 17.4961 53.5871 17.0933 53.0903 17.0933Z"
            fill="black"
          />
          <path
            d="M51.1377 16.3486C51.3764 16.3486 51.6051 16.2537 51.7738 16.085L52.4099 15.4489C52.755 15.0916 52.7452 14.522 52.3878 14.1768C52.0392 13.8401 51.4865 13.8401 51.1377 14.1768L50.5017 14.8129C50.1504 15.1643 50.1505 15.7339 50.5019 16.0852C50.6706 16.2538 50.8992 16.3486 51.1377 16.3486Z"
            fill="black"
          />
          <path
            d="M48.5911 12.5952C48.0942 12.5952 47.6914 12.998 47.6914 13.4949V14.3945C47.6914 14.8913 48.0942 15.2942 48.5911 15.2942C49.0879 15.2942 49.4907 14.8913 49.4907 14.3945V13.4949C49.4907 12.998 49.0879 12.5952 48.5911 12.5952Z"
            fill="black"
          />
          <path
            d="M46.05 14.1764C45.6926 13.8312 45.1232 13.8411 44.7779 14.1985C44.4412 14.5471 44.4412 15.0998 44.7779 15.4485L45.414 16.0846C45.7713 16.4298 46.3408 16.4199 46.6861 16.0625C47.0228 15.7139 47.0228 15.1612 46.6861 14.8125L46.05 14.1764Z"
            fill="black"
          />
          <path
            d="M10.765 3.89004C10.6589 3.56436 10.3771 3.32719 10.038 3.27828L7.39846 2.89413L6.21812 0.501958C5.95565 0.0562945 5.38156 -0.0922601 4.9359 0.170213C4.79893 0.250844 4.68478 0.364987 4.60415 0.501958L3.42381 2.89413L0.78334 3.27828C0.291682 3.35014 -0.0486107 3.80693 0.0232489 4.29859C0.0518127 4.49381 0.143689 4.67431 0.284934 4.81218L2.19489 6.67445L1.74507 9.30413C1.65926 9.79354 1.9864 10.2598 2.47581 10.3456C2.6717 10.3799 2.87345 10.3484 3.04956 10.256L5.40844 9.01084L7.77002 10.2524C8.20984 10.4836 8.75379 10.3145 8.985 9.87473C9.0771 9.69964 9.10881 9.49913 9.07541 9.30413L8.62559 6.67445L10.5364 4.81218C10.7819 4.57332 10.8705 4.21582 10.765 3.89004ZM7.03231 5.71633C6.82044 5.92268 6.72361 6.22002 6.77321 6.51162L6.99632 7.81161L5.82947 7.19715C5.56734 7.05928 5.25404 7.05928 4.9919 7.19715L3.82236 7.81071L4.04637 6.51072C4.09607 6.21979 3.99992 5.92291 3.78907 5.71633L2.84624 4.79598L4.14893 4.60616C4.44233 4.56388 4.69603 4.37978 4.82726 4.11405L5.40844 2.93281L5.99141 4.11405C6.12231 4.37933 6.37522 4.56331 6.66795 4.60616L7.97154 4.79598L7.03231 5.71633Z"
            fill="black"
          />
          <path
            d="M50.9496 45.0949L50.5789 44.5389C48.4997 41.4315 45.2316 39.3185 41.5446 38.6975C41.0569 38.6023 40.5845 38.9206 40.4895 39.4083C40.3943 39.896 40.7126 40.3685 41.2003 40.4635C41.2164 40.4666 41.2325 40.4693 41.2487 40.4716C43.9078 40.9229 46.3311 42.2739 48.113 44.2987C46.6148 44.5692 45.3046 45.4689 44.5144 46.77C43.5455 48.5056 44.167 50.6979 45.9025 51.6668C47.6381 52.6358 49.8304 52.0142 50.7993 50.2787C51.1677 49.5637 51.3666 48.7734 51.3805 47.9693C51.6425 48.3535 51.8437 48.7759 51.977 49.2216C52.305 50.3689 52.1612 51.5996 51.5775 52.6403C51.3241 53.0676 51.465 53.6195 51.8923 53.873C52.3196 54.1265 52.8716 53.9856 53.125 53.5582C53.1331 53.5445 53.1409 53.5307 53.1483 53.5165C54.794 50.574 53.8242 46.8572 50.9505 45.094L50.9496 45.0949ZM49.2304 49.4006C48.7463 50.2685 47.6505 50.5797 46.7825 50.0957C46.3654 49.8631 46.0578 49.4742 45.9277 49.0147C45.7959 48.5557 45.8536 48.0632 46.0879 47.6472C46.6186 46.7777 47.5142 46.1949 48.5241 46.062C48.6806 46.0579 48.8354 46.0938 48.974 46.1664C49.7324 46.5901 49.7728 48.4272 49.2304 49.4006Z"
            fill="black"
          />
        </svg>
      </span>
    );
  };

  letsBeginHandler = async () => {
    try {
      const { history, authStore } = this.props;
      this.setState({ beginClicked: true });
      await this.loggedInUser.applicationByUnitItem.load(
        ApiRoutes.me.applicationByUnit(+this.unitId),
      );

      analyticsService.identify(this.loggedInUser, {
        application_id: this.loggedInUser.applicationByUnit.id,
        organisation_id: this.loggedInUser.applicationByUnit.organisation_id,
      });
      await this.authStore.setCurrentApplicant();
      const currentStep = ApplicationStep.LANDING;
      const currentApplicant = this.authStore.currentApplicant.item;
      const meta = {
        application_id: currentApplicant.application_id,
        organisation_id: currentApplicant.organisation_id,
      };
      analyticsService.track(TrackerEventType.LETS_BEGIN_BUTTON_CLICKED, meta);
      if (currentStep === currentApplicant?.step) {
        const nextStep = await authStore.updateApplicationStep(
          ApplicationStep.LANDING,
        );
        await this.stepStore.setCurrentStep(nextStep);
        history.push(
          getNextApplicationStepUrl(
            nextStep,
            authStore.loggedInUser.applicationByUnitItem.item,
          ),
        );
      }
      if (currentApplicant.step !== currentStep) {
        history.push(
          getNextApplicationStepUrl(
            ApplicationStep[currentApplicant.step.toUpperCase()],
            authStore.loggedInUser.applicationByUnitItem.item,
          ),
        );
      }
      this.setState({ beginClicked: false });
    } catch (e) {
      notification.error({ message: (e as any).message, duration: 2 });
      this.setState({ beginClicked: false });
    }
  };

  render() {
    const { beginClicked } = this.state;
    const { authStore } = this.props;
    return (
      <div className="onboarding-page flex justify-center ">
        <div className="lg:w-10/12 w-11/12">
          <div className=" w-full sticky bg-white z-10 top-0 justify-between py-2 flex flex-row">
            <div className="">
              <Logo unit={this.unit} />
            </div>
            <div className=" flex flex-row items-center">
              <img
                className="w-10 h-10 object-cover rounded-full mr-2"
                src={profiledummyImage}
                alt="Hello!"
              />
              <span>Hello, </span>
              <span className="font-bold ml-2 notranslate">
                {authStore.loggedInUser != null
                  ? authStore.loggedInUser.first_name
                  : ''}
              </span>
            </div>
          </div>

          <div className="">
            <div className="welcome_part flex lg:flex-row flex-col justify-center lg:justify-between mt-5">
              <div className="">
                {this.welcome()}
                <br />
                <HeadingText heading="H2" color="black">
                  Welcome
                </HeadingText>
                <p className="text-xl">
                  Apply to a <span>Unit </span> by following these 4 easy steps
                </p>
              </div>
              <div className="grid_right">
                <div className="flex flex-col mb-8 w-full">
                  <div className="flex flex-col my-6 text-base">
                    {/* <HeadingText heading={'H2'}>
                  Welcome,{' '}
                  {this.props.authStore.loggedInUser != null
                    ? this.props.authStore.loggedInUser.first_name
                    : ''}
                </HeadingText> */}
                    {/* <div className="flex my-2 flex-row">
                  <p className="my-2 ">Apply to </p>
                  <p className="my-2 mx-2 font-bold">{this.unit?.name}</p>
                  <p className="my-2 mr-2">in</p>
                  <HeadingText
                    className="mr-2 mb-2"
                    heading={'H2'}
                    color={'orange'}>
                    6
                  </HeadingText>
                  <p className="my-2"> easy steps. </p>
                </div> */}

                    <div className="flex flex-col my-4">
                      <div className="flex flex-row items-center my-4">
                        <HeadingText
                          className="mr-2"
                          heading="H2"
                          color="warning">
                          01
                        </HeadingText>
                        <h2 className="font-bold">
                          Upload your Government Identification
                        </h2>
                      </div>
                      <div className="flex flex-row  items-center my-4">
                        <HeadingText
                          className="mr-2"
                          heading="H2"
                          color="warning">
                          02
                        </HeadingText>
                        <h2 className="font-bold">Connect your Bank Details</h2>
                      </div>
                      <div className="flex flex-row my-4 items-center ">
                        <HeadingText
                          className="mr-2"
                          heading="H2"
                          color="warning">
                          03
                        </HeadingText>
                        <h2 className="font-bold">
                          Upload Most Recent Paystub or Proof of Benefit
                        </h2>
                      </div>
                      <div className="flex flex-row my-4 items-center ">
                        <HeadingText
                          className="mr-2"
                          heading="H2"
                          color="warning">
                          04
                        </HeadingText>
                        <h2 className="font-bold">
                          Tell us about your Previous Residence
                        </h2>
                      </div>
                    </div>
                    <MTButton
                      className=""
                      variant="login"
                      disabled={beginClicked}
                      onClick={this.letsBeginHandler}>
                      LET'S BEGIN
                    </MTButton>
                  </div>
                </div>

                {/* <div className="image-container xs:hidden flex content-center flex-wrap my-16 h-auto">
              <img src={Image} alt="auth-image" className="w-full object-cover" />
            </div> */}
              </div>
            </div>
            <div className="flex flex-row  justify-center mb-4">
              <img src={onboadingPageImage} alt="Banner" className="w-full" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject(
  AUTH_STORE,
  UNIT_STORE,
  STEP_STORE,
)(observer(OnboardingPage));
