import cx from 'classnames';
import {
  AppLogo,
  HaloLogo,
  HPALogo,
  MCENEARNEYLogo,
  MorganLogo,
} from 'constants/constants';
import { Unit } from 'models/entities/UnitModel';
import React, { useEffect, useMemo, useState } from 'react';
import {
  isOrgHalo,
  isOrgHPA,
  isOrgMcEnearney,
  isOrgMorgan,
} from 'utils/helpers';

interface Props {
  className?: string;
  unit?: Unit;
  orgnaisationId?: number;
}

const Logo: React.FC<Props> = (props: Props) => {
  const { className, unit, orgnaisationId } = props;

  const [logo, setLogo] = useState<string>(AppLogo);

  const isHaloOrg = useMemo(() => isOrgHalo(unit), [unit]);
  const isHPAOrg = useMemo(() => isOrgHPA(unit), [unit]);
  const isMcEnearneyOrg = useMemo(() => isOrgMcEnearney(unit), [unit]);
  const isMorganOrg = useMemo(() => isOrgMorgan(unit), [unit]);

  useEffect(() => {
    if (isHaloOrg) {
      setLogo(HaloLogo);
    }
    if (isHPAOrg) {
      setLogo(HPALogo);
    }
    if (isMcEnearneyOrg) {
      setLogo(MCENEARNEYLogo);
    }

    if (isMorganOrg) {
      setLogo(MorganLogo);
    }
  }, [unit, isHaloOrg, isHPAOrg, isMcEnearneyOrg, isMorganOrg]);

  return (
    <img
      className={cx(
        'h-12 vsm:w-12 object-contain',
        isHaloOrg && 'w-24 vsm:w-24',
        isHPAOrg && 'override:h-10 vsm:w-40 override:object-cover',
        isMcEnearneyOrg && 'w-20 vsm:w-20 h-20',
        className ?? '',
      )}
      src={logo}
      alt="onboarding-bg"
    />
  );
};

export default React.memo(Logo);
