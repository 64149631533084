import { observable } from 'mobx';
import Model from '../Model';

import { UserStore } from '../../stores/UserStore';
import { ModelItem } from '../ModelItem';
import { Application } from './ApplicationModel';
import { City } from './CityModel';
import { Payroll } from './Payroll';
import { State } from './StateModel';
import { CreditStatus } from '../../enums/CreditStatus.enum';

export enum UserRole {
  ADMIN = 1,
  MANAGER,
  AGENT,
  RENTER,
  INSPECTOR,
  SUPPORT,
  PHA_AGENT,
  ASSOCIATE_AGENT,
  SUPER_ADMIN,
  OWNER,
  PHA_OWNER,
  PHA_ADMIN,
  PHA_MANAGER,
}

export class User extends Model {
  static _store: UserStore;

  @observable id: number;

  @observable role_id: number;

  @observable image_url: string;

  @observable first_name: string;

  @observable middle_name: string;

  @observable no_middle_name: string;

  @observable last_name: string;

  @observable role: string;

  @observable email: string;

  @observable date_of_birth: string;

  @observable address_line_1: string;

  @observable street_name: string;

  @observable house_number: string;

  @observable state_code: string;

  @observable address_line_2: string;

  @observable state: string;

  @observable city_name: string;

  @observable belonging_state: State;

  @observable belonging_city: City;

  @observable zip_code: string;

  @observable country_code: string;

  @observable latitude: number;

  @observable longitude: number;

  @observable phone_number: string;

  @observable social_security_number: string;

  @observable annual_income: string;

  @observable employer: string;

  @observable employer_address: string;

  @observable occupation: string;

  @observable supervisor: string;

  @observable supervisor_phone_number: string;

  @observable length_of_employment: string;

  @observable other_income: string;

  @observable stripe_publishable_key: string;

  @observable stripe_product_key_annual: string;

  @observable stripe_product_key_monthly: string;

  @observable stripe_customer_id: string;

  @observable is_complete: boolean;

  @observable applicationByUnitItem = new ModelItem<Application>(Application);

  @observable is_default_password: boolean;

  @observable created_at: string;

  @observable updated_at: string;

  @observable payrolls: Payroll[];

  @observable applicantOrganisations: number[];

  @observable referral_id: string;

  @observable editable: boolean;

  @observable credit_history_outside_usa: boolean;

  @observable disabled: boolean;

  @observable credit_status: CreditStatus;

  @observable total_annual_income: number;

  @observable crs_token_exists: boolean;

  @observable crs_token_expired: boolean;

  @observable allow_mx_monitoring: boolean;

  @observable crs_report_expired: boolean;

  @observable invited_by_pha: boolean;

  @observable pha_invite_id: string;

  @observable answered_questionnaire: boolean;

  @observable crs_token_age: number;

  get fullname(): string {
    const name = [this.first_name, this.middle_name, this.last_name];
    return name.filter((v) => !!v).join(' ');
  }

  get isAdmin(): boolean {
    return this.role_id === UserRole.ADMIN;
  }

  get isManager(): boolean {
    return this.role_id === UserRole.MANAGER;
  }

  get isAgent(): boolean {
    return this.role_id === UserRole.AGENT;
  }

  get belongsToAnOrganisation(): boolean {
    return this.isManager || this.isAgent || this.isAssociateAgent;
  }

  get isRenter(): boolean {
    return this.role_id === UserRole.RENTER;
  }

  get isPHAOwner(): boolean {
    return this.role_id === UserRole.PHA_OWNER;
  }

  get isPHAAdmin(): boolean {
    return this.role_id === UserRole.PHA_ADMIN;
  }

  get isPHAManager(): boolean {
    return this.role_id === UserRole.PHA_MANAGER;
  }

  get isPHAagent(): boolean {
    return this.role_id === UserRole.PHA_AGENT;
  }

  get isPHAMember(): boolean {
    return (
      this.isPHAOwner || this.isPHAAdmin || this.isPHAManager || this.isPHAagent
    );
  }

  get isLandLordOrPHAMember(): boolean {
    return this.isPHAMember || this.belongsToAnOrganisation;
  }

  get isLandLordOrPHAAgent(): boolean {
    return this.belongsToAnOrganisation || this.isPHAagent;
  }

  get isAssociateAgent(): boolean {
    return this.role_id === UserRole.ASSOCIATE_AGENT;
  }

  get isManagerOrAgent(): boolean {
    return [UserRole.MANAGER, UserRole.AGENT].includes(this.role_id);
  }

  get isManagerOrAgentOrSupport(): boolean {
    return [UserRole.MANAGER, UserRole.AGENT, UserRole.SUPPORT].includes(
      this.role_id,
    );
  }

  get isAdminOrManager(): boolean {
    return [UserRole.ADMIN, UserRole.MANAGER].includes(this.role_id);
  }

  get isAdminOrManagerorAgent(): boolean {
    return [UserRole.ADMIN, UserRole.MANAGER, UserRole.AGENT].includes(
      this.role_id,
    );
  }

  get isSupport(): boolean {
    return this.role_id === UserRole.SUPPORT;
  }

  get isAdminOrSupport(): boolean {
    return [UserRole.ADMIN, UserRole.SUPPORT].includes(this.role_id);
  }

  get isAdminOrManagerOrSupport(): boolean {
    return [UserRole.ADMIN, UserRole.MANAGER, UserRole.SUPPORT].includes(
      this.role_id,
    );
  }

  get isRenterOrSupportOrPHAOrInspector(): boolean {
    return [
      UserRole.RENTER,
      UserRole.SUPPORT,
      UserRole.PHA_AGENT,
      UserRole.PHA_MANAGER,
      UserRole.PHA_ADMIN,
      UserRole.PHA_OWNER,
      UserRole.INSPECTOR,
    ].includes(this.role_id);
  }

  get applicationByUnit(): Application {
    return this.applicationByUnitItem.item;
  }
}
