import { AuthContext } from 'context/AuthContext';
import React, { Suspense } from 'react';
import webRoutes from 'routes/web';
import { useHistory } from 'react-router-dom';
import WebRoutes from 'routes/web/WebRoutes';
import {
  APPLICANT_STORE,
  APPLICATION_LOGS_STORE,
  APPLICATION_STORE,
  APPLICATION_INSURANCE_STORE,
  AUTH_STORE,
  BANK_STORE,
  BUILDING_IMAGE_STORE,
  BUILDING_STORE,
  CITY_STORE,
  DOCUMENT_STORE,
  GUEST_CARD_STORE,
  INSURANCE_STORE,
  INVITE_STORE,
  ORGANIZATION_STORE,
  PAYMENT_STORE,
  RESIDENCE_HISTORY_STORE,
  STATE_STORE,
  STEP_STORE,
  UNIT_IMAGE_STORE,
  UNIT_STORE,
  USER_STORE,
  PHA_STORE,
} from 'constants/stores';
import { ApplicantStore } from 'stores/ApplicantStore';
import { ApplicationStore } from 'stores/ApplicationStore';
import { AuthStore } from 'stores/AuthStore';
import { BankStore } from 'stores/BankStore';
import { BuildingImageStore } from 'stores/BuildingImageStore';
import { BuildingStore } from 'stores/BuildingStore';
import { CityStore } from 'stores/CityStore';
import { DocumentStore } from 'stores/DocumentStore';
import { GuestCardStore } from 'stores/GuestCardStore';
import { InsuranceStore } from 'stores/InsuranceStore';
import { InviteStore } from 'stores/InviteStore';
import { OrganizationStore } from 'stores/OrganizationStore';
import { PaymentStore } from 'stores/PaymentStore';
import { ResidenceHistoryStore } from 'stores/ResidenceHistoryStore';
import { StateStore } from 'stores/StateStore';
import { StepStore } from 'stores/StepStore';
import { UnitImageStore } from 'stores/UnitImageStore';
import { UnitStore } from 'stores/UnitStore';
import { UserStore } from 'stores/UserStore';
import { PHAStore } from 'stores/PHAStore';
import { ApplicationLogsStore } from 'stores/ApplicationLogsStore';
import { ApplicationInsuranceStore } from 'stores/ApplicationInsuranceStore';
import { inject } from 'mobx-react';
import Loader from 'shared/components/Loader/Loader';
import Zendesk from 'react-zendesk';

import Intercom from './Scripts/Intercom';
import Segment from './Scripts/Segment';
import { REACT_APP_ENV, ENV_ZENDESK_API_KEY } from '../utils/secrets';
import { ENVIRONMENT } from '../constants/constants';
import {set} from "lodash";

interface Props {
  [AUTH_STORE]?: AuthStore;
  [APPLICANT_STORE]?: ApplicantStore;
  [APPLICATION_STORE]?: ApplicationStore;
  [APPLICATION_INSURANCE_STORE]?: ApplicationInsuranceStore;
  [APPLICATION_LOGS_STORE]?: ApplicationLogsStore;
  [BANK_STORE]?: BankStore;
  [BUILDING_STORE]?: BuildingStore;
  [BUILDING_IMAGE_STORE]?: BuildingImageStore;
  [CITY_STORE]?: CityStore;
  [DOCUMENT_STORE]?: DocumentStore;
  [INVITE_STORE]?: InviteStore;
  [INSURANCE_STORE]?: InsuranceStore;
  [ORGANIZATION_STORE]?: OrganizationStore;
  [PAYMENT_STORE]?: PaymentStore;
  [RESIDENCE_HISTORY_STORE]?: ResidenceHistoryStore;
  [STATE_STORE]?: StateStore;
  [STEP_STORE]?: StepStore;
  [UNIT_STORE]?: UnitStore;
  [GUEST_CARD_STORE]?: GuestCardStore;
  [UNIT_IMAGE_STORE]?: UnitImageStore;
  [USER_STORE]?: UserStore;
  [PHA_STORE]?: PHAStore;
}

const SplashPage: React.FC<Props> = (props: Props) => {
  const {
    applicantStore,
    applicationStore,
    applicationLogsStore,
    authStore,
    bankStore,
    buildingImageStore,
    buildingStore,
    cityStore,
    documentStore,
    guestCardStore,
    insuranceStore,
    inviteStore,
    organizationStore,
    paymentStore,
    residenceStore,
    stateStore,
    stepStore,
    unitImageStore,
    unitStore,
    userStore,
    phaStore,
  } = props;

  const history = useHistory();

  const onLogout = (setRedirect: boolean) => {
    localStorage.clear();
    const state = {};
    if (setRedirect) {
      set(state, 'redirect_to', window.location.pathname);
    }
    history.push({
      pathname: WebRoutes.auth.login,
      state,
    });
    clearStore();
  };

  const clearStore = () => {
    applicantStore.clearStore();
    applicationStore.clearStore();
    applicationLogsStore.clearStore();
    authStore.clearStore();
    bankStore.clearStore();
    buildingImageStore.clearStore();
    buildingStore.clearStore();
    cityStore.clearStore();
    documentStore.clearStore();
    guestCardStore.clearStore();
    insuranceStore.clearStore();
    inviteStore.clearStore();
    organizationStore.clearStore();
    paymentStore.clearStore();
    residenceStore.clearStore();
    stateStore.clearStore();
    stepStore.clearStore();
    unitImageStore.clearStore();
    unitStore.clearStore();
    userStore.clearStore();
    phaStore.clearStore();
  };

  const ZENDESH_ENVS: string[] = [ENVIRONMENT.DEMO, ENVIRONMENT.PRODUCTION];

  return (
    <AuthContext.Provider value={{ onLogout }}>
      <Suspense fallback={<Loader />}>{webRoutes}</Suspense>
      {ZENDESH_ENVS.includes(REACT_APP_ENV) ? (
        <Zendesk zendeskKey={ENV_ZENDESK_API_KEY} />
      ) : (
        <Intercom />
      )}
      <Segment />
    </AuthContext.Provider>
  );
};

export default inject(
  APPLICANT_STORE,
  APPLICATION_STORE,
  APPLICATION_INSURANCE_STORE,
  APPLICATION_LOGS_STORE,
  AUTH_STORE,
  BANK_STORE,
  BUILDING_IMAGE_STORE,
  BUILDING_STORE,
  CITY_STORE,
  DOCUMENT_STORE,
  GUEST_CARD_STORE,
  INSURANCE_STORE,
  INVITE_STORE,
  ORGANIZATION_STORE,
  PAYMENT_STORE,
  RESIDENCE_HISTORY_STORE,
  STATE_STORE,
  STEP_STORE,
  UNIT_IMAGE_STORE,
  UNIT_STORE,
  USER_STORE,
  PHA_STORE,
)(SplashPage);
