import { observable } from 'mobx';
import Model from '../Model';
import { UnitImageStore } from "../../stores/UnitImageStore";

export class UnitImage extends Model {
  static _store: UnitImageStore;

  @observable id: number;

  @observable unit_id: number;

  @observable url: string;
}
