export const APPLICANT_STORE = 'applicantStore';
export const APPLICATION_STORE = 'applicationStore';
export const APPLICATION_INSURANCE_STORE = 'applicationInsuranceStore';
export const APPLICATION_LOGS_STORE = 'applicationLogsStore';
export const AUTH_STORE = 'authStore';
export const BANK_STORE = 'bankStore';
export const BUILDING_IMAGE_STORE = 'buildingImageStore';
export const BUILDING_STORE = 'buildingStore';
export const CITY_STORE = 'cityStore';
export const DOCUMENT_STORE = 'documentStore';
export const GUEST_CARD_STORE = 'guestCardStore';
export const GUEST_CARD_CHAT_STORE = 'guestCardChatStore';
export const INVITE_STORE = 'inviteStore';
export const INSURANCE_STORE = 'insuranceStore';
export const INTEGRATION_HEALTH_STATUS = 'integrationHealthStatusStore';
export const LANDLORD_STORE = 'landlordStore';
export const ORGANIZATION_STORE = 'organizationStore';
export const PAYMENT_STORE = 'paymentStore';
export const PHA_STORE = 'phaStore';
export const REFERRAL_STORE = 'referralStore';
export const RENT_DETERMINATION_CHAT_STORE = 'rentDeterminationChatStore';
export const RESIDENCE_HISTORY_STORE = 'residenceStore';
export const STATE_STORE = 'stateStore';
export const STEP_STORE = 'stepStore';
export const SYSTEM_LOGS_STORE = 'systemLogsStore';
export const UNIT_STORE = 'unitStore';
export const UNIT_IMAGE_STORE = 'unitImageStore';
export const USER_STORE = 'userStore';
export const PAYROLL_STORE = 'payrollStore';
export const BANK_TRANSACTION_STORE = 'bankTransactionStore';
export const PAYROLL_DETAIL_STORE = 'payrollDetailStore';
export const WAITING_LIST_APPLICANT_STORE = 'waitingListApplicantStore';
export const WAITLIST_INVITE_STORE = 'waitListInviteStore';

// Follow alphabetical order
