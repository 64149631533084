import { times, random } from 'lodash';

export class Utils {
  static getRandomString = (length = 20) =>
    times(length, () => random(35).toString(36)).join('');

  static getSanitizedFileName = (name: string) => {
    const newName = name.replace(/[^a-zA-Z0-9-_/. ]/g, '_');
    if (!newName) {
      return Utils.getRandomString(12);
    }
    return newName;
  };

  static makeFirstLetterCapital = (str: string): string =>
    str.charAt(0).toUpperCase() + str.substr(1);

  static removeUnderscore = (str: string): string => {
    const list = str.split('_');
    return `${Utils.makeFirstLetterCapital(list[0])} ${list
      .slice(1)
      .join(' ')}`;
  };

  private static objectToGetParams = (object: {
    [key: string]: string | number | undefined | null;
  }) => {
    const params = Object.entries(object)
      .filter(([, value]) => value !== undefined && value !== null)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`,
      );

    return params.length > 0 ? `?${params.join('&')}` : '';
  };

  static getPinterestShareUrl = (
    description: string,
    url: string,
    media: string,
  ) => {
    return `https://pinterest.com/pin/create/button/${Utils.objectToGetParams({
      url,
      media,
      description,
    })}`;
  };

  static getFacebookShareUrl = (title: string, url: string) => {
    return `https://www.facebook.com/sharer/sharer.php${Utils.objectToGetParams(
      {
        quote: title,
        u: url,
      },
    )}`;
  };

  static getTwitterShareUrl = (
    title: string,
    url: string,
    imageUrl: string,
  ) => {
    return `https://twitter.com/intent/tweet${Utils.objectToGetParams({
      text: title,
      url,
      via: imageUrl,
    })}`;
  };

  static getGoogleMapUrl = (lat: number, long: number) => {
    return `https://maps.google.com?q=${lat},${long}`;
  };
}
