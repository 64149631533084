/* eslint-disable import/no-cycle */
import { action, observable } from 'mobx';
import { IntegrationHealthStatus } from '../models/entities/IntegrationHealthStatusModel';
import ApiRoutes from '../routes/api/ApiRoutes';
import { PaginatedModelList } from '../models/PaginatedModelList';
import Store from './Store';

export class IntegrationHealthStatusStore extends Store<
  IntegrationHealthStatus
> {
  constructor() {
    super();
    IntegrationHealthStatus._store = this;
  }

  @observable loading = false;

  @observable loaded = false;

  @action setLoading(loading: boolean): void {
    this.loading = loading;
  }

  @action setLoaded(loaded: boolean): void {
    this.loaded = loaded;
  }

  @observable
  IntegrationHealthStatusPaginatedList = new PaginatedModelList<
    IntegrationHealthStatus
  >(IntegrationHealthStatus);

  @action
  async loadIntegrationHealthStatus(
    params?: { [key: string]: any },
    forceRefresh?: boolean,
  ) {
    return this.IntegrationHealthStatusPaginatedList.load(
      ApiRoutes.system.integrationHealth.list,
      params,
      { forceRefresh },
    );
  }

  @action
  async checkIntegrationHealth(id: number) {
    this.setLoading(true);
    const response = await this.apiService.post(
      ApiRoutes.system.integrationHealth.check(id),
    );
    if (response.data) {
      this.IntegrationHealthStatusPaginatedList.items
        .find((_item) => _item.id === id)
        .updateFromJson(response.data);
    }

    this.setLoading(false);
    return response;
  }

  @action
  clearStore(): void {
    this.IntegrationHealthStatusPaginatedList = new PaginatedModelList<
      IntegrationHealthStatus
    >(IntegrationHealthStatus);
  }
}
