import { action, observable } from 'mobx';
import { ApplicationDocument } from 'models/entities/ApplicationDocument';
import { ModelItem } from 'models/ModelItem';
import { storageService } from 'services/StorageService';
import { ClassifiedDocument } from 'models/ClassifiedDocument';
import { Organization } from 'models/entities/OrganizationModel';
import { notification } from 'antd';
import { WaitingListSteps } from 'models/WaitingListStep';

import ApiRoutes from '../routes/api/ApiRoutes';
import { User } from '../models/entities/UserModel';
import { authService } from '../services/AuthService';
import { UserStoreIncludes } from './UserStore';
import { addIncludesToParams } from '../utils/helpers';
import { Bank } from '../models/entities/BankModel';
import { ApplicationStep } from '../models/ApplicationStep';
import { Store } from './Store';
import { MxConnectionStatus } from '../enums/MxConnectionStatus';
import { Applicant } from '../models/entities/ApplicantModel';
import { Utils } from '../utils/utils';
import { PHA } from '../models/entities/PHAModel';
import {
  WaitingListApplicant,
  WaitingListEmployer,
} from '../models/entities/WaitingListApplicantModel';
import { BankProviderType } from '../pages/renter/bank-statements/constants';

export class AuthStore extends Store<User> {
  @observable isImageUploading = false;

  @observable loadingSignedUrl = false;

  @observable isLoadingLoggedInUser: boolean;

  @observable payrollsLoaded = false;

  @observable currentApplicant = new ModelItem<Applicant>(Applicant);

  @observable primaryApplicant = new ModelItem<Applicant>(Applicant);

  @observable waitingListApplicant = new ModelItem<WaitingListApplicant>(
    WaitingListApplicant,
  );

  @observable loggedInUser: User;

  @observable loggedInUserOrg: Organization;

  @observable loggedInUserPHA: PHA;

  @observable fetchPayrolls = true;

  @observable isLoadingLoggedInUserOrg = false;

  @observable isLoadingLoggedInUserPHA = false;

  @action
  toggleFetchPayrolls(val: boolean): void {
    this.fetchPayrolls = val;
  }

  @action setIsLoadingLoggedInUser(loading: boolean): void {
    this.isLoadingLoggedInUser = loading;
  }

  @action setIsLoadingLoggedInUserOrg(loading: boolean): void {
    this.isLoadingLoggedInUserOrg = loading;
  }

  @action setIsLoadingLoggedInUserPHA(loading: boolean): void {
    this.isLoadingLoggedInUserPHA = loading;
  }

  @action
  async setLoggedInUser(user: any) {
    this.loggedInUser = User.fromJson(user) as User;
    if (
      this.loggedInUser.isAdmin ||
      this.loggedInUser.isAgent ||
      this.loggedInUser.isManager ||
      this.loggedInUser.isAssociateAgent ||
      this.loggedInUser.isSupport
    ) {
      // Call the agent org API
      await this.getLoggedInUserOrg();
    }

    if (this.loggedInUser.isPHAMember) {
      await this.getLoggedInUserPHA();
    }
  }

  @action
  async login(
    email: string,
    password: string,
    unitId?: number,
    inviteId?: number,
    referralCode?: string,
    waitlistInviteId?: number,
  ): Promise<void> {
    const response = await this.apiService.post(ApiRoutes.auth.login, {
      email,
      password,
      apply_for_unit: unitId,
      invite_id: inviteId,
      referral_code: referralCode,
      waitlist_invite_id: waitlistInviteId,
    });
    authService.setAuthToken(response.token);

    localStorage.setItem('user_id', response.user.data.id);
    localStorage.setItem('userData', JSON.stringify(response.user.data));
    await this.setLoggedInUser(response.user.data);
  }

  @action
  async me(include: UserStoreIncludes[] = []): Promise<void> {
    try {
      this.setIsLoadingLoggedInUser(true);
      const response = await this.apiService.get(
        ApiRoutes.auth.me,
        addIncludesToParams({}, include),
      );
      await this.setLoggedInUser(response.data);
      storageService.setFirstTimeVisited('false');
    } catch (e) {
      authService.clearAuthToken();
      throw e;
    } finally {
      this.setIsLoadingLoggedInUser(false);
    }
  }

  async signup(
    first_name: string,
    last_name: string,
    email: string,
    // phone_number: string,
    password: string,
    password_confirmation: string,
    invite_id?: string,
    unit_id?: number,
    ref?: string,
    phaInviteId?: string,
    waitlistInviteId?: number,
  ): Promise<void> {
    const response = await this.apiService.post(ApiRoutes.auth.register, {
      first_name,
      last_name,
      email,
      // phone_number,
      password,
      password_confirmation,
      invite_id,
      apply_for_unit: unit_id,
      referral_code: ref,
      pha_invite_id: phaInviteId,
      waitlist_invite_id: waitlistInviteId,
    });
    authService.setAuthToken(response.token);

    localStorage.setItem('user_id', response.user.data.id);
    localStorage.setItem('userData', JSON.stringify(response.user.data));
    await this.setLoggedInUser(response.user.data);
  }

  @action
  async resetPassword(
    token: string,
    password: string,
    password_confirmation: string,
    is_first_login = false,
  ) {
    return this.apiService.post(ApiRoutes.auth.resetPassword, {
      token,
      password,
      password_confirmation,
      is_first_login,
    });
  }

  @action
  async forgotPassword(email: string) {
    return this.apiService.post(ApiRoutes.auth.forgotPassword, {
      email,
    });
  }

  @action
  async deleteBank(bank: Bank): Promise<void> {
    bank.setDeleting(true);
    await this.apiService.delete(ApiRoutes.me.banks.show(bank.id));
    bank.setDeleting(false);
    bank.delete();
    this.currentApplicant.item?.banks.removeItem(bank);
  }

  @action
  async updateMe(body: { [key: string]: any }) {
    this.loggedInUser.setUpdating(true);
    const formData = new FormData();
    if (!body.social_security_number && body.credit_history_outside_usa) {
      delete body.social_security_number;
    }
    Object.keys(body).forEach((key) => formData.append(key, body[key]));
    const response = await this.apiService.patch(ApiRoutes.auth.me, formData);
    await this.setLoggedInUser(response.data);
    this.loggedInUser.setUpdating(false);
    return response.data;
  }

  @action
  async updateMyMeta(body: { [key: string]: any }) {
    this.loggedInUser.setUpdating(true);
    const response = await this.apiService.put(ApiRoutes.me.updateMeta, body);
    await this.setLoggedInUser(response.data);
    this.loggedInUser.setUpdating(false);
  }

  @action
  async getMyDocuments() {
    const response = await this.apiService.get(ApiRoutes.me.listMyDocuments);
    this.currentApplicant.item.documents.deserialize(response.data);
  }

  @action
  async getSignedUrl(
    file: File,
    unitId: number,
    docType: string,
    applicationId?: number,
  ): Promise<string> {
    try {
      this.loadingSignedUrl = true;
      const body: any = {
        file_name: file.name,
        doc_type: docType,
        content_type: file.type,
      };
      if (docType === 'building_image') {
        body.building_id = unitId;
      } else {
        body.unit_id = unitId;
      }
      if (applicationId) {
        body.application_id = applicationId;
      }
      const response = await this.apiService.get(ApiRoutes.preSignedUrl, body);
      this.loadingSignedUrl = false;
      return response.url;
    } catch (e) {
      this.loadingSignedUrl = false;
      throw e;
    }
  }

  @action
  async upload(
    file,
    unitId: number,
    docType: string,
    onUploadProgress?: any,
    applicationId?: number,
  ): Promise<string> {
    try {
      const newFileName = Utils.getSanitizedFileName(file.name); // File name without special character's
      const newFile = new File([file], newFileName, { type: file.type });
      this.isImageUploading = true;
      const url = await this.getSignedUrl(
        newFile,
        unitId,
        docType,
        applicationId,
      );
      await this.apiService.put(
        url,
        newFile,
        false,
        false,
        {
          'Content-Type': file.type,
          'Content-Disposition': `filename=${newFile.name}`,
        },
        '',
        '',
        onUploadProgress,
      );
      this.isImageUploading = false;
      return url.split('?')[0];
    } catch (e) {
      this.isImageUploading = false;
      throw e;
    }
  }

  async uploadBankStatements(file) {
    const formData = new FormData();
    formData.append('document', file);
    return this.apiService.post(ApiRoutes.me.uploadBankStatements, formData);
  }

  @action
  async deleteFile(key: string): Promise<void> {
    await this.apiService.delete(
      ApiRoutes.me.deleteDocument,
      null,
      null,
      null,
      {
        document_key: key,
      },
    );

    if (this.currentApplicant?.item) {
      const updatedDocs =
        this.currentApplicant.item?.documents?.items.filter(
          (doc) => doc.key !== key,
        ) || [];
      this.currentApplicant?.item?.documents?.setItems(updatedDocs);
    }

    if (this.waitingListApplicant?.item) {
      const updatedDocs =
        this.waitingListApplicant.item?.documents?.items.filter(
          (doc) => doc.key !== key,
        ) || [];
      this.waitingListApplicant?.item?.documents?.setItems(updatedDocs);
    }
  }

  @action
  async updateApplicationStep(
    currentStep: string,
    applicationId?: number,
    doNotUpdateApplicationStep = false,
    byPassChecks = false,
  ): Promise<ApplicationStep> {
    const appId =
      applicationId ?? this.loggedInUser.applicationByUnitItem.item.id;
    const nextStep = await this.apiService.put(
      ApiRoutes.applications.completeStep(appId),
      { step: currentStep, bypass_checks: byPassChecks },
    );

    if (!doNotUpdateApplicationStep) {
      this.currentApplicant.item.step = nextStep;
    }

    return nextStep;
  }

  @action
  async updateWaitingListStep(tenantId: number, step: WaitingListSteps) {
    try {
      const response = await this.apiService.post(
        ApiRoutes.tenantMonitoring.completeStep(tenantId),
        { step },
      );
      if (response?.data) {
        this.waitingListApplicant.item.step = response.data.step;
        this.waitingListApplicant.item.terms_accepted_at =
          response?.data?.terms_accepted_at;
      }
      return this.waitingListApplicant.item.step;
    } catch (e) {
      console.log(e);
      if ((e as any)?.code === 107) {
        this.waitingListApplicant.item.step = (e as any).data.desired_step_to_complete;
        return (e as any).data.desired_step_to_complete;
      }
      return this.waitingListApplicant.item.step;
    }
  }

  @action
  async importMxMember(memberGuid: string) {
    const token = localStorage.getItem('auth_token');
    await this.apiService.post(
      ApiRoutes.mx.importMember,
      { mx_member_id: memberGuid },
      {
        Authorization: token,
      },
    );
  }

  @action
  async updateBankConnected(memberGuid: string) {
    const token = localStorage.getItem('auth_token');
    const response = await this.apiService.post(
      ApiRoutes.mx.syncMember,
      {
        mx_member_id: memberGuid,
        connection_status: MxConnectionStatus.CONNECTED,
      },
      {
        Authorization: token,
      },
    );
    const bank = Bank.fromJson(response.data.bank.data) as Bank;
    if (this.currentApplicant.item) {
      this.currentApplicant.item.banks.appendItem(bank);
    }
    if (this.waitingListApplicant.item) {
      this.waitingListApplicant.item.banks.appendItem(bank);
    }
  }

  @action
  async recordNewTellerConnection(
    connection_token: string,
    institution: string,
    enrollment_id: string,
  ) {
    const response = await this.apiService.post(
      ApiRoutes.teller.recordNewConnection,
      { connection_token, institution, enrollment_id },
    );
    if (response && response.data) {
      const bank = Bank.fromJson(response.data) as Bank;
      if (this.currentApplicant.item) {
        const bankExists = this.currentApplicant.item.banks.items.find(
          (_bank) => _bank.id === bank.id,
        );
        if (bankExists) {
          notification.error({
            message: 'Bank already connected',
            duration: 2.5,
          });
          return;
        }
        this.currentApplicant.item.banks.appendItem(bank);
      }
      if (this.waitingListApplicant.item) {
        const bankExists = this.waitingListApplicant.item.banks.items.find(
          (_bank) => _bank.id === bank.id,
        );
        if (bankExists) {
          notification.error({
            message: 'Bank already connected',
            duration: 2.5,
          });
          return;
        }
        this.waitingListApplicant.item.banks.appendItem(bank);
      }
    }
  }

  @action
  async importPayroll(
    companyId: string,
    companyName: string,
    taskId: string,
  ): Promise<void> {
    const response = await this.apiService.post(
      ApiRoutes.payroll.importPayroll,
      {
        company_id: companyId,
        company_name: companyName,
        task_id: taskId,
      },
    );
    this.loggedInUser.payrolls = [
      ...this.loggedInUser.payrolls,
      ...response.payrolls,
    ];
  }

  @action
  async updateEmployers(
    id: number,
    payload: {
      employers: WaitingListEmployer[];
    },
  ): Promise<void> {
    await this.apiService.post(
      ApiRoutes.tenantMonitoring.updateEmployers(id),
      payload,
    );

    this.waitingListApplicant.item.employers = { data: payload.employers };
  }

  @action
  async getPayrolls(): Promise<void> {
    if (!this.payrollsLoaded) {
      const response = await this.apiService.get(ApiRoutes.payroll.show);
      if (response.payrolls) {
        this.loggedInUser.payrolls = response.payrolls;
      }
      this.payrollsLoaded = true;
    }
  }

  @action
  async createDummyPayroll(): Promise<void> {
    const response = await this.apiService.post(
      ApiRoutes.payroll.zeroIncomePayroll,
    );
    if (response.data) {
      this.loggedInUser.payrolls = [response?.data];
    }
  }

  @action
  async deletePayroll(payroll_id: number): Promise<void> {
    this.loggedInUser.payrolls = this.loggedInUser.payrolls.filter(
      (_payroll) => _payroll.id !== payroll_id,
    );
    await this.apiService.delete(ApiRoutes.payroll.showById(payroll_id));
  }

  @action
  async deletePayStub(payrollId: number): Promise<void> {
    await this.apiService.delete(ApiRoutes.me.deletePaystub(payrollId));
    this.loggedInUser.payrolls = this.loggedInUser.payrolls.filter(
      (_doc) => _doc.id !== payrollId,
    );
  }

  @action
  async deleteAtomicPayroll(payrollId: number): Promise<void> {
    await this.apiService.delete(ApiRoutes.payroll.deletePayroll(payrollId));
    this.loggedInUser.payrolls = this.loggedInUser.payrolls.filter(
      (payroll) => payroll.id !== payrollId,
    );
  }

  @action
  async uploadPaystub(
    file,
    companyName: string,
    companyId?: string,
  ): Promise<any> {
    const formData = new FormData();
    formData.append('document', file);
    formData.append('company_name', companyName);
    formData.append('company_mapping_id', companyId);
    const { payroll } = await this.apiService.post(
      ApiRoutes.me.uploadPayStub,
      formData,
    );
    if (this.loggedInUser.payrolls.length === 0) {
      this.loggedInUser.payrolls = [];
    }
    this.loggedInUser.payrolls = [...this.loggedInUser.payrolls, payroll];
    return payroll;
  }

  @action
  async getBankStatements(
    id: number,
  ): Promise<
    {
      document: string;
      name: string;
      created_at: string;
      updated_at: string;
    }[]
  > {
    const response = await this.apiService.get(ApiRoutes.me.bankStatements(id));
    return response.data;
  }

  @action
  async createDummyBankAccount(): Promise<void> {
    const response = await this.apiService.post(
      ApiRoutes.bank.createDummyAccount,
    );
    if (response.data) {
      this.currentApplicant.item.banks.setItems([response.data]);
    }
  }

  @action
  async deleteDummyBankAccount(userId: string): Promise<void> {
    const response = await this.apiService.delete(
      ApiRoutes.bank.deleteDummyAccount,
    );
    if (response.success) {
      this.currentApplicant.item.banks.setItems([]);
    }
  }

  @action
  async getApplicationPayrolls(
    id: number,
  ): Promise<{ url: string; created_at: string; updated_at: string }[]> {
    const response = await this.apiService.get(ApiRoutes.me.payrolls(id));
    return response.data;
  }

  // Fetch Renter Requested Docs
  @action
  async getRequestedDocuments(): Promise<ApplicationDocument[]> {
    const response = await this.apiService.get(
      ApiRoutes.applicationDocuments.getRequestedDocuments,
    );

    return response.data;
  }

  @action
  async setCurrentApplicant() {
    if (this.loggedInUser.applicationByUnitItem.item) {
      const currentApplicant = this.loggedInUser.applicationByUnitItem.item.applicants.items.find(
        (_) => _.user_id === this.loggedInUser.id,
      );

      this.currentApplicant.setItem(currentApplicant);
    }
  }

  @action
  async setPrimaryApplicant() {
    if (this.loggedInUser.applicationByUnitItem.item) {
      const primaryApplicant = this.loggedInUser.applicationByUnitItem.item.applicants.items.find(
        (_) => _.is_primary,
      );

      this.primaryApplicant.setItem(primaryApplicant);
    }
  }

  @action
  async documentClassification(file: File): Promise<ClassifiedDocument[]> {
    const formData = new FormData();
    formData.append('document', file);
    const response = await this.apiService.post(
      ApiRoutes.documentClassification.classifier,
      formData,
    );
    return response.documents as ClassifiedDocument[];
  }

  async getTanentMonitoringApplicantsByUserId(userId: number): Promise<void> {
    await this.waitingListApplicant.load(
      ApiRoutes.tenantMonitoring.fetchByUserId(userId),
    );
  }

  @action
  async getLoggedInUserOrg(): Promise<void> {
    if (this.loggedInUserOrg) {
      return;
    }

    this.setIsLoadingLoggedInUserOrg(true);
    const response = await this.apiService.get(ApiRoutes.me.agentOrg);
    this.loggedInUserOrg = response.data as Organization;
    this.setIsLoadingLoggedInUserOrg(false);
  }

  @action
  async createStripePaymentSource(
    source_id: string,
    organisation_id: number,
  ): Promise<void> {
    await this.apiService.post(ApiRoutes.me.createPaymentSource, {
      source_id,
      organisation_id,
    });
  }

  @action
  async getLoggedInUserPendingConnectApplicationsCount(): Promise<number> {
    try {
      const response = await this.apiService.get(
        ApiRoutes.me.getPendingConnectApplicationsCount,
      );
      if (response.data) {
        return response.data;
      }
      return 0;
    } catch (e) {
      notification.error({
        message: (e as any)?.message || 'Something went wrong.',
        duration: 2.5,
      });
      return 0;
    }
  }

  // eslint-disable-next-line consistent-return
  @action
  async acceptPHAInvite(
    inviteId: string,
    data: {
      type: string;
      first_name: string;
      last_name: string;
      email: string;
      phone_number?: string;
      password: string;
      password_confirmation: string;
      organisation_name?: string;
      registering_as?: string;
      city_name?: string;
      street_name?: string;
      route?: string;
      state_code?: string;
      house_number?: string;
      country?: string;
      zip_code?: string;
      latitude?: number;
      longitude?: number;
    },
  ) {
    const response = await this.apiService.post(
      ApiRoutes.public.phaInvite.acceptInvite(inviteId),
      data,
    );

    localStorage.setItem('user_id', response.user.data.id);
    localStorage.setItem('userData', JSON.stringify(response.user.data));
    authService.setAuthToken(response.token);
    await this.setLoggedInUser(response.user.data);
    return response.user.data;
  }

  @action
  toggleAnsweredQuestionnaire(val: boolean) {
    this.loggedInUser.answered_questionnaire = val;
  }

  @action
  toggleCRSTokenExists(val: boolean) {
    this.loggedInUser.crs_token_exists = val;
  }

  @action
  toggleCRSTokenExpired(val: boolean) {
    this.loggedInUser.crs_token_expired = val;
  }

  @action
  async getLoggedInUserPHA(): Promise<void> {
    if (this.loggedInUserPHA) {
      return;
    }

    this.setIsLoadingLoggedInUserPHA(true);
    const response = await this.apiService.get(ApiRoutes.me.agentPHA);
    this.loggedInUserPHA = response.data as PHA;
    this.setIsLoadingLoggedInUserPHA(false);
  }

  @action
  clearStore(): void {
    this.isImageUploading = false;
    this.loadingSignedUrl = false;
    this.isLoadingLoggedInUser = false;
    this.payrollsLoaded = false;
    this.currentApplicant = new ModelItem<Applicant>(Applicant);
    this.loggedInUser = null;
    this.loggedInUserOrg = null;
    this.loggedInUserPHA = null;
    this.fetchPayrolls = true;
  }
}
