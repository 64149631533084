import { action, observable } from 'mobx';
import { ApplicationStep } from 'models/ApplicationStep';
import { ApiService } from '../services/ApiService';
import ApiRoutes from '../routes/api/ApiRoutes';

export class StepStore {
  @observable private _currentStep: ApplicationStep;

  @observable importingBankingInfo: boolean;

  @observable stepError: any;

  @action setCurrentStep = (step: ApplicationStep) =>
    (this._currentStep = step);

  @action setImportingBankingInfo = (b: boolean) =>
    (this.importingBankingInfo = b);

  @action setStepError = (e: any) => (this.stepError = e);

  get currentStep() {
    return this._currentStep ?? ApplicationStep.TERMS_AND_CONDITIONS;
  }

  protected apiService = ApiService.getInstance();

  async importAccountInformation(user, metaData: any) {
    try {
      this.setImportingBankingInfo(true);
      const response = await this.apiService.post(
        ApiRoutes.me.importAccountInformation,
        { ...metaData },
      );
      user && user.updateFromJson({ ...response.data, delete: false });
    } catch (e) {
      throw e;
    } finally {
      this.setImportingBankingInfo(false);
    }
  }

  @action
  async completeApplication(id: number, params?: { [key: string]: any }) {
    return this.apiService.post(ApiRoutes.me.completeApplication(id), params);
  }

  @action
  clearStore(): void {
    this._currentStep = null;
    this.importingBankingInfo = false;
    this.stepError = null;
  }
}
