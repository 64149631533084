/* eslint-disable import/no-cycle */
import { action, observable } from 'mobx';
import { ModelItem } from 'models/ModelItem';
import { ConsumerService } from 'constants/constants';

import { Model } from '../Model';
import { OrganizationStore } from '../../stores/OrganizationStore';
import { City } from './CityModel';
import { State } from './StateModel';
export enum InsuranceType {
  DISABLED = 'disabled',
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export interface W9FormData {
  name: string;
  business_name: string;
  federal_tax: string;
  city: string;
  address: string;
  account_number: string;
  requester_name: string;
  date: string;
  payee_code?: string;
  fatca_code?: string;
  social_security_number?: string;
  employer_identification_number?: string;
  other?: string;
  classification?: string;
}
export class Organization extends Model {
  static _store: OrganizationStore;

  @observable id: number;

  @observable name: string;

  @observable owner_id: number;

  @observable owner: any;

  @observable primary_name: string;

  @observable primary_email: string;

  @observable primary_phone: string;

  @observable insurance_type: InsuranceType;

  @observable address: string;

  @observable city_id: number;

  @observable state_id: number;

  @observable city = new ModelItem<City>(City);

  @observable state = new ModelItem<State>(State);

  @observable zip_code: string;

  @observable is_agent_enabled: boolean;

  @observable appfolio_account_id: number;

  @observable dtc_enabled: boolean;

  @observable pha_voucher_enabled: boolean;

  @observable inspector_flow_enabled: boolean;

  @observable payment_enabled: boolean;

  @observable tax_documents_required: boolean;

  @observable valid_govt_id_required: boolean;

  @observable consumer_service: ConsumerService;

  @observable consumer_username: string;

  @observable consumer_interface: string;

  @observable consumer_password: string;

  @observable api_key: string;

  @observable stripe_account_id: string;

  @observable support_tab_enabled: boolean;

  @observable allow_refund: boolean;

  @observable crs_token_exists: boolean;

  @observable allow_waitlist: boolean;

  @observable crs_token_expired: boolean;

  @observable terms_accepted_at: string;

  @observable tnc_url: string;

  @observable signator_name: string;

  @observable w9_form_data: W9FormData;

  @action
  setTermsAcceptedAt(b: string) {
    this.terms_accepted_at = b;
  }
}
