import React from 'react';
import Logo from '../../assets/logo.png';

const PageNotFound: React.FC = () => {
  return (
    <div className="empty h-screen w-full flex flex-col items-center justify-center">
      <img src={Logo} alt="Logo" className="w-1/4 object-cover" />
      <span className="mt-8 text-darkText text-4xl font-regular text-center ">
        Sorry, we can’t find the page you were looking for!!!
      </span>
    </div>
  );
};

export default PageNotFound;
