import { observable } from 'mobx';
import Model from 'models/Model';
import { SystemLogsStore } from 'stores/systemLogsStore';
export enum SystemLogType {
  ENABLE_USER = 'enable_user',
}

export class SystemLogs extends Model {
  static _store: SystemLogsStore;

  @observable id: number;

  @observable type: SystemLogType;

  @observable meta: JSON;

  @observable createdAt: string;

  @observable updatedAt: string;
}
