import { InviteType } from 'enums/InviteType';
import { Applicant } from './entities/ApplicantModel';
import { Application } from './entities/ApplicationModel';
import { Organization } from './entities/OrganizationModel';
import { User } from './entities/UserModel';
import { ConsumerService } from '../constants/constants';
import { Unit } from './entities/UnitModel';
import { ApplicationSource } from 'enums/ApplicationSource.enum';

export enum ApplicationStep {
  LANDING = 'landing',
  TERMS_AND_CONDITIONS = 'terms_and_conditions',
  CO_APPLICANTS_AND_GUARANTOR = 'co_applicants_and_guarantor',
  GOVERNMENT_IDENTIFICATION = 'government_identification',
  YOUR_INFORMATION = 'your_information',
  PHA_VOUCHER = 'pha_voucher',
  PAYMENT_DETAILS = 'payment_details',
  CREDIT_REPORTING_SERVICE = 'credit_reporting_service',
  BANK_STATEMENTS = 'bank_statements',
  EMPLOYMENT_INFORMATION = 'employment_information',
  TAX_DOCUMENTS = 'tax_documents',
  RESIDENCE_HISTORY = 'residence_history',
  SUBMISSION = 'submission',
  CONFIRM_DETAILS = 'confirm_details',
  INSURANCE_PLAN = 'insurance_plan',
  LEASE_PENDING = 'lease_pending',
  DOCUSIGN_SENT = 'docusign_sent',
  DOCUSIGN_COMPLETE_TENANT = 'docusign_complete_tenant',
  DOCUSIGN_COMPLETE_LANDLORD = 'docusign_complete_landlord',
  LEASE_COMPLETED = 'lease_completed',
  APPLICATION_COMPLETED = 'application_completed',
}

export const getNextStep = (
  currentStep: ApplicationStep,
  application: Application,
  currentApplicant?: Applicant,
  organisation?: Organization,
  user?: User,
  unit?: Unit,
): ApplicationStep => {
  const _map: { [currentStep: string]: ApplicationStep } = {
    [ApplicationStep.LANDING]: ApplicationStep.TERMS_AND_CONDITIONS,
    [ApplicationStep.TERMS_AND_CONDITIONS]:
      ApplicationStep.CO_APPLICANTS_AND_GUARANTOR,
    [ApplicationStep.CO_APPLICANTS_AND_GUARANTOR]:
      ApplicationStep.GOVERNMENT_IDENTIFICATION,
    [ApplicationStep.GOVERNMENT_IDENTIFICATION]:
      ApplicationStep.YOUR_INFORMATION,
    [ApplicationStep.YOUR_INFORMATION]: ApplicationStep.PHA_VOUCHER,
    [ApplicationStep.PHA_VOUCHER]: ApplicationStep.PAYMENT_DETAILS,
    [ApplicationStep.PAYMENT_DETAILS]: ApplicationStep.CREDIT_REPORTING_SERVICE,
    [ApplicationStep.CREDIT_REPORTING_SERVICE]: ApplicationStep.BANK_STATEMENTS,
    [ApplicationStep.BANK_STATEMENTS]: ApplicationStep.EMPLOYMENT_INFORMATION,
    [ApplicationStep.EMPLOYMENT_INFORMATION]: ApplicationStep.TAX_DOCUMENTS,
    [ApplicationStep.TAX_DOCUMENTS]: ApplicationStep.RESIDENCE_HISTORY,
    [ApplicationStep.RESIDENCE_HISTORY]: ApplicationStep.SUBMISSION,
    [ApplicationStep.SUBMISSION]: ApplicationStep.CONFIRM_DETAILS,
    [ApplicationStep.CONFIRM_DETAILS]: ApplicationStep.INSURANCE_PLAN,
    [ApplicationStep.INSURANCE_PLAN]: ApplicationStep.LEASE_PENDING,
    [ApplicationStep.LEASE_PENDING]: ApplicationStep.DOCUSIGN_SENT,
    [ApplicationStep.DOCUSIGN_SENT]: ApplicationStep.DOCUSIGN_COMPLETE_TENANT,
    [ApplicationStep.DOCUSIGN_COMPLETE_TENANT]:
      ApplicationStep.DOCUSIGN_COMPLETE_LANDLORD,
    [ApplicationStep.DOCUSIGN_COMPLETE_LANDLORD]:
      ApplicationStep.LEASE_COMPLETED,
    [ApplicationStep.LEASE_COMPLETED]: ApplicationStep.APPLICATION_COMPLETED,
  };

  let nextStep = _map[currentStep];

  if (
    nextStep === ApplicationStep.CO_APPLICANTS_AND_GUARANTOR &&
    currentApplicant.role === InviteType.GUARANTOR
  ) {
    nextStep = _map[nextStep];
  }

  if (
    !(
      unit.building?.item?.pha_voucher_enabled ||
      organisation?.pha_voucher_enabled
    ) &&
    nextStep === ApplicationStep.PHA_VOUCHER
  ) {
    nextStep = _map[nextStep];
  }

  if (
    (!organisation?.payment_enabled ||
      application.source ===
        ApplicationSource.MATRIX_CONNECT_FROM_PHA_INVITE) &&
    nextStep === ApplicationStep.PAYMENT_DETAILS
  ) {
    nextStep = _map[nextStep];
  }

  if (
    nextStep === ApplicationStep.CREDIT_REPORTING_SERVICE &&
    !(
      user?.credit_history_outside_usa ||
      organisation?.consumer_service === ConsumerService.EXPERIAN
    )
  ) {
    nextStep = _map[nextStep];
  }

  if (
    nextStep === ApplicationStep.TAX_DOCUMENTS &&
    !organisation.tax_documents_required
  ) {
    nextStep = _map[nextStep];
  }

  return nextStep;
};

export const getPreviousStep = (
  currentStep: ApplicationStep,
  application: Application,
  currentApplicant?: Applicant,
  organisation?: Organization,
  user?: User,
  unit?: Unit,
): ApplicationStep => {
  const _map: { [currentStep: string]: ApplicationStep } = {
    [ApplicationStep.TERMS_AND_CONDITIONS]: ApplicationStep.LANDING,
    [ApplicationStep.CO_APPLICANTS_AND_GUARANTOR]:
      ApplicationStep.TERMS_AND_CONDITIONS,
    [ApplicationStep.GOVERNMENT_IDENTIFICATION]:
      ApplicationStep.CO_APPLICANTS_AND_GUARANTOR,
    [ApplicationStep.YOUR_INFORMATION]:
      ApplicationStep.GOVERNMENT_IDENTIFICATION,
    [ApplicationStep.PHA_VOUCHER]: ApplicationStep.YOUR_INFORMATION,
    [ApplicationStep.PAYMENT_DETAILS]: ApplicationStep.PHA_VOUCHER,
    [ApplicationStep.CREDIT_REPORTING_SERVICE]: ApplicationStep.PAYMENT_DETAILS,
    [ApplicationStep.BANK_STATEMENTS]: ApplicationStep.CREDIT_REPORTING_SERVICE,
    [ApplicationStep.EMPLOYMENT_INFORMATION]: ApplicationStep.BANK_STATEMENTS,
    [ApplicationStep.TAX_DOCUMENTS]: ApplicationStep.EMPLOYMENT_INFORMATION,
    [ApplicationStep.RESIDENCE_HISTORY]: ApplicationStep.TAX_DOCUMENTS,
    [ApplicationStep.SUBMISSION]: ApplicationStep.RESIDENCE_HISTORY,
    [ApplicationStep.CONFIRM_DETAILS]: ApplicationStep.SUBMISSION,
    [ApplicationStep.INSURANCE_PLAN]: ApplicationStep.CONFIRM_DETAILS,
    [ApplicationStep.LEASE_PENDING]: ApplicationStep.INSURANCE_PLAN,
    [ApplicationStep.DOCUSIGN_SENT]: ApplicationStep.LEASE_PENDING,
    [ApplicationStep.DOCUSIGN_COMPLETE_TENANT]: ApplicationStep.DOCUSIGN_SENT,
    [ApplicationStep.DOCUSIGN_COMPLETE_LANDLORD]:
      ApplicationStep.DOCUSIGN_COMPLETE_TENANT,
    [ApplicationStep.LEASE_COMPLETED]:
      ApplicationStep.DOCUSIGN_COMPLETE_LANDLORD,
    [ApplicationStep.APPLICATION_COMPLETED]: ApplicationStep.LEASE_COMPLETED,
  };

  let nextStep = _map[currentStep];

  if (
    nextStep === ApplicationStep.CREDIT_REPORTING_SERVICE &&
    !(
      user?.credit_history_outside_usa ||
      organisation?.consumer_service === ConsumerService.EXPERIAN
    )
  ) {
    nextStep = _map[nextStep];
  }
  if (
    (!organisation?.payment_enabled ||
      application.source ===
        ApplicationSource.MATRIX_CONNECT_FROM_PHA_INVITE) &&
    nextStep === ApplicationStep.PAYMENT_DETAILS
  ) {
    nextStep = _map[nextStep];
  }

  if (
    !(
      unit?.building?.item?.pha_voucher_enabled ||
      organisation?.pha_voucher_enabled
    ) &&
    nextStep === ApplicationStep.PHA_VOUCHER
  ) {
    nextStep = _map[nextStep];
  }

  if (
    nextStep === ApplicationStep.CO_APPLICANTS_AND_GUARANTOR &&
    currentApplicant.role === InviteType.GUARANTOR
  ) {
    nextStep = _map[nextStep];
  }

  if (
    nextStep === ApplicationStep.TAX_DOCUMENTS &&
    !organisation.tax_documents_required
  ) {
    nextStep = _map[nextStep];
  }

  return nextStep;
};

export const getIndexOfApplicationStep = (step: ApplicationStep): number => {
  return Object.values(ApplicationStep).findIndex((value) => value === step);
};
