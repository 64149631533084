import { observable } from 'mobx';
import Model from '../Model';
import { GuestCardChatType } from '../../enums/GuestCardChatType.enum';
import { GuestCardChatStore } from '../../stores/GuestCardChatStore';

export class GuestCardChat extends Model {
  static _store: GuestCardChatStore;

  @observable id: number;

  @observable guest_card_id: number;

  @observable type: GuestCardChatType;

  @observable message_body: string;

  @observable read: boolean;

  @observable createdAt: string;

  @observable updatedAt: string;
}
