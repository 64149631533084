import { action } from 'mobx';
import Store from './Store';
import { Payment } from '../models/entities/PaymentModel';

export class PaymentStore extends Store<Payment> {
  constructor() {
    super();
    Payment._store = this;
  }

  @action
  clearStore(): void {}
}
