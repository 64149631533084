import { action, computed, observable } from 'mobx';

import Store from './Store';
import { Unit } from '../models/entities/UnitModel';
import { ModelItem } from '../models/ModelItem';
import ApiRoutes from '../routes/api/ApiRoutes';
import { PaginatedModelList } from '../models/PaginatedModelList';
import { DataFinitiPropertyDetail } from '../interfaces/DataFinitiPropertyDetail.interface';
import { set } from 'lodash';
import { Utils } from '../utils/utils';
import { getFormattedDateWithoutTime } from '../utils/helpers';

export class UnitStore extends Store<Unit> {
  constructor() {
    super();
    Unit._store = this;
  }

  @observable unitList = new PaginatedModelList<Unit>(Unit);

  @observable unitItem = new ModelItem<Unit>(Unit);

  @computed get units() {
    return this.unitList.items;
  }

  @computed get unit() {
    return this.unitItem.item;
  }

  @action
  async fetchUnits(params?: { [key: string]: any }) {
    return this.unitList.load(ApiRoutes.units.list, params);
  }

  @action async fetchUnitFromId(id: number, appendIntoStore = false) {
    const promise = this.unitItem.load(ApiRoutes.units.show(id));
    if (appendIntoStore) {
      promise.then(() => {
        const alreadyPresent = this.unitList.items.find(
          (item) => item.id === this.unitItem.item?.id,
        );
        if (!alreadyPresent) {
          this.unitList.appendItem(this.unitItem.item);
        }
      });
    }
    return promise;
  }

  @action async fetchUnitFromInvite(inviteId: number) {
    return this.unitItem.load(ApiRoutes.public.units.show(inviteId));
  }

  @action async fetchGuestApplyUnit(unitId: number) {
    return this.unitItem.load(ApiRoutes.public.units.guestApplyUnit(unitId));
  }

  @action
  async updateElement(unit: Unit, body: { [key: string]: any }) {
    const { unit_image } = body;
    const formData = new FormData();
    if (unit_image !== null) {
      Object.keys(body).forEach((key) => formData.append(key, body[key]));
    }
    const response = await this.apiService.patch(
      ApiRoutes.units.show(unit.id),
      body.unit_image ? formData : body,
    );
    unit.updateFromJson(response.data);
    return unit;
  }

  @action
  async addImage(unit: any, body: { [key: string]: any }) {
    unit.setUpdating(true);
    const formData = new FormData();
    Object.keys(body).forEach((key) => formData.append(key, body[key]));
    const response = await this.apiService.post(
      ApiRoutes.buildingUnit.addImage(+unit.id),
      formData,
    );

    unit.updateFromJson({
      unit_images: [...unit?.unit_images?.items, response],
    });
    return response;
  }

  @action
  async removeImage(unit: any, body: { [key: string]: any }) {
    const response = await this.apiService.delete(
      ApiRoutes.buildingUnit.deleteImage(+unit.id),
      null,
      null,
      null,
      body,
    );
    unit.updateFromJson({
      unit_images: unit.unit_images?.items.filter(
        (item) => item.id !== body?.property_id,
      ),
    });
    return response;
  }

  @action
  async createUnit(body: { [key: string]: any }) {
    const formData = new FormData();
    Object.keys(body).forEach((key) => formData.append(key, body[key]));
    const response = await this.apiService.post(ApiRoutes.units.list, formData);
    const unit = Unit.fromJson(response.data) as Unit;
    // TODO: add only if filters allows.
    this.unitList.appendItem(unit);
    return unit;
  }

  @action
  async fetchPropertyDataByAddress(
    address: string,
    city: string,
    country: string,
  ): Promise<DataFinitiPropertyDetail> {
    const body = {
      address,
      city,
      country,
    };
    const response = await this.apiService.post(
      ApiRoutes.units.fetchPropertyDataByAddress,
      body,
    );

    return response.data;
  }

  @action
  async contactLandlord(
    unit_id: number,
    message: string,
    preferredTourDates?: Date[],
  ) {
    const data = { message };
    if (preferredTourDates.length > 0) {
      const formatedDates = preferredTourDates.map((_d) =>
        getFormattedDateWithoutTime(_d.toString()),
      );
      set(data, ['preferred_tour_dates'], formatedDates);
    }
    await this.apiService.post(ApiRoutes.units.contactLandlord(unit_id), data);
  }
  @action
  clearStore(): void {
    this.unitList = new PaginatedModelList<Unit>(Unit);
    this.unitItem = new ModelItem<Unit>(Unit);
  }
}
