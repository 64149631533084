import { observable } from 'mobx';
import Model from 'models/Model';
import { OrganisationPropertyUrlStatusType } from 'enums/OrganisationPropertyUrlStatusType';
import { Organization } from './OrganizationModel';

export class OrganisationPropertyUrl extends Model {
  @observable id: number;

  @observable organisation_id: number;

  @observable user_id: number;

  @observable file_url: string;

  @observable status: OrganisationPropertyUrlStatusType;

  @observable meta: JSON;

  @observable createdAt: Date;

  @observable updatedAt: Date;

  @observable organisation: Organization;
}
