import { observable } from 'mobx';
import Model from '../Model';
import { ResidenceHistoryStore } from '../../stores/ResidenceHistoryStore';

export class ResidenceHistory extends Model {
  static _store: ResidenceHistoryStore;

  @observable id: number;

  @observable user_id: number;

  @observable address_line_1: string;

  @observable address_line_2: string;

  @observable state_name: string;

  @observable city_name: string;

  @observable country: string;

  @observable phone_number: string;

  @observable zip_code: number;

  @observable landlord_name: string;

  @observable landlord_phone_number: string;

  @observable landlord_email: string;

  @observable previous_rent: number;

  @observable move_in_date: string;

  @observable move_out_date: string;

  @observable currently_living: boolean;
}
