import { action, observable } from 'mobx';
import ApiRoutes from 'routes/api/ApiRoutes';
import { Bank } from 'models/entities/BankModel';
import { PaginatedModelList } from 'models/PaginatedModelList';
import { BankTransaction } from 'models/entities/BankTransactionModel';
import { QueuedDocumentUploadStatus } from 'models/entities/QueuedDoc';
import { DocumentStatus } from 'enums/DocumentStatus.enum';
import Store from './Store';
import { DocumentModel } from '../models/entities/DocumentModel';

export class DocumentStore extends Store<DocumentModel> {
  @observable
  rejectedBankStatementsPaginatedModelList = new PaginatedModelList<
    DocumentModel
  >(DocumentModel);

  @observable requestedApplications = [];

  @observable bankAccounts = [];

  @observable groupedApplications = [];

  @observable bankTransactionsPaginatedList = new PaginatedModelList<
    BankTransaction
  >(BankTransaction);

  @observable banksPaginatedList = new PaginatedModelList<Bank>(Bank);

  @observable nameOfApplicantWhoseDocumentIsBeingProcessed = '';

  constructor() {
    super();
    DocumentModel._store = this;
  }

  get rejectedBankStatements(): DocumentModel[] {
    return this.rejectedBankStatementsPaginatedModelList.items;
  }

  get banks(): Bank[] {
    return this.banksPaginatedList.items;
  }

  get bankTransactions() {
    return this.bankTransactionsPaginatedList.items?.map((item, index) => {
      return {
        id: index + 1,
        details: item,
      };
    });
  }

  @action
  setNameOfApplicantWhoseDocumentIsBeingProcessed(name: string) {
    this.nameOfApplicantWhoseDocumentIsBeingProcessed = name;
  }

  @action
  async fetchBanks(): Promise<void> {
    return this.banksPaginatedList.load(ApiRoutes.bank.fetchBanks);
  }

  // @action
  // async loadRejectedBankStatements(): Promise<void> {
  //   return this.rejectedBankStatementsPaginatedModelList.load(
  //     ApiRoutes.bank.getRejectedBankStatements,
  //   );
  // }

  @action
  async getRequestedApplications(params?: {
    [key: string]: any;
  }): Promise<void> {
    const response = await this.apiService.get(
      ApiRoutes.applications.getAllRequestedDocuments,
      params,
    );
    this.requestedApplications = response.map((obj) => obj.requested_against);
  }

  @action
  async getBankAccounts(params?: { [key: string]: any }): Promise<void> {
    const response = await this.apiService.get(
      ApiRoutes.bank.bankAccounts,
      params,
    );
    this.bankAccounts = response;
  }

  @action
  async addNewBankAccount(data: { [key: string]: any }): Promise<void> {
    const response = await this.apiService.post(
      ApiRoutes.bank.bankAccount,
      data,
    );
    this.bankAccounts.push(response);
  }

  @action
  async fetchBankTrasactions(documentId: number): Promise<void> {
    return this.bankTransactionsPaginatedList.load(
      ApiRoutes.bank.fetchTransactions(documentId),
    );
  }

  @action
  async addBanktransaction(
    body: { [key: string]: any },
    applicationId?: number,
    isWaitlistApp?: boolean,
  ): Promise<void> {
    const res = await this.apiService.post(ApiRoutes.bank.addTransaction, body);
    if (body.isComplete) {
      const submittedDoc = this.rejectedBankStatements.filter(
        (statement) => statement.id === body.bank_statement_id,
      )[0];
      this.rejectedBankStatementsPaginatedModelList.removeItem(submittedDoc);
      await this.getBankStatementsByApplicationId(
        applicationId,
        isWaitlistApp,
        true,
      );
    } else {
      this.bankTransactionsPaginatedList.appendItem(res.data);
    }
  }

  async editBankTransaction(body: { [key: string]: any }): Promise<void> {
    const response = await this.apiService.patch(
      ApiRoutes.bank.editTransaction,
      body,
    );
    const transactions: any = this.bankTransactionsPaginatedList.items?.map(
      (tnx) => {
        if (tnx.id === response.data.id) {
          return response.data;
        }
        return tnx;
      },
    );
    this.bankTransactionsPaginatedList.setItems([...transactions]);
  }

  async deleteTransaction(transactionId: number) {
    const response = await this.apiService.delete(
      ApiRoutes.bank.deleteTransaction,
      { transactionId },
    );
    this.bankTransactionsPaginatedList.setItems(
      this.bankTransactionsPaginatedList.items?.filter(
        (tnx) => tnx.id !== transactionId,
      ) as any,
    );

    return response;
  }

  @action
  async moveDocument(
    data: any,
    userId: number,
    documentType: string,
    desiredDocumentType: string,
    taxReturnType?: string,
    move_reason?: string,
  ): Promise<any> {
    const body = {
      userId,
      documentType,
      desiredDocumentType,
      taxReturnType,
      move_reason,
    };
    const documentId = data.id;
    try {
      const response = await this.apiService.post(
        ApiRoutes.payroll.moveDocument(documentId),
        body,
      );
      this.rejectedBankStatementsPaginatedModelList.removeItem(data);
      return response;
    } catch (e) {
      if ((e as any).code === 9998) {
        this.rejectedBankStatementsPaginatedModelList.removeItem(data);
      }
      throw e;
    }
  }

  async requestDocument(
    docName: string,
    request_description: string,
    userId: number,
    applicationId: number,
    upload_against?: number,
    default_template = false,
    waitingListApplicantId?: number,
  ): Promise<void> {
    this.apiService.post(
      waitingListApplicantId
        ? ApiRoutes.waitlistApplicantDocument.requestDocument(
            waitingListApplicantId,
          )
        : ApiRoutes.payroll.requestDocument(applicationId),
      {
        user_id: userId,
        request_description,
        request_message: docName,
        upload_against,
        default_template,
      },
    );
    this.rejectedBankStatementsPaginatedModelList.items.filter(
      (item) => item.id === upload_against,
    )[0].status = QueuedDocumentUploadStatus.REQUESTED;
  }

  @action
  async rejectDocument(
    bankStatement: DocumentModel,
    rejection_reason: string,
  ): Promise<any> {
    const response = await this.apiService.delete(
      ApiRoutes.bank.deleteBankStatement(bankStatement.id),
      null,
      null,
      null,
      { rejection_reason },
    );
    this.rejectedBankStatementsPaginatedModelList.removeItem(bankStatement);
    return response;
  }

  @action
  async getGroupedApplications(organisation?: number): Promise<void> {
    const response = await this.apiService.get(
      ApiRoutes.bank.getGroupedApplications,
      { organisation },
    );
    this.groupedApplications = response.data;
  }

  async getBankStatementsByApplicationId(
    applicationId: number,
    isWaitlistApp: boolean,
    forceRefresh?: boolean,
  ): Promise<void> {
    await this.rejectedBankStatementsPaginatedModelList.load(
      ApiRoutes.bank.getRejectedBankStatementById(applicationId),
      { isWaitlistApp },
      { forceRefresh },
    );
  }

  @action
  clearStore(): void {
    this.rejectedBankStatementsPaginatedModelList = new PaginatedModelList<
      DocumentModel
    >(DocumentModel);
    this.bankAccounts = [];
    this.requestedApplications = [];
    this.bankTransactionsPaginatedList = new PaginatedModelList<
      BankTransaction
    >(BankTransaction);
  }
}
