import Axios, {
  AxiosRequestConfig,
  CancelToken,
  CancelTokenSource,
} from 'axios';
import URI from 'urijs';
import { ErrorCode, MatrixError, UploadErrorCode } from 'models/MatrixError';
import { Utils } from 'utils/utils';
import * as rdd from 'react-device-detect';
import { notification } from 'antd';

interface RequestConfig extends AxiosRequestConfig {
  requestId?: string;
  redirectIfUnauthorized?: boolean;
  useBaseUrl?: boolean;
  useAuthHeaders?: boolean;
}

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export class ApiService {
  private static instance = new ApiService();

  private requestMap = new Map<string, CancelTokenSource>();

  private unreportedErrors = [ErrorCode.UNAUTHORIZED, ErrorCode.NOT_FOUND];

  static getInstance() {
    return this.instance;
  }

  get(url: string, params?: any, headers?: any, requestId?: string) {
    return this.request({ method: 'GET', url, headers, params, requestId });
  }

  delete(
    url: string,
    params?: any,
    headers?: any,
    requestId?: string,
    data?: any,
  ) {
    return this.request({
      method: 'DELETE',
      url,
      headers,
      params,
      requestId,
      data,
    });
  }

  post(
    url: string,
    data?: any,
    headers?: any,
    params?: any,
    requestId?: string,
  ) {
    return this.request({
      method: 'POST',
      url,
      data,
      headers,
      params,
      requestId,
    });
  }

  put(
    url: string,
    data?: any,
    useBaseUrl = true,
    useAuthHeaders = true,
    headers?: any,
    params?: any,
    requestId?: string,
    onUploadProgress?: any,
  ) {
    return this.request({
      method: 'PUT',
      url,
      data,
      headers,
      params,
      requestId,
      useBaseUrl,
      useAuthHeaders,
      onUploadProgress,
    });
  }

  patch(
    url: string,
    data?: any,
    headers?: any,
    params?: any,
    requestId?: string,
  ) {
    return this.request({
      method: 'PATCH',
      url,
      data,
      headers,
      params,
      requestId,
    });
  }

  generateHeaders = (additionalHeaders, useAuthHeaders: boolean) => {
    const headers = {
      ...additionalHeaders,
    };

    if (useAuthHeaders) {
      headers.Authorization = localStorage.getItem('auth_token');
    }
    let deviceType;
    if (rdd.isDesktop) {
      deviceType = 'desktop';
    } else if (rdd.isMobile) {
      deviceType = 'mobile';
    } else if (rdd.isTablet) {
      deviceType = 'tablet';
    }

    headers['os-name'] = rdd.osName;
    headers['device-type'] = deviceType;

    return { ...headers };
  };

  // TODO: pass token only when required.
  private async request({
    useBaseUrl = true,
    useAuthHeaders = true,
    ...config
  }) {
    const cancelToken = this.addToRequestMap(config.requestId);
    try {
      const response = await Axios.request({
        baseURL: useBaseUrl ? BASE_URL : undefined,
        cancelToken,
        ...config,
        headers: this.generateHeaders(config.headers, useAuthHeaders),
      });
      this.removeFromRequestMap(config.requestId);
      return response?.data;
    } catch (e) {
      const error = e as any;
      const errorStatus = error?.response?.status;
      const errorCode = error.response?.data?.code;
      if (errorStatus === ErrorCode.UNAUTHORIZED) {
        window.location.href = URI('/auth/login').query({
          redirect_to: URI().pathname(),
        });
      }
      if (errorCode === UploadErrorCode.PHA_INVITE_ALREADY_ACCEPTED) {
        notification.error({
          message: 'Invite already accepted',
          description: 'Please login to continue',
        });
        setTimeout(() => {
          window.location.replace(URI('/auth/login'));
        }, 2000);
      }
      if (this.shouldReportToSentry(errorStatus)) {
        // Report To sentry or any other service.
      }
      if (error.response && error.response.data) {
        // console.log(error.response.data);
      } else {
        console.log(error);
      }
      throw MatrixError.from(error);
    }
  }

  private shouldReportToSentry(errorStatus: ErrorCode) {
    return !this.unreportedErrors.includes(errorStatus);
  }

  private addToRequestMap(requestId?: string): CancelToken {
    if (!requestId) {
      return undefined;
    }

    const source = Axios.CancelToken.source();
    this.requestMap.set(requestId, source);
    return source.token;
  }

  private removeFromRequestMap(requestId?: string) {
    if (!requestId) {
      return;
    }

    this.requestMap.delete(requestId);
  }

  generateRequsetId(): string {
    return Utils.getRandomString();
  }

  cancelRequest(requestId: string) {
    const source = this.requestMap.get(requestId);
    source && source.cancel();
  }
}
