import { observable } from 'mobx';
import Model from 'models/Model';
// eslint-disable-next-line import/no-cycle
import { WaitListInviteStore } from 'stores/WaitListInviteStore';
import { ModelItem } from 'models/ModelItem';
import { Building } from './BuildingModel';

export enum WaitListInviteStatus {
  SENT = 'sent',
  ACCEPTED = 'accepted',
}

export class WaitListInvite extends Model {
  static _store: WaitListInviteStore;

  @observable id: number;

  @observable first_name: string;

  @observable last_name: number;

  @observable email: string;

  @observable phone_number: string;

  @observable status: WaitListInviteStatus;

  @observable invited_by: number;

  @observable expired_at: Date;

  @observable building_id: number;

  @observable building = new ModelItem<Building>(Building);

  get fullname(): string {
    const name = [this.first_name, this.last_name];
    return name.filter((v) => !!v).join(' ');
  }
}
