class StorageService {
  static getInstance(): StorageService {
    return new StorageService();
  }

  get getFirstTimeVisited() {
    return localStorage.getItem('first_time_visited');
  }

  setFirstTimeVisited = (value: string) => {
    localStorage.setItem('first_time_visited', value);
  };
}

export const storageService = StorageService.getInstance();
