/* eslint-disable import/no-cycle */
import { action, observable } from 'mobx';
import ApiRoutes from 'routes/api/ApiRoutes';
import { ModelItem } from 'models/ModelItem';
import { WaitingListSteps } from 'models/WaitingListStep';
import { DocumentStatus } from 'enums/DocumentStatus.enum';
import { notification } from 'antd';
import Store from './Store';
import { WaitingListApplicant } from '../models/entities/WaitingListApplicantModel';
import { PaginatedModelList } from '../models/PaginatedModelList';

export class WaitingListApplicantStore extends Store<WaitingListApplicant> {
  constructor() {
    super();
    WaitingListApplicant._store = this;
  }

  @observable waitingListApplicant = new ModelItem<WaitingListApplicant>(
    WaitingListApplicant,
  );

  @observable
  waitingListApplicantsPaginatedList = new PaginatedModelList<
    WaitingListApplicant
  >(WaitingListApplicant);

  @observable currentStep: WaitingListSteps;

  // eslint-disable-next-line no-return-assign
  @action setCurrentStep = (step: WaitingListSteps) =>
    (this.currentStep = step);

  @action
  async fetchMonitoringTenants(params?: { [key: string]: any }): Promise<void> {
    return this.waitingListApplicantsPaginatedList.load(
      ApiRoutes.tenantMonitoring.list,
      params,
    );
  }

  @action
  clearStore(): void {
    this.currentStep = null;
    this.waitingListApplicantsPaginatedList = new PaginatedModelList<
      WaitingListApplicant
    >(WaitingListApplicant);
  }

  @action
  async removeTenantMxMonitoring(tenant: WaitingListApplicant): Promise<void> {
    const response = await this.apiService.patch(
      ApiRoutes.tenantMonitoring.remove(tenant.id),
    );
    if (response) {
      this.waitingListApplicantsPaginatedList.removeItem(tenant);
    }
    return response;
  }

  @action async reGenerateReport(tenant: WaitingListApplicant) {
    const response = await this.apiService.post(
      ApiRoutes.tenantMonitoring.regenerate(tenant.id),
    );

    if (response) {
      (this.waitingListApplicantsPaginatedList.items as any).forEach(
        (applicant) => {
          if (applicant.id === tenant.id) {
            // tenant.report_url = response
            // applicant.report_url = response?.data?.report_url;
            // applicant.last_updated = response?.data?.last_updated;
          }
        },
      );
    }

    return response;
  }

  @action
  async completeWaitlistApplicantDocumentUpload(
    waitlistApplicantId: number,
    documentId: number,
    uploadCount?: number,
  ) {
    await this.apiService.post(
      ApiRoutes.waitlistApplicantDocument.complete(
        waitlistApplicantId,
        documentId,
      ),
      {
        upload_count: uploadCount,
      },
    );
  }

  @action
  async getWaitlistApplicantDocuments(
    waitlistApplicantId: number,
    status: DocumentStatus,
  ) {
    const response = await this.apiService.get(
      ApiRoutes.waitlistApplicantDocument.list(waitlistApplicantId),
      { status },
    );
    return response;
  }

  @action
  async getExistingApplicationDocumentSignedUrl(
    waitlistApplicantId: number,
    docId: number,
    file: File,
  ): Promise<any> {
    const body = {
      file_name: file.name,
      content_type: file.type,
    };
    return this.apiService.post(
      ApiRoutes.waitlistApplicantDocument.signedUrlForExisting(
        waitlistApplicantId,
        docId,
      ),
      body,
    );
  }

  @action
  async confirmDocumentUpload(
    waitlistApplicantId: number,
    docId: number,
    renterDocumentId: number,
  ): Promise<void> {
    await this.apiService.put(
      ApiRoutes.waitlistApplicantDocument.confirmUpload(
        waitlistApplicantId,
        docId,
        renterDocumentId,
      ),
    );
  }

  @action
  async getNewApplicationDocumentSignedUrl(
    file: File,
    waitingListApplicantId: number,
    userId: number,
    docType: string,
  ): Promise<any> {
    const body = {
      file_name: file.name,
      content_type: file.type,
      user_id: userId,
      doc_type: docType,
    };

    const response = await this.apiService.post(
      ApiRoutes.waitlistApplicantDocument.signedUrlForNew(
        waitingListApplicantId,
      ),
      body,
    );
    return response;
  }

  @action
  async requestDocument(
    docName: string,
    request_description: string,
    userId: number,
    waitlist_applicant_id: number,
    upload_against?: number,
  ): Promise<any> {
    const response = await this.apiService.post(
      ApiRoutes.waitlistApplicantDocument.list(waitlist_applicant_id),
      {
        user_id: userId,
        request_description,
        request_message: docName,
        upload_against,
      },
    );
    if (response.data) {
      notification.success({
        message: `${docName} document requested successfully.`,
        duration: 2,
      });
    }
    return response;
  }

  async deleteDocRequestAndProcess(
    waitlistApplciantId: number,
    userId: number,
    requestMessage: string,
  ): Promise<void> {
    await this.apiService.post(
      ApiRoutes.waitlistApplicantDocument.deleteRequestAndProcess(
        waitlistApplciantId,
      ),
      { userId, requestMessage },
    );
  }

  async deleteDocRequest(
    waitlistApplciantId: number,
    userId: number,
    requestMessage: string,
  ): Promise<void> {
    await this.apiService.post(
      ApiRoutes.waitlistApplicantDocument.deleteRequest(waitlistApplciantId),
      { userId, requestMessage },
    );
  }
}
