import { action, observable } from 'mobx';
import Store from './Store';
import { UnitImage } from '../models/entities/UnitImage';
import ApiRoutes from 'routes/api/ApiRoutes';
import { PaginatedModelList } from 'models/PaginatedModelList';
import { ModelItem } from 'models/ModelItem';

export class UnitImageStore extends Store<UnitImage> {
  constructor() {
    super();
    UnitImage._store = this;
  }

  @observable unitList = new PaginatedModelList<UnitImage>(UnitImage);

  @observable unitItem = new ModelItem<UnitImage>(UnitImage);

  @action async fetchImages(unit, unitId) {
    const response = await this.apiService.get(
      ApiRoutes.units.getImages(unitId),
    );
    const existingUrls = unit?.unit_images?.items.map((item) => item.url) || [];
    const newImages = response.data.filter(
      (item) => !existingUrls.includes(item.url),
    );
    unit.updateFromJson({
      unit_images: [...unit?.unit_images?.items, ...newImages],
    });
  }

  @action
  clearStore(): void {}
}
