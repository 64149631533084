import { action, observable } from 'mobx';
import { OrganisationPropertyUrl } from 'models/entities/OrganisationPropertyUrlModel';
import { ModelList } from 'models/ModelList';
import Store from './Store';
import ApiRoutes from '../routes/api/ApiRoutes';
import { PaginatedModelList } from '../models/PaginatedModelList';
import { Organization } from '../models/entities/OrganizationModel';
import { User } from '../models/entities/UserModel';
import { ModelItem } from '../models/ModelItem';
import { Utils } from '../utils/utils';

export class OrganizationStore extends Store<Organization> {
  constructor() {
    super();
    Organization._store = this;
  }

  @observable organizationPaginatedList = new PaginatedModelList<Organization>(
    Organization,
  );

  @observable organizationMembersPaginatedList = new PaginatedModelList<User>(
    User,
  );

  @observable organizationManagersPaginatedList = new PaginatedModelList<User>(
    User,
  );

  @observable organizationAgentsPaginatedList = new PaginatedModelList<User>(
    User,
  );

  @observable organizationItem = new ModelItem<Organization>(Organization);

  @observable propertyFileDetailsGroupedByOrg = new ModelList<
    OrganisationPropertyUrl
  >(OrganisationPropertyUrl);

  @observable organisationPropertyUrls = new ModelList<OrganisationPropertyUrl>(
    OrganisationPropertyUrl,
  );

  @observable brandLogo: string;

  @observable loadingMembers = true;

  @observable selectedOrgIdForMemberFilter = undefined;

  get organizations() {
    return this.organizationPaginatedList.items;
  }

  @action
  setLoadingMembers(val: boolean) {
    this.loadingMembers = val;
  }

  @action
  setSelectedOrgId(val: number | undefined) {
    this.selectedOrgIdForMemberFilter = val;
  }

  @action
  async loadOrganizations(params?: { [key: string]: any }) {
    return this.organizationPaginatedList.load(
      ApiRoutes.organizations.list,
      params,
    );
  }

  @action
  async fetchOrganizationFromId(id: number, params?: { [key: string]: any }) {
    const organization = this.organizations.find((org) => org.id === id);
    if (organization) {
      this.organizationItem.setItem(organization);
      return organization;
    }
    await this.organizationItem.load(ApiRoutes.organizations.show(id), params);

    this.organizationPaginatedList.appendItem(this.organizationItem.item);

    return this.organizationItem.item;
  }

  @action
  async createOrganization(body: {
    [key: string]: any;
  }): Promise<Organization> {
    const response = await this.apiService.post(
      ApiRoutes.organizations.list,
      body,
    );
    const organization: Organization = Organization.fromJson(
      response.data,
    ) as Organization;
    this.organizationPaginatedList.appendItem(organization);
    this.organizationItem.setItem(organization);
    return organization;
  }

  @action
  async updateOrganization(
    organizationId: number,
    body: { [key: string]: any },
  ): Promise<Organization> {
    const organization = Organization.getOrNew(organizationId) as Organization;
    organization.setUpdating(true);
    const response = await this.apiService.patch(
      ApiRoutes.organizations.show(organization.id),
      body,
    );
    organization.updateFromJson(response.data);
    organization.setUpdating(false);
    return organization;
  }

  @action
  async getBrandLogo(organitionId: number): Promise<void> {
    const response = await this.apiService.get(
      ApiRoutes.organizations.getBrandUrl(organitionId),
    );
    this.brandLogo = response.logo;
  }

  @action
  async loadOrganizationMembers(orgId: number, params?: any) {
    this.setLoadingMembers(true);
    await this.organizationMembersPaginatedList.load(
      ApiRoutes.organizations.members.list(orgId),
      params,
    );
    this.setLoadingMembers(false);
  }

  @action
  async createOrgMember(orgId: number, data: any) {
    const response = await this.apiService.post(
      ApiRoutes.organizations.members.list(orgId),
      data,
    );
    if (this.selectedOrgIdForMemberFilter === orgId) {
      const member = User.fromJson(response.data) as User;
      this.organizationMembersPaginatedList.appendItem(member);
    }
  }

  @action
  async updateOrgMember(orgId: number, memberId: number, data: any) {
    const response = await this.apiService.put(
      ApiRoutes.organizations.members.update(orgId, memberId),
      data,
    );
    this.organizationMembersPaginatedList.items
      .find((_item) => _item.id === memberId)
      .updateFromJson(response.data);
  }

  @action
  async deleteOrgMember(orgId: number, memberId: number, isOwner = false) {
    await this.apiService.delete(
      ApiRoutes.organizations.members.update(orgId, memberId),
    );
    if (isOwner) {
      this.organizationMembersPaginatedList = new PaginatedModelList<User>(
        User,
      );
      const newOrgs = this.organizationPaginatedList.items.filter(
        (_org) => _org.id !== orgId,
      );
      this.organizationPaginatedList.setItems(newOrgs);
      this.setSelectedOrgId(newOrgs[0].id);
    } else {
      const newItems = this.organizationMembersPaginatedList.items.filter(
        (_item) => _item.id !== memberId,
      );
      this.organizationMembersPaginatedList.setItems(newItems);
    }
  }

  async generateApiKey(organisationId: number): Promise<void> {
    const response = await this.apiService.post(
      ApiRoutes.organizations.generateApiKey(organisationId),
    );

    const organisation = this.organizationPaginatedList.items.filter(
      (_item) => _item.id === organisationId,
    )[0];
    organisation.api_key = response.api_key;
  }

  async deleteApiKey(organisationId: number): Promise<void> {
    const response = await this.apiService.delete(
      ApiRoutes.organizations.deleteApiKey(organisationId),
    );

    const organisation = this.organizationPaginatedList.items.filter(
      (_item) => _item.id === organisationId,
    )[0];
    organisation.api_key = response.api_key;
  }

  @action
  async validateCoupon(organisation_id: number, coupon_code: string) {
    return this.apiService.post(ApiRoutes.coupons.validate, {
      organisation_id,
      coupon_code,
    });
  }

  @action
  async fetchCRSIframeUrlForOrganisation(
    organisation_id: number,
    unit_id?: number,
  ) {
    let url = ApiRoutes.crs.authenticationIframeUrl(organisation_id);
    if (unit_id) {
      url += `?unit_id=${unit_id}`;
    }
    const response = await this.apiService.get(url);

    if (response.url) {
      return response.url;
    }

    return '';
  }

  @action
  async checkIfCRSReportExists(
    organisation_id: number,
    unit_id: number,
    user_id: number,
  ) {
    const response: {
      report_exists: boolean;
    } = await this.apiService.get(
      ApiRoutes.crs.checkIfReportExists(organisation_id, unit_id, user_id),
    );

    return response.report_exists;
  }

  @action
  async checkIfReportIsShared(organisation_id: number, unit_id: number) {
    const response: { shared: boolean } = await this.apiService.get(
      ApiRoutes.crs.checkShareStatus(organisation_id, unit_id),
    );

    return response.shared;
  }

  @action
  async checkIfReportIsBeingUpdated() {
    const response: {
      refreshing_report: boolean;
    } = await this.apiService.get(ApiRoutes.crs.checkRefreshStatus);

    return response.refreshing_report;
  }

  @action
  async fetchViewCRSIframeUrlForOrganisation(
    organisation_id: number,
    unit_id: number,
    user_id: number,
  ) {
    const response = await this.apiService.get(
      ApiRoutes.crs.viewReportIframeUrl(organisation_id, unit_id, user_id),
    );

    if (response.url) {
      return response.url;
    }

    return '';
  }

  @action
  async uploadPropertyCSV(file: any) {
    const newFileName = Utils.getSanitizedFileName(file.name); // File name without special character's
    const newFile = new File([file], newFileName, { type: file.type });
    const { url } = await this.apiService.get(
      ApiRoutes.organizations.getPropertySignedUrl,
    );

    if (url) {
      await this.apiService.put(
        url,
        newFile,
        false,
        false,
        {
          'Content-Type': file.type,
          'Content-Disposition': `filename=${newFile.name}`,
        },
        '',
        '',
      );
      return url.split('?')[0];
    }

    return '';
  }

  @action
  async appendPropertyUrl(url: string): Promise<boolean> {
    const response = await this.apiService.put(
      ApiRoutes.organizations.appendPropertyUrl,
      { url },
    );

    if (response.success) {
      return response.success;
    }

    return false;
  }

  @action
  async fetchPropertyUrls() {
    const response = await this.apiService.get(
      ApiRoutes.organizations.getPendingPropertyFilesGroupedByOrg,
    );
    if (response?.data) {
      this.propertyFileDetailsGroupedByOrg.setItems(response?.data);
    }
  }

  @action
  async fetchPropertyUrlsByOrgId(
    orgId: number,
  ): Promise<OrganisationPropertyUrl[]> {
    const propertyUrls = this.organisationPropertyUrls.items.filter(
      (item) => item.organisation_id === orgId,
    );
    if (!propertyUrls.length) {
      const response = await this.apiService.get(
        ApiRoutes.organizations.getPropertyUrlsByOrgId(orgId),
      );
      if (response?.data) {
        this.organisationPropertyUrls.appendItems(response?.data);
      }
      return response.data;
    }
    return propertyUrls;
  }

  @action async rejectOrganisationPropertyUrl(
    organisationPropertyUrlId: number,
  ): Promise<OrganisationPropertyUrl> {
    const response = await this.apiService.post(
      ApiRoutes.organizations.rejectOrganisationPropertyUrl(
        organisationPropertyUrlId,
      ),
    );
    if (response.data) {
      return response?.data;
    }
    return null;
  }

  @action
  clearStore(): void {
    this.organizationPaginatedList = new PaginatedModelList<Organization>(
      Organization,
    );
    this.organizationItem = new ModelItem<Organization>(Organization);
    this.brandLogo = undefined;
    this.organizationMembersPaginatedList = new PaginatedModelList<User>(User);
    this.organizationManagersPaginatedList = new PaginatedModelList<User>(User);
    this.organizationAgentsPaginatedList = new PaginatedModelList<User>(User);
  }
}
