export enum ApplicationInsuranceStatus {
  NONE = 'none',
  PENDING = 'pending',
  ACTIVE = 'active',
  IN_ACTIVE = 'inactive',
  EXPIRED = 'expired',
  UNKNOWN = 'unknown',
  SCHEDULED = 'scheduled',
  IN_FORCE = 'in_force',
  ON_HOLD = 'on_hold',
  REQUEST = 'request',
}
