import { AUTH_STORE } from 'constants/stores';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { AuthStore } from 'stores/AuthStore';
import { useIntercom } from 'react-use-intercom';

interface Props {
  [AUTH_STORE]?: AuthStore;
}

const Intercom: React.FC<Props> = (props: Props) => {
  const { authStore } = props;

  const { update, shutdown, boot } = useIntercom();

  const { loggedInUser } = authStore;

  const userName = useCallback(() => {
    return loggedInUser?.fullname || 'Anonymous';
  }, [loggedInUser]);

  const userEmail = useCallback(() => {
    return loggedInUser?.email || 'Anonymous';
  }, [loggedInUser]);

  useEffect(() => {
    if (!loggedInUser) {
      shutdown();
      boot();
    }

    if (loggedInUser) {
      update({
        name: userName(),
        email: userEmail(),
      });
    }
  }, [loggedInUser, shutdown, boot, update, userEmail, userName]);

  return null;
};

export default React.memo(inject(AUTH_STORE)(observer(Intercom)));
