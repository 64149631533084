import { action } from 'mobx';
import Store from './Store';
import { GuestCardChat } from '../models/entities/GuestCardChatModel';

export class GuestCardChatStore extends Store<GuestCardChat> {
  constructor() {
    super();
    GuestCardChat._store = this;
  }

  @action
  clearStore(): void {}
}
