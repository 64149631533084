import React from 'react';

export const LazyLoadedLoginPage = React.lazy(() => import('./auth/LoginPage'));
export const LazyLoadedSignupPage = React.lazy(() =>
  import('./auth/RegisterPage'),
);
export const LazyLoadedForgotPasswordPage = React.lazy(() =>
  import('./auth/ForgotPasswordPage'),
);
export const LazyLoadedResetPasswordPage = React.lazy(() =>
  import('./auth/ResetPasswordPage'),
);
export const LazyLoadedInvitationSignupPage = React.lazy(() =>
  import('./auth/PHAInvitationSignupPage'),
);
export const LazyLoadedApplicationsPage = React.lazy(() =>
  import('./app/application/ApplicationsPage'),
);
export const LazyLoadedBuildingsPage = React.lazy(() =>
  import('./app/building/BuildingsPageNew'),
);
//Remove later
// export const LazyLoadedOrganizationsPage = React.lazy(() =>
//   import('./app/organization/OrganizationsPageNew'),
// );
// export const LazyLoadedPHAsPage = React.lazy(() => import('./app/phas/index'));
// export const LazyLoadedPHAInvitationsPage = React.lazy(() =>
//   import('./app/PHAInvitations/index'),
// );
// export const LazyLoadedPHAProgrammes = React.lazy(() =>
//   import('./app/PHAProgrammes/index'),
// );
// export const LazyLoadedAgentsListPage = React.lazy(() =>
//   import('./app/organization/members/OrganizationMembersListPage'),
// );
// export const LazyLoadedPhaMemberListPage = React.lazy(() =>
//   import('./app/phas/PhaMembersListPage'),
// );
export const LazyLoadedOrganizationsDataPage = React.lazy(() =>
  import('./app/organization/OrganizationsDataPage'),
);
export const LazyLoadedPHADataPage = React.lazy(() =>
  import('./app/phas/PHADataPage'),
);
export const LazyLoadedPHADetailPage = React.lazy(() =>
  import('./app/phas/PHADetailPage'),
);
export const LazyLoadedEditPHADetailPage = React.lazy(() =>
  import('./app/phas/EditPHADetailPage'),
);
export const LazyLoadedPHACallDetailsPage = React.lazy(() =>
  import('./app/phas/PHACallDetailPage'),
);
export const LazyLoadedViewOrganizationPage = React.lazy(() =>
  import('./app/organization/ViewOrganizationPages'),
);
export const LazyLoadedAddOrganizationPage = React.lazy(() =>
  import('./app/organization/AddOrganizationPage'),
);
export const LazyLoadedSettingPage = React.lazy(() => import('./app/Settings'));
export const LazyLoadedSuggestionsPage = React.lazy(() =>
  import('./app/Suggestions'),
);
export const LazyLoadedSuggestionsBankStatementPage = React.lazy(() =>
  import('./app/support/bank-statement/BankStatementManualEntry'),
);
export const LazyLoadedViewSuggestionsPage = React.lazy(() =>
  import('../components/Dashboard/suggestions/UnitDetail'),
);
export const LazyLoadedNewApplicationsPage = React.lazy(() =>
  import('./app/new-applications/index'),
);
export const LazyLoadedOsmosUploaderPage = React.lazy(() =>
  import('./app/OsmosUploader/index'),
);

export const LazyLoadedUnitsPage = React.lazy(() => import('./app/UnitsPage'));
export const LazyLoadedguestCard = React.lazy(() =>
  import('./app/GuestCardsPage'),
);
export const LazyLoadedReferralsPage = React.lazy(() =>
  import('./app/Referrals/ReferralsPage'),
);
export const LazyLoadedSupportPage = React.lazy(() =>
  import('./app/support/SupportPage'),
);
export const LazyLoadedPropertyPreview = React.lazy(() =>
  import('./preview/PropertyPreview/PropertyPreview'),
);
export const LazyLoadedGuestApply = React.lazy(() =>
  import('./preview/GuestApply/GuestApply'),
);
export const LazyLoadedInsuredApplications = React.lazy(() =>
  import('./app/insuranced-applications/InsurancedApplicationsListPage'),
);
export const LazyLoadedConfigurationPage = React.lazy(() =>
  import('./app/organization/configuration/ConfigurationPage'),
);

export const LazyLoadedSystemLogsPage = React.lazy(() =>
  import('./app/system/index'),
);
// RENTER ROUTES
export const LazyLoadedUploadPhaVoucherPage = React.lazy(() =>
  import('./renter/UploadPhaVoucherPage'),
);
export const LazyLoadedUploadGovtIdPage = React.lazy(() =>
  import('./renter/UploadGovtIdPage'),
);
export const LazyLoadedUploadCoApplicantsPage = React.lazy(() =>
  import('./renter/UploadCoApplicantsPage'),
);
export const LazyLoadedPaymentDetailsPage = React.lazy(() =>
  import('./renter/PaymentDetailsPage'),
);
export const LazyLoadedUploadCRSPage = React.lazy(() =>
  import('./renter/crs/UploadCRSPage'),
);
export const LazyLoadedUploadCRSPageNew = React.lazy(() =>
  import('./renter/crs/UploadCRSPageNew'),
);
export const LazyLoadedUploadBankStatementPage = React.lazy(() =>
  import('./renter/bank-statements/index'),
);
export const LazyLoadedUploadPayStubPage = React.lazy(() =>
  import('./renter/payrolls'),
);
export const LazyLoadedUploadTaxReturnPage = React.lazy(() =>
  import('./renter/UploadTaxReturnPage'),
);
export const LazyLoadedUploadResidenceHistoryPage = React.lazy(() =>
  import('./renter/UploadResidenceHistoryPage'),
);
export const LazyLoadedTermAndConditionPage = React.lazy(() =>
  import('./renter/TermAndConditionPage'),
);
export const LazyLoadedSubmissionPage = React.lazy(() =>
  import('./renter/SubmissionPage'),
);

export const LazyLoadedYourInformationPage = React.lazy(() =>
  import('./renter/YourInformationPage'),
);

export const LazyLoadedConfirmDetailsPage = React.lazy(() =>
  import('./confirm-details/ConfirmDetailsPage'),
);

export const LazyLoadedInsurancePage = React.lazy(() =>
  import('./insurance/insurance-flow/InsurancePage'),
);

export const LazyLoadedSignLeasePage = React.lazy(() =>
  import('./insurance/SignLeasePage/SignLeasePage'),
);

export const LazyLoadedPendingPayrollsPage = React.lazy(() =>
  import('./app/support/shared/PayrollsDocumentsList'),
);

export const LazyLoadedPendingBankStatementsPage = React.lazy(() =>
  import('./app/support/bank-statement/BankStatementsDocumentsList'),
);

export const LazyLoadedPendingPropertiesPage = React.lazy(() =>
  import('./app/support/property/PendingPropertiesList'),
);

// User Routes
export const LazyLoadedProfilePage = React.lazy(() =>
  import('../pages/app/Profile/UserProfilePage'),
);
export const LazyLoadedProfileEditPage = React.lazy(() =>
  import('./app/Profile/EditUserProfilePage'),
);

export const LazyLoadedGetInsurencePage = React.lazy(() =>
  import('./insurance/assurant-flow/pages/AssurantInsurancePage'),
);

// Waiting List Routes
export const LazyLoadedTenantMonitoringPage = React.lazy(() =>
  import('./app/waiting-list/TenantMonitoringPage'),
);
// Apply
export const LazyLoadedWaitingListApplyPreview = React.lazy(() =>
  import('./preview/WaitingListApplyPreview/WaitingListApplyPreview'),
);
export const LazyLoadedWaitingListPersonalInformationPage = React.lazy(() =>
  import('./app/waiting-list/apply/PersonalInformationPage'),
);
export const LazyLoadedWaitingListGovtIdentificationPage = React.lazy(() =>
  import('./app/waiting-list/apply/GovtIdentificationPage'),
);
export const LazyLoadedWaitingListBankStatementPage = React.lazy(() =>
  import('./app/waiting-list/apply/UploadBankStatementPage'),
);
export const LazyLoadedWaitingListPaystubPage = React.lazy(() =>
  import('./app/waiting-list/apply/UploadPaystubPage'),
);
export const LazyLoadedTermsAndConditionPage = React.lazy(() =>
  import('./app/waiting-list/apply/TermsAndCondition'),
);
export const LazyLoadedUploadAdditionalDocumentsPage = React.lazy(() =>
  import('./app/waiting-list/apply/UploadAdditionalDocument'),
);

export const LazyLoadedWaitListApplicantsPage = React.lazy(() =>
  import('./app/waiting-list/WaitlistApplicantsListPage'),
);

export const LazyLoadedWaitlistApplicantInvitesListPage = React.lazy(() =>
  import('./app/waiting-list/invites/WaitlistApplicantInvitesListPage'),
);
export const LazyLoadedWaitlistDataPage = React.lazy(() =>
  import('./app/waiting-list/WaitlistDataPage'),
);
