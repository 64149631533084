import { ApplicationStep } from 'models/ApplicationStep';
import { Application } from 'models/entities/ApplicationModel';
import ApiRoutes from 'routes/api/ApiRoutes';
import WebRoutes from '../routes/web/WebRoutes';
import { ApplicationStatusType } from './constants';

export const ApplicationStepUrlMapping = {
  [ApplicationStep.LANDING]: WebRoutes.app.upload.onboarding,
  [ApplicationStep.TERMS_AND_CONDITIONS]: WebRoutes.app.upload.terms,
  [ApplicationStep.PHA_VOUCHER]: WebRoutes.app.upload.phaVoucher,
  [ApplicationStep.GOVERNMENT_IDENTIFICATION]: WebRoutes.app.upload.govtId,
  [ApplicationStep.YOUR_INFORMATION]: WebRoutes.app.upload.yourInformation,
  [ApplicationStep.CO_APPLICANTS_AND_GUARANTOR]:
    WebRoutes.app.upload.coapplicants,
  [ApplicationStep.PAYMENT_DETAILS]: WebRoutes.app.upload.paymentDetails,
  [ApplicationStep.CREDIT_REPORTING_SERVICE]: WebRoutes.app.upload.crs,
  [ApplicationStep.BANK_STATEMENTS]: WebRoutes.app.upload.bankStatements,
  [ApplicationStep.EMPLOYMENT_INFORMATION]:
    WebRoutes.app.upload.employmentInformation,
  [ApplicationStep.TAX_DOCUMENTS]: WebRoutes.app.upload.taxReturns,
  [ApplicationStep.RESIDENCE_HISTORY]: WebRoutes.app.upload.residenceHistory,
  [ApplicationStep.SUBMISSION]: WebRoutes.app.upload.submission,
  [ApplicationStep.CONFIRM_DETAILS]: WebRoutes.documents.confirmDetails,
  [ApplicationStep.INSURANCE_PLAN]: WebRoutes.documents.insurance,
  [ApplicationStep.LEASE_PENDING]: WebRoutes.documents.signLease,
};

export const getNextApplicationStepUrl = (
  step: ApplicationStep,
  application: Application,
) => {
  if (step === ApplicationStep.CONFIRM_DETAILS) {
    if (application.status !== ApplicationStatusType.ACCEPTED) {
      return `/app${ApiRoutes.applications.show(application.id)}`;
    }
  }

  if (
    step === ApplicationStep.LEASE_PENDING ||
    step === ApplicationStep.APPLICATION_COMPLETED
  ) {
    return `/app${ApiRoutes.applications.show(application.id)}`;
  }

  return ApplicationStepUrlMapping[step]
    .replace(':unitId', application.unit_id)
    .replace(':appId', application.id);
};

export const getApplicationStepByPath = (
  path: string,
  application: Application,
): ApplicationStep => {
  const applicationSteps = Object.keys(ApplicationStepUrlMapping);

  for (const step of applicationSteps) {
    if (
      ApplicationStepUrlMapping[step]
        .replace(':unitId', application.unit_id)
        .replace(':appId', application.id) === path
    ) {
      return step as ApplicationStep;
    }
  }
  return ApplicationStep.TERMS_AND_CONDITIONS;
};
