import { Route } from 'react-router';
import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  ENV_MATRIX_ORG_ID,
  ENV_MATRIX_TEAM_ORG_ID,
  ENV_MCENEARNEY_ORG_ID,
} from 'utils/secrets';
import { AUTH_STORE } from '../constants/stores';
import NotAuthorized from '../components/NotAuthorized/NotAuthorized';

const AdminOrManagerOrSupportProtectedRoute: React.FC<any> = ({
  component: Component,
  ...rest
}) => {
  if (
    (!rest.authStore.loggedInUser.isAdminOrManagerOrSupport &&
      !(
        rest.authStore?.loggedInUserOrg?.id === +ENV_MCENEARNEY_ORG_ID &&
        rest.authStore.loggedInUser.isAgent
      )) ||
    (!rest.authStore?.loggedInUser.isAdmin &&
      ![+ENV_MATRIX_ORG_ID, +ENV_MATRIX_TEAM_ORG_ID].includes(
        rest.authStore?.loggedInUserOrg?.id,
      ) &&
      !rest.authStore?.isLoadingLoggedInUserOrg &&
      !rest.authStore?.loggedInUserOrg?.support_tab_enabled)
  ) {
    return <Route {...rest} render={(props) => <NotAuthorized />} />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default inject(AUTH_STORE)(
  observer(AdminOrManagerOrSupportProtectedRoute),
);
