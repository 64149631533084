import { action, observable } from 'mobx';
import Store from './Store';
import { PayrollDetail } from 'models/entities/PayrollDetail';

export class PayrollDetailStore extends Store<PayrollDetail> {
  constructor() {
    super();
    PayrollDetail._store = this;
  }

  @action
  clearStore(): void {}
}
