import { Route } from 'react-router';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { AUTH_STORE } from '../constants/stores';
import NotAuthorized from '../components/NotAuthorized/NotAuthorized';

const SupportProtectedRoute: React.FC<any> = ({
  component: Component,
  authStore,
  inverse,
  ...rest
}) => {
  let isSupport =
    !authStore.isLoadingLoggedInUser && authStore.loggedInUser.isSupport;
  if (inverse) {
    isSupport = !isSupport;
  }
  if (!isSupport) {
    return <Route {...rest} render={(props) => <NotAuthorized />} />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default inject(AUTH_STORE)(observer(SupportProtectedRoute));
