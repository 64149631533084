/* eslint-disable @typescript-eslint/ban-types */
import { action, computed, observable } from 'mobx';
import { isEqual } from 'lodash';
import { MatrixError } from './MatrixError';
import { Model } from './Model';
import { ApiService } from '../services/ApiService';

export abstract class ModelContainer<T extends Model, F = {}> {
  @observable error: MatrixError;

  @action setError = (e: MatrixError): void => {
    this.error = e;
  };

  @observable loading = false;

  @action setLoading = (loading: boolean): void => {
    this.loading = loading;
  };

  @observable loaded = false;

  @action setLoaded = (loaded: boolean): void => {
    this.loaded = loaded;
  };

  protected url: string;

  protected params: { [key: string]: any };

  protected requestId: string;

  constructor(
    protected modelClass: typeof Model,
    protected apiService = ApiService.getInstance(),
  ) {}

  @computed
  get hasError(): boolean {
    return !!this.error;
  }

  @action
  async load(
    url: string,
    params?: { [key: string]: any },
    config?: { dataKey?: string; forceRefresh?: boolean },
    dataTransformer?: (data: any) => {},
  ): Promise<void> {
    const dataKey = config?.dataKey === undefined ? 'data' : config.dataKey;
    const forceRefresh = config && config.forceRefresh;

    const isSameUrl = this.url === url;
    const areSameParams =
      (!this.params && !params) || isEqual(this.params, params);
    const isSameRequest = isSameUrl && areSameParams;

    if (isSameRequest && this.loaded && !forceRefresh) {
      return;
    }

    if (this.loading) {
      if (isSameRequest) {
        return;
      }
      this.apiService.cancelRequest(this.requestId);
    }

    this.setError(null);
    this.setLoaded(false);
    this.setLoading(true);
    this.url = url;
    this.params = params;
    this.requestId = this.apiService.generateRequsetId();

    try {
      const response = await this.apiService.get(
        url,
        params,
        null,
        this.requestId,
      );
      let data = dataKey ? response[dataKey] : response;
      if (dataTransformer) {
        data = dataTransformer(data);
      }
      this.onSuccess(data);
    } catch (e) {
      this.onError(e as MatrixError);
    }
  }

  abstract deserialize(data: { [key: string]: any }): void;

  @action
  protected onSuccess(response: { [key: string]: any }): void {
    this.setLoaded(true);
    this.setLoading(false);
    this.deserialize(response);
  }

  @action
  protected onError(error: MatrixError): void {
    this.setLoaded(true);
    this.setLoading(false);
    this.setError(error);
    throw error;
  }
}
