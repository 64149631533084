import { observable } from 'mobx';
import Model from 'models/Model';
import { PayrollDetailStore } from 'stores/PayrollDetails';

export class PayrollDetail extends Model {
  static _store: PayrollDetailStore;

  @observable id: number;

  @observable payroll_id: number;

  @observable is_verified: boolean;

  @observable employee_first_name: string;

  @observable employee_last_name: string;

  @observable employer_name: string;

  @observable employer_address_line_1: string;

  @observable employer_address_line_2: string;

  @observable employer_address_city: string;

  @observable employer_address_state_code: string;

  @observable employer_address_postal_code: string;

  @observable job_title: string;

  @observable job_start_date: string;

  @observable job_end_date: string;

  @observable net_pay: number;

  @observable pay_date: string;

  // TODO: ADD TYPE FOR PAY_STATEMENTS
  @observable pay_statements: any;

  @observable social_security_tax: number;

  @observable state_tax: number;

  @observable medicare_tax: number;

  @observable local_tax: number;

  @observable federal_tax: number;

  @observable gross: number;

  @observable ytd_gross: number;

  @observable benefit_insurance: number;
}
