import { action, observable } from 'mobx';
import ApiRoutes from '../routes/api/ApiRoutes';
import { PaginatedModelList } from '../models/PaginatedModelList';
import Store from './Store';
import { State } from '../models/entities/StateModel';
import { ModelItem } from '../models/ModelItem';

export class StateStore extends Store<State> {
  constructor() {
    super();
    State._store = this;
  }

  @observable statesPaginatedList = new PaginatedModelList<State>(State);

  @observable stateItem = new ModelItem<State>(State);

  @action
  async fetchStates(params?: { [key: string]: any }) {
    return this.statesPaginatedList.load(ApiRoutes.states.list, params);
  }

  @action
  async fetchStateById(stateId: number, appendIntoStore = false) {
    const promise = this.stateItem.load(ApiRoutes.states.show(stateId));
    if (appendIntoStore) {
      promise.then(() => {
        const alreadyPresent = this.statesPaginatedList.items.find(
          (item) => item.id === this.stateItem.item?.id,
        );
        if (!alreadyPresent) {
          this.statesPaginatedList.appendItem(this.stateItem.item);
        }
      });
    }
    return promise;
  }

  @action
  clearStore(): void {
    this.statesPaginatedList = new PaginatedModelList<State>(State);
  }
}
