import React from 'react';
import cx from 'classnames';
import { Tooltip } from 'antd';

import './MTButton.scss';

// TODO Maybe needs to add size of a button whether "small/large/default" : VS

interface Props {
  onClick?: (e: any) => void;
  onBlur?: () => void;
  form?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  className?: string;
  variant?:
    | 'login'
    | 'primary'
    | 'secondary'
    | 'accept'
    | 'reject'
    | 'edit'
    | 'misc'
    | 'unit'
    | 'unit-secondary';
  uppercaseText?: boolean;
  tooltipText?: string;
  style?: any;
  ignoreTooltip?: boolean;
}

const MTButton: React.FC<Props> = ({
  variant = 'primary',
  className,
  children,
  uppercaseText = true,
  ignoreTooltip = false,
  tooltipText,
  ...props
}) => {
  if (ignoreTooltip) {
    return (
      <div className="mt-button">
        <button
          {...props}
          title={tooltipText}
          className={cx(
            className,
            'btn leading-5 text-sm ssm:text-base text-white font-black h-auto text-center border-solid border-2 cursor-pointer focus:outline-none md:py-2 lg:py-3 py-2 px-5 md:px-6 lg:px-7',
            variant,
            { uppercase: uppercaseText },
          )}>
          {children}
        </button>
      </div>
    );
  }
  return (
    <div className="mt-button">
      <Tooltip placement="top" title={tooltipText}>
        <button
          {...props}
          title={tooltipText}
          className={cx(
            className,
            'btn leading-5 text-sm ssm:text-base text-white font-black h-auto text-center border-solid border-2 cursor-pointer focus:outline-none md:py-2 lg:py-3 py-2 px-5 md:px-6 lg:px-7',
            variant,
            { uppercase: uppercaseText },
          )}>
          {children}
        </button>
      </Tooltip>
    </div>
  );
};

export default MTButton;
