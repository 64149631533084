import { observable } from 'mobx';
import Model from '../Model';
import { UnitStore } from '../../stores/UnitStore';
import { Building } from './BuildingModel';
import { User } from './UserModel';
import { ModelItem } from '../ModelItem';
import { ModelList } from '../ModelList';
import { UnitImage } from './UnitImage';
import moment from 'moment';

export class Unit extends Model {
  static _store: UnitStore;

  @observable id: number;

  @observable organisation_id: number;

  @observable building_id: number;

  @observable status_id: number;

  @observable name: string;

  @observable address_line_1: string;

  @observable address_line_2: string;

  @observable application_fee: number;

  @observable state: number;

  @observable state_name: string;

  @observable city: string;

  @observable city_name: string;

  @observable zip_code: number;

  @observable created_at: string;

  @observable updated_at: string;

  @observable image_url: string;

  @observable unit_images = new ModelList<UnitImage>(UnitImage);

  // TODO: Fix this later.
  @observable renter_name: string;

  @observable renter_email: string;

  @observable status: 'available' | 'unavailable';

  @observable unit_full_address: string;

  @observable building = new ModelItem<Building>(Building);

  @observable appliedBy: User[];

  @observable monthly_rent: number;

  @observable security_deposit: number;

  @observable cats_allowed: boolean;

  @observable dogs_allowed: boolean;

  @observable bathrooms: number;

  @observable bedrooms: number;

  @observable marketing_description: string;

  @observable accepted_app_id: number;

  @observable last_app_accepted_at: Date;

  @observable area: number;

  @observable latitude: number;

  @observable longitude: number;

  @observable year_built: number;

  get canAcceptAppForUnit() {
    if (this.last_app_accepted_at && this.accepted_app_id) {
      const _last_app_accepted_at = moment
        .utc(this.last_app_accepted_at)
        .startOf('date');
      const _now = moment.utc().startOf('date');
      const _30DaysBefore = _now.clone().subtract(30, 'days');
      return (
        !this.accepted_app_id && _last_app_accepted_at.isBefore(_30DaysBefore)
      );
    }
    return true;
  }

  get fullAddress() {
    return this.address_line_1 + this.address_line_2;
  }
}
