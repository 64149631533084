export enum DocumentType {
  GOVT_ID = 1,
  BANK_STATEMENT = 2,
  PAY_STUB = 3,
  TAX_RETURN_W2 = 4,
  TAX_RETURN_W4 = 5,
  TAX_RETURN_1040 = 6,
  TAX_RETURN_1099 = 7,
  TAX_RETURN_OTHER = 8,
  CREDIT_REPORT = 9,
  EVICTION_REPORT = 10,
  CRIMINAL_REPORT = 11,
  ADDITIONAL_DOCUMENT = 12,
  UNIT_IMAGE,
  BUILDING_IMAGE,
  PHA_VOUCHER,
  INSPECTION_REPORT,
}

export class DocumentTypeUtils {
  static getDocumentType = (v: DocumentType) => {
    switch (v) {
      case DocumentType.GOVT_ID: {
        return 'doc_government_id';
      }
      case DocumentType.BANK_STATEMENT: {
        return 'doc_bank_statements';
      }
      case DocumentType.PAY_STUB: {
        return 'doc_paystubs';
      }
      case DocumentType.TAX_RETURN_W2: {
        return 'doc_tax_return_w2';
      }
      case DocumentType.TAX_RETURN_W4: {
        return 'doc_tax_return_w4';
      }
      case DocumentType.TAX_RETURN_1040: {
        return 'doc_tax_return_1040';
      }
      case DocumentType.TAX_RETURN_1099: {
        return 'doc_tax_return_1099';
      }
      case DocumentType.TAX_RETURN_OTHER: {
        return 'doc_tax_return_other';
      }
      case DocumentType.CREDIT_REPORT: {
        return 'doc_credit_report';
      }
      case DocumentType.EVICTION_REPORT: {
        return 'doc_eviction_report';
      }
      case DocumentType.CRIMINAL_REPORT: {
        return 'doc_criminal_report';
      }
      case DocumentType.UNIT_IMAGE: {
        return 'unit_image';
      }
      case DocumentType.BUILDING_IMAGE: {
        return 'building_image';
      }
      case DocumentType.PHA_VOUCHER: {
        return 'pha_voucher';
      }
      case DocumentType.INSPECTION_REPORT: {
        return 'inspection_report';
      }
      case DocumentType.ADDITIONAL_DOCUMENT: {
        return 'doc_additional_document';
      }
    }
    return '';
  };
}
