import { action } from 'mobx';
import Store from './Store';
import { RentDeterminationChat } from '../models/entities/RentDeterninationChatModel';

export class RentDeterminationChatStore extends Store<RentDeterminationChat> {
  constructor() {
    super();
    RentDeterminationChat._store = this;
  }

  @action
  clearStore(): void {}
}
