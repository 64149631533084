import { AuthContext } from 'context/AuthContext';
import React, { useContext, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

type LogoutComponentProps = RouteComponentProps;

export const LogoutComponent: React.FC<LogoutComponentProps> = (props) => {
  const context = useContext(AuthContext);

  useEffect(() => {
    context.onLogout(true);
  }, []);

  return null;
};
