import { observable } from 'mobx';
import Model from '../Model';
import { BankTransactionStore } from 'stores/BankTransactionStore';

export class BankTransaction extends Model {
  static _store: BankTransactionStore;

  @observable id: number;

  @observable amount: number;

  @observable bank_account_id: number;

  @observable category: string;

  @observable connection_id: string;

  @observable createdAt: string;

  @observable date: string;

  @observable description: string;

  @observable document_id: number;

  @observable is_bill_pay: boolean;

  @observable is_direct_deposit: boolean;

  @observable is_expenses: boolean;

  @observable is_fee: boolean;

  @observable is_income: boolean;

  @observable is_overdraft_fee: boolean;

  @observable is_payroll_advance: boolean;

  @observable is_subscription: boolean;

  @observable top_level_category: string;

  @observable type: string;
}
