export enum QueuedDocumentUploadStatus {
  NOOP = 'noop',
  PROCESSING = 'processing',
  PROCESSED = 'processed',
  FAILED = 'failed',
  REQUESTED = 're-requested',
}

export interface QueuedDoc {
  id: number;
  appfolio_account_id: number;
  status: QueuedDocumentUploadStatus;
  meta: {
    file_name: string;
    report_url: string;
  };
}
