import { AxiosError } from 'axios';

export enum ErrorCode {
  UNIDENTIFIED,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  UNPROCESSABLE_ENTITY = 422,
  TOO_MANY_REQUESTS = 429,
}

export enum UploadErrorCode {
  GOVERNMENT_ID_MISSING = 1202,
  TAX_DOCS_MISSING = 1203,
  PAYSTUBS_MISSING = 1204,
  BANK_STATEMENTS_MISSING = 1205,
  RESIDENCE_HISTORY_MISSING = 1206,
  DOCUMENTS_ARE_MISSING = 1201,
  APPLICATION_PAYMENT_DETAILS_MISSING = 1207,
  APPLICATION_DOES_NOT_EXIST = 4001,
  DOB_INVALID = 203,
  CRS_MISSING = 1208,
  INCOMPLETE_USER_DATA = 1220,
  PHA_INVITE_ALREADY_ACCEPTED = 13002,
}

export class MatrixError {
  constructor(
    readonly message: string = 'Error. Please Try Again.',
    readonly code?: UploadErrorCode,
    readonly status: ErrorCode = ErrorCode.UNIDENTIFIED,
    readonly errors = {},
    readonly data = {},
  ) {}

  static from(axiosError: AxiosError): MatrixError {
    if (!axiosError.response) {
      return new MatrixError();
    }

    const { status, data } = axiosError.response;
    if (status === ErrorCode.UNPROCESSABLE_ENTITY) {
      return new MatrixError(
        data?.message,
        data?.code,
        status,
        data?.errors,
        data?.meta,
      );
    }
    return new MatrixError(
      data?.message,
      data?.code,
      status,
      data?.errors || null,
      data?.meta,
    );
  }

  errorsByKey(key: string) {
    return this.errors?.[key];
  }

  hasErrorByKey(key: string) {
    return !!this.errorsByKey(key);
  }
}
