import moment from 'moment';
import { User } from '../models/entities/UserModel';

class AnalyticsService {
  static getInstance(): AnalyticsService {
    return new AnalyticsService();
  }

  static getCurrentDate(): string {
    return moment().utc().toString();
  }

  static getTimeStamp(): number {
    return moment().utc().unix();
  }

  identify(user: User, meta = {}) {
    window.analytics.identify(user.id, {
      ...meta,
      name: user.fullname,
      email: user.email,
    });
  }

  track(eventName: string, meta = {}) {
    window.analytics.track(eventName, {
      ...meta,
      event_timestamp: AnalyticsService.getCurrentDate(),
      epoch: AnalyticsService.getTimeStamp(),
    });
  }
}

export const analyticsService = AnalyticsService.getInstance();
