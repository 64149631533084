import Model from 'models/Model';
import { PHAStore } from 'stores/PHAStore';

export class PHAProgramme extends Model {
  static _store: PHAStore;

  id: number;

  code: string;

  name: string;
}
