import { action, observable } from 'mobx';
import { getCapitalizeString, removeObjectEmptyValues } from 'utils/helpers';
import Store from './Store';
import { PaginatedModelList } from '../models/PaginatedModelList';
import ApiRoutes from '../routes/api/ApiRoutes';
import { Invite } from '../models/entities/InviteModel';
import { InviteType } from '../enums/InviteType';
import { notification } from 'antd';

export class InviteStore extends Store<Invite> {
  constructor() {
    super();
    Invite._store = this;
  }

  @observable invitePaginatedList = new PaginatedModelList<Invite>(Invite);

  get invites() {
    return this.invitePaginatedList.items;
  }

  @action
  async loadInvites(params?: { [key: string]: any }) {
    return this.invitePaginatedList.load(ApiRoutes.invites.list, params);
  }

  @action
  async inviteRenter(
    unitId: number,
    name: string,
    renterEmail: string,
    inviteType?: InviteType,
    note?: string,
    applicationId?: number,
    guestCardId?: number,
  ) {
    const body = removeObjectEmptyValues({
      unit_id: unitId,
      renter_name: name,
      renter_email: renterEmail,
      note,
      invite_type: inviteType,
      application_id: applicationId,
      guest_card_id: guestCardId,
    });
    try {
      const response = await this.apiService.post(ApiRoutes.invites.list, body);

      const invite = Invite.fromJson(response.data) as Invite;
      this.invitePaginatedList.appendItem(invite);
      notification.success({
        message: 'Invite Sent',
        duration: 2,
      });

      if (response?.application?.data) {
        return { invite, application: response.application.data };
      }

      return { invite };
    } catch (e) {
      const error = getCapitalizeString(e?.errors?.renter_name?.[0]);
      notification.error({
        message: error ? `Error: ${error}` : e?.message,
        duration: 2,
      });

      return null;
    }
  }

  @action
  async invitePropertyRenter(
    unitId: number,
    name: string,
    renterEmail: string,
    inviteType?: InviteType,
    note?: string,
    guestCardId?: number,
    guestCardUrl?: string,
  ) {
    const body = removeObjectEmptyValues({
      unit_id: unitId,
      renter_name: name,
      renter_email: renterEmail,
      note,
      invite_type: inviteType,
      guest_card_id: guestCardId,
      guest_card_url: guestCardUrl,
    });

    const response = await this.apiService.post(ApiRoutes.invites.list, body);

    const invite = Invite.fromJson(response.data) as Invite;
    this.invitePaginatedList.appendItem(invite);
    return invite;
  }

  @action
  async resendInvite(invite: Invite): Promise<void> {
    const response = await this.apiService.post(
      ApiRoutes.invites.resend(invite.id),
    );
    if (response.success) {
      invite.updateFromJson({
        ...invite,
        can_resend_invite: false,
        last_attempt_at: new Date(),
      });
    }
    return response;
  }

  @action
  async deleteInvite(invite: Invite): Promise<{ application: any }> {
    try {
      const response = await this.apiService.delete(
        ApiRoutes.invites.show(invite.id),
      );
      if (response?.success) {
        this.invitePaginatedList.removeItem(invite);
        return { application: response.application.data };
      }
      return response;
    } catch (e) {
      const error = getCapitalizeString(e?.errors?.renter_name?.[0]);
      notification.error({
        message: error ? `Error: ${error}` : e?.message,
        duration: 2,
      });
      return null;
    }
  }

  @action
  clearStore(): void {
    this.invitePaginatedList = new PaginatedModelList<Invite>(Invite);
  }
}
