import { ApplicationInsuranceFrequency } from 'enums/ApplicationInsuranceFrequency.enum';
import { ApplicationInsuranceStatus } from 'enums/ApplicationInsuranceStatus';
import { ApplicationInsuranceType } from 'enums/ApplicationInsuranceType.enum';
import { observable } from 'mobx';
import Model from '../Model';
import { ApplicationInsuranceStore } from '../../stores/ApplicationInsuranceStore';

export class ApplicationInsurance extends Model {
  static _store: ApplicationInsuranceStore;

  @observable id: number;

  @observable organisation_id: number;

  @observable application_id: string;

  @observable frequency: ApplicationInsuranceFrequency;

  @observable type: ApplicationInsuranceType;

  @observable status: ApplicationInsuranceStatus;

  @observable createdAt: string;

  @observable updatedAt: string;

  @observable start_date: string;

  @observable renewal_date: string;

  @observable user_id: number;

  @observable subscription_id: string;
}
