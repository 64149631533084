export enum ApplicationSource {
  MATRIX_CONNECT = 'matrix_connect',
  MATRIX_CONNECT_FROM_PHA_INVITE = 'matrix_connect_from_pha_invite',
  MATRIX_CONNECT_SUGGESTIONS = 'matrix_connect_suggestions',
  REJECTED_APPLICATION_SUGGESTIONS = 'rejected_application_suggestions',
  FRESH_APPLICATION = 'fresh_application',
  FRESH_APPLICATION_MANUAL = 'fresh_application_manual',
  FRESH_APPLICATION_EXTERNAL = 'fresh_application_external',
  APPFOLIO_APPLICATION_INGESTION = 'appfolio_application_ingestion',
}
