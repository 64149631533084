import { observable } from 'mobx';
import Model from '../Model';
import { PaymentStore } from 'stores/PaymentStore';

export class Payment extends Model {
  static _store: PaymentStore;

  @observable id: number;

  @observable application_id: number;

  @observable user_id: number;

  @observable currency: string;

  @observable amount: string;

  @observable created_at: string;

  @observable updated_at: string;

  @observable coupon_id: number;
}
