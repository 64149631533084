import React from 'react';

export const LazyLoadedAuthContainer = React.lazy(() =>
  import('./AuthContainer/AuthContainer'),
);
export const LazyLoadedAppContainer = React.lazy(() =>
  import('./AppContainer/AppContainer'),
);
export const LazyLoadedRenterContainer = React.lazy(() =>
  import('./AppContainer/RenterContainer/RenterContainer'),
);

export const LazyLoadedInsuranceContainer = React.lazy(() =>
  import('./InsuranceContainer/InsuranceContainer'),
);

export const LazyLoadedUserContainer = React.lazy(() =>
  import('./UserContainer/UserContainer'),
);

export const LazyLoadedWaitingListContainer = React.lazy(() =>
  import('./WaitingListContainer/WaitingListContainer'),
);

export const LazyLoadedPhaApplyContainer = React.lazy(() =>
  import('./AppContainer/PHAInviteApplyContainer/PHAInviteApplyContainer'),
);
