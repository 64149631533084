import MatrixLogo from 'assets/logo.png';
import { DocumentType } from '../enums/DocumentType';
import { UnitStatus } from '../enums/UnitStatus';
import {
  ENV_ATLAS_ORG_ID,
  ENV_HALO_ORG_ID,
  ENV_HPA_ORG_ID,
  ENV_INSPECTIFY_EXPERIENCE_URL,
  ENV_MATRIX_ORG_ID,
  ENV_MCENEARNEY_GENERIC_UNIT_ID,
  ENV_MCENEARNEY_ORG_ID,
  ENV_MORGAN_ORG_ID,
  ENV_NOVA_PRODUCT_ID,
  ENV_NOVA_PUBLIC_ID,
} from '../utils/secrets';

export const TAX_RETURN_DOCS = [
  DocumentType.TAX_RETURN_W2,
  DocumentType.TAX_RETURN_W4,
  DocumentType.TAX_RETURN_1040,
  DocumentType.TAX_RETURN_1099,
  DocumentType.TAX_RETURN_OTHER,
];

export const phoneRegex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const socialSecurityNumberRegex = /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/;

export const nameRegex = /^[aA-zZ\.\s]+$/;
export const nameWithNumbersRegex = /^[a-zA-Z0-9.\s]+$/;

export const USA_BOUNDS = {
  west: -171.791110603,
  south: 18.91619,
  east: -66.96466,
  north: 71.3577635769,
};

export enum APPLICATION_STATUS {
  PENDING = 1,
  ACCEPTED = 2,
  REJECTED = 3,
  INCOMPLETE = 4,
  ANALYSING = 5,
}

export const OTHER = 'Other';

export const HOUSE_TYPE_DROPDOWN = [
  { key: 'Single Family Detached', value: 'Single Family Detached' },
  {
    key: 'Semi Detached/Row House',
    value: 'Semi Detached/Row House',
  },
  { key: 'Manufactured Home', value: 'Manufactured Home' },
  { key: 'Garden/Walkup', value: 'Garden/Walkup' },
  { key: 'Elevator/High Rise', value: 'Elevator/High Rise' },
];

export const SUBSIDY_TYPE_DROPDOWN = [
  { key: 'Section 202', value: 'Section 202' },
  { key: 'Section 221(d)(3)(BMIR)', value: 'Section 221(d)(3)(BMIR)' },
  {
    key: 'Section 236(Insured or NonInsured)',
    value: 'Section 236(Insured or NonInsured)',
  },
  {
    key: 'Section 515 (Rural development)',
    value: 'Section 515 (Rural development)',
  },
  { key: 'Home', value: 'Home' },
  { key: 'Tax Credit', value: 'Tax Credit' },
  { key: OTHER, value: OTHER },
];

export const PATRIOT_ORG = 6;
export const ATLAS_ORG = +ENV_ATLAS_ORG_ID;
export const HALO_ORG = +ENV_HALO_ORG_ID;
export const HPA_ORG = +ENV_HPA_ORG_ID;
export const MATRIX_ORG = +ENV_MATRIX_ORG_ID;
export const MCENEARNEY_ORG = +ENV_MCENEARNEY_ORG_ID;
export const MORGAN_ORG = +ENV_MORGAN_ORG_ID;
export const INSPECTIFY_SCHEDULER_URL = ENV_INSPECTIFY_EXPERIENCE_URL;
export const NOVA_PUBLIC_ID = ENV_NOVA_PUBLIC_ID;
export const NOVA_PRODUCT_ID = ENV_NOVA_PRODUCT_ID;

export const UNIT_STATUS_AVAILABLE = 'Available';

export const UNIT_STATUS = [
  { key: UnitStatus.AVAILABLE.toLowerCase(), value: UnitStatus.AVAILABLE },
  { key: UnitStatus.UNAVAILABLE.toLowerCase(), value: UnitStatus.UNAVAILABLE },
];

export const PROPERTY_VIEW_TYPE = [
  { key: 'all', value: 'All Listings' },
  { key: 'my', value: 'My Listings' },
];

export const HaloLogo =
  'https://matrix-doc-staging.s3.amazonaws.com/testing_content/halo-logo.png';

export const HaloLandingBgImage =
  'https://assets.mymatrixrent.com/halo-landing.jpeg';

export const HPALogo =
  'https://matrix-doc-staging.s3.amazonaws.com/testing_content/HPA_logo.png';

export const MCENEARNEYLogo =
  'https://mymatrix-assets.s3.amazonaws.com/logo/mcenearney.png';

export const MorganLogo =
  'https://mymatrix-assets.s3.amazonaws.com/logo/morgan.png';

export const MATRIX_CONNECT_UNIT_IMAGE =
  'https://matrix-doc-demo.s3.us-east-1.amazonaws.com/units/images/4645/IMG_6917.jpg';

export const AppLogo = MatrixLogo;

export enum ENVIRONMENT {
  DEMO = 'demo',
  DEVELOP = 'develop',
  LOCAL = 'local',
  PRODUCTION = 'production',
  STAGING = 'staging',
}

export enum ApplicationStatusType {
  INCOMPLETE = 'incomplete',
  PENDING = 'pending',
  READY = 'ready',
  MAIA_GENERATED = 'maia-generated',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  INSPECTING = 'inspecting',
  WAITING_OTHER = 'waiting_other',
  ANALYSING = 'analysing',
  LANDLORD_NOT_RESPONSIVE = 'landlord_not_responsive',
}

export enum SupportTabType {
  PENDING_PAYROLLS = 'pending_income_documents',
  BANK_STATEMENT = 'rejected_bank_statement',
  PENDING_PROPERTY = 'pending_property',
}

export enum MappingForApplicationFilters {
  incomplete = 'INCOMPLETE',
  ready = 'PENDING', // we do not want to show the Ready state to FE
  pending = 'PENDING',
  waiting_other = 'PENDING',
  accepted = 'ACCEPTED',
  rejected = 'REJECTED',
  analysing = 'ANALYSING',
}

export const PROPERTY_TYPES = {
  BUILDING: 'building',
  UNIT: 'unit',
};

export const LOCAL_STORAGE_CONSTANTS = {
  CRS_FETCHED: 'crs_fetched',
};

export enum PayCycleType {
  MONTHLY = 'monthly',
  SEMI_MONTHLY = 'semimonthly',
  BI_WEEKLY = 'biweekly',
  WEEKLY = 'weekly',
}

// MAX_FILE_SIZE_FOR_UPLOAD => 10 * 1000 * 1000 bytes = 10MB
export const MAX_FILE_SIZE_FOR_UPLOAD = 10 * 1000 * 1000;
export const MAX_FILE_SIZE_FOR_UPLOAD_IN_STRING = '10MB';

export const IMAGE_PLACEHOLDER =
  'https://mymatrix-assets.s3.amazonaws.com/matrix-logo-large.png';

export enum ConsumerService {
  MCL = 'mcl',
  CIC = 'cic',
  EXPERIAN = 'experian',
}

export enum PHASteps {
  NOOP = 'noop',
  REQUEST_FOR_TENANCY_APPROVAL = 'request_for_tenancy_approval',
  COMPS_SELECTION = 'comps_selection',
  RENT_DETERMINATION = 'rent_determination',
  SCHEDULING_INSPECTION = 'scheduling_inspection',
  CONDUCTING_INSPECTION = 'conducting_inspection',
  CONTRACT_IN_REVIEW = 'contract_in_review',
  CONTRACT_EXECUTION = 'contract_execution',
}

export const NOVA_SCRIPT_URL =
  'https://static.novacredit.com/connect/v2/init.js';

export const TELLER_SCRIPT_URL = 'https://cdn.teller.io/connect/connect.js';

export const HEADER_TITLE = {
  CO_APPS_AND_GUARANTOR: (onlyCoApps: boolean) =>
    onlyCoApps ? 'Co-applicants' : 'Co-applicants & Guarantor',
  GOVERNMENT_IDENTIFICATION: 'Upload a Government ID',
  PAYMENT_DETAILS: 'Payment Details',
  YOUR_INFORMATION: 'Housing Voucher',
  CREDIT: 'Credit Report Services',
  BANK_STATEMENT: 'Connect your Bank Account',
  EMPLOYMENT_INFORMATION: 'Confirm Your Income & Employment',
  RESIDENCE_HISTORY: 'Residence History',
  SUBMISSION: 'Submission',
  ADDITIONAL_DOCUMENTS: 'Additional Documents',
};

export const HEADER_DESCRIPTION = {
  CO_APPS_AND_GUARANTOR: (onlyCoApps: boolean) =>
    onlyCoApps
      ? 'Add Co-applicants, if any'
      : 'Add Co-applicants and Guarantor, if any',
  GOVERNMENT_IDENTIFICATION:
    'Accepted forms of Government ID include: Drivers License, State ID, Passport.',
  YOUR_INFORMATION:
    'This housing voucher is provided for the sole purpose of verifying eligibility for housing assistance  when applicable and is independent of any credit or background check determinations.',
  CREDIT: 'Connect your Credit Report in order to see your Credit Score',
  BANK_STATEMENT:
    'Use Bank Connections to connect to over 16,000 Banks, Credit Unions & Financial Institutions. No Login Information or Passwords are stored during this process',
  EMPLOYMENT_INFORMATION:
    'Use Instant Payroll Connections to connect to your employer(s). No Login Information or Passwords are stored during this process. You can also upload your (3) most recent paystubs or you can also upload your offer letter.',
  RESIDENCE_HISTORY:
    'Please tell us where you have lived for the last 2 years, starting with your current residence.',
  SUBMISSION: 'Submit the application',
  ADDITIONAL_DOCUMENTS: 'Do you have any other documents?',
};

export enum CreditStateEnum {
  POOR = 'poor',
  FAIR = 'fair',
  GOOD = 'good',
  VERY_GOOD = 'very good',
  EXCEPTIONAL = 'exceptional',
}

export const creditScoreRangeArray = [
  {
    high: 579,
    score: CreditStateEnum.POOR,
  },
  {
    high: 669,
    low: 580,
    score: CreditStateEnum.FAIR,
  },
  {
    high: 739,
    low: 670,
    score: CreditStateEnum.GOOD,
  },
  {
    high: 799,
    low: 740,
    score: CreditStateEnum.VERY_GOOD,
  },
  {
    low: 800,
    score: CreditStateEnum.EXCEPTIONAL,
  },
];

export const GENERIC_UNIT_IDS = [+ENV_MCENEARNEY_GENERIC_UNIT_ID];

export enum SystemPageTabs {
  LOGS = 'logs',
  HEALTH_CHECK = 'health_check',
}

export const SHORT_MESSAGE = `I would like to receive informational texts from Assurant regarding my policy at the cell phone number I provide below. Informational texts may include, but are not limited to: any information regarding my policy, notices, cancellation, non-renewal, claims, billing and general customer service. The frequency and type of informational texts will be determined by Assurant. I must inform Assurant if my cell phone number changes. I may choose to stop receiving informational texts anytime by replying STOP. Standard message and data rates apply.`;

export enum PHATabs {
  PHAS = 'PHAs',
  PHA_MEMBERS = 'PHA_Members',
  PHA_INVITATIONS = 'PHA_Invitations',
  PHA_PROGRAMS = 'PHA_Programs',
  PHA_SUB_PROGRAMS = 'PHA_Sub_Programs',
}

export enum OrgnizationTabs {
  ORGANIZATIONS = 'Organizations',
  ORGANIZATIONS_MEMBERS = 'Organizations_members',
}

export enum WaitlistTabs {
  WAITLIST_APPLICANTS = 'Waitlist_Applicants',
  WAITLIST_INVITES = 'Waitlist_Invites',
}

export const SIGNUP_DOCUMENTS = {
  MSA: 'https://assets.mymatrixrent.com/documents/pha/landlord_tnc.pdf',
  EXPERIAN_PRIVACY_POLICY:
    'https://assets.mymatrixrent.com/documents/experian/privacy_policy.pdf',
};
