export default {
  auth: {
    me: '/me',
    login: '/auth/login',
    register: '/auth/register',
    changePassword: '/auth/change-password',
    forgotPassword: '/auth/forgot-password',
    resetPassword: '/auth/reset-password',
  },
  me: {
    uploadBankStatements: '/me/upload/bank-statements',
    importAccountInformation: '/me/import-bank-data',
    applicationByUnit: (unitId: number) => `/me/units/${unitId}/application`,
    listDocuments: '/me/documents',
    listMyDocuments: '/me/my-documents',
    deleteDocument: '/me/delete-document',
    deletePaystub: (payrollId: number) => `/me/payroll/${payrollId}`,
    completeApplication: (id: number) =>
      `/me/applications/${id}/mark-as-complete`,
    banks: {
      list: '/me/banks',
      show: (id: number) => `/me/banks/${id}`,
    },
    listPaymentSources: '/me/stripe/payment-sources',
    uploadPayStub: '/me/upload/paystub/',
    bankStatements: (id: number): string => `/me/${id}/bank-statements`,
    payrolls: (id: number): string => `/me/${id}/payrolls`,
    chatToken: '/me/chat-token',
    agentOrg: '/me/get-agent-org',
    agentPHA: '/me/get-agent-pha',
    createPaymentSource: '/me/create-payment-source',
    getPendingConnectApplicationsCount:
      '/me/unresolved-connect-applications/count',
    updateMeta: 'me/meta',
    moveInDate: 'me/application/move-in-date',
  },
  admin: {
    users: {
      list: '/admin/users',
      show: (id: number) => `admin/users/${id}`,
    },
  },
  preSignedUrl: '/s3/pre-signed-upload-url',
  buildings: {
    list: '/buildings',
    show: (id: number) => `/buildings/${id}`,
    termsConditions: (id: number): string =>
      `/buildings/${id}/terms-and-conditions`,
    createQueuedProperty: (organisationPropertyUrlId: number): string =>
      `/buildings/create-queued-property/${organisationPropertyUrlId}`,
    transferProperty: (building_id: number): string =>
      `/buildings/transfer-property/${building_id}`,
  },
  buildingUnit: {
    addImage: (property_id: number) =>
      `/building-unit/image-upload/${property_id}`,
    deleteImage: (property_id: number) =>
      `/building-unit/image-delete/${property_id}`,
  },
  cities: {
    list: '/cities',
    show: (id: number) => `/cities/${id}`,
  },
  states: {
    list: '/states',
    show: (id: number) => `/states/${id}`,
  },
  applications: {
    batchCreate: '/applications/batch/create',
    list: '/applications',
    merge: '/applications/merge',
    show: (id: number) => `/applications/${id}`,
    generateReport: (id: number) => `/applications/${id}/generate-report`,
    triggerMAIAReport: (id: number) =>
      `/applications/${id}/trigger-maia-report`,
    shareReport: (id: number) => `/applications/${id}/share`,
    processFee: (id: number) => `/applications/${id}/process-fee`,
    processInternalInsuranceFee: (id: number) =>
      `/applications/${id}/process-internal-insurance-fee`,
    generatePaymentIntent: (id: number) =>
      `/applications/${id}/generate-payment-intent`,
    generateSubscriptionPaymentIntent: (id: number) =>
      `/applications/${id}/generate-subscription`,
    // generateInsurancePaymentIntent: (id: number) =>
    //   `/applications/${id}/generate-insurance-payment-intent`,
    getLeaseDocumentUrl: (id: number) =>
      `/applications/${id}/lease-document-url`,
    getSubscriptionStatus: (id: number) =>
      `/applications/${id}/subscription-status`,
    getPreviousApplication: (userId: number) =>
      `/applications/${userId}/previous-application`,
    // makeApplicationFeePayment: (id: number) =>
    //   `/applications/${id}/make-application-fee-payment`,
    // internalInsuranceFeePayment: (id: number) =>
    //   `/applications/${id}/insurance/subscribe-internal`,
    externalInsuranceFeePayment: (id: number) =>
      `/applications/${id}/insurance/subscribe-external`,
    completeStep: (id: number) => `/applications/${id}/complete-step`,
    submitComps: (id: number): string =>
      `/applications/${id}/pha-voucher/submit-comps`,
    submitPHAComps: (id: number): string =>
      `/applications/${id}/pha-voucher/submit-pha-comps`,
    dataFinitiComps: (id: number): string => `/applications/${id}/comps`,
    getOrgConfigs: (id: number): string =>
      `/applications/${id}/get-org-configs`,
    pha: {
      voucherEnabled: (id: number): string =>
        `/applications/${id}/pha-voucher-status`,
      deleteVoucher: (id: number): string =>
        `/applications/${id}/pha-voucher/delete`,
      getVoucher: (id: number): string => `/applications/${id}/pha-voucher`,
      updateStep: (id: number): string =>
        `/applications/${id}/pha-voucher/update-step`,
    },
    phaAgentFlow: {
      status: (id: number): string =>
        `/applications/${id}/inspector-flow-status`,
    },
    rejectApplication: (id: number): string => `/applications/${id}/reject`,
    applicationSuggestions: {
      suggestions: (id: number): string => `/applications/${id}/unit-suggested`,
      resubmit: (id: number): string => `/applications/${id}/re-submit`,
      dtcSuggestions: (id: number) => `/applications/${id}/dtc-suggestions`,
      dtcSuggestionsStatus: (id: number) =>
        `applications/${id}/dtc-suggestions/status`,
    },
    guestCards: {
      attach: (id: number): string => `/applications/${id}/attach-guest-card`,
      create: (id: number): string => `/applications/${id}/create-guest-card`,
    },
    queuedDocs: (id: number): string => `/applications/${id}/queued-docs`,
    logs: {
      list: (id: number): string => `/applications/${id}/logs`,
      create: (id: number): string => `/applications/${id}/logs/create`,
    },
    getAllRequestedDocuments: `/applications/requested-doc`,
    paymentDetails: (applicationId: number) =>
      `/applications/${applicationId}/payment-details`,
    rentBased: {
      getMAIAScore: (applicationId: number): string =>
        `/applications/${applicationId}/get-maia-score`,
      generateMAIAReport: (applicationId: number): string =>
        `/applications/${applicationId}/generate-maia-report`,
      getMAIAReport: (applicationId: number): string =>
        `/applications/${applicationId}/get-maia-report`,
      acceptByUnitDataId: (applicationId: number, unitDataId: number): string =>
        `applications/${applicationId}/accept/unit-data/${unitDataId}`,
    },
    applyCoupon: (id: number): string => `/applications/${id}/apply-coupon`,
    deleteInspectionReport: (applicationId: number): string =>
      `/applications/${applicationId}/inspection-report/delete`,
    rent_determination: {
      chats: (applicationId: number) => ({
        list: `applications/${applicationId}/rent-determination/chats`,
        create: `applications/${applicationId}/rent-determination/chats`,
        markAsRead: `applications/${applicationId}/rent-determination/chats/mark-as-read`,
        acceptProposal: (chatId: number): string =>
          `applications/${applicationId}/rent-determination/chats/${chatId}/accept-proposal`,
      }),
    },
    updateApplicationUnit: (applicationId: number): string =>
      `applications/${applicationId}/update-unit`,
  },
  applicationDocuments: {
    list: (applicationId: number): string =>
      `/applications/${applicationId}/documents`,
    complete: (applicationId: number, applicationDocumentId: number): string =>
      `/applications/${applicationId}/documents/${applicationDocumentId}/complete`,
    signedUrlForExisting: (
      applicationId: number,
      applicationDocumentId: number,
    ): string =>
      `/applications/${applicationId}/documents/${applicationDocumentId}/get-signed-url`,
    signedUrlForNew: (applicationId: number): string =>
      `/applications/${applicationId}/documents/get-signed-url`,
    getRequestedDocuments: `/me/requested-documents`,
    deleteRequestAndProcess: (applicationId: number): string =>
      `/applications/${applicationId}/documents/process`,
    deleteRequest: (applicationId: number): string =>
      `/applications/${applicationId}/documents/delete`,
  },
  applicationDocumentUploads: {
    list: (applicationId: number, applicationDocumentId: number): string =>
      `/applications/${applicationId}/documents/${applicationDocumentId}`,
    applicationDocumentUpload: (
      applicationId: number,
      applicationDocumentId: number,
      applicationDocumentUploadId: number,
    ): string =>
      `/applications/${applicationId}/documents/${applicationDocumentId}/uploads/${applicationDocumentUploadId}`,
    confirmUpload: (
      applicationId: number,
      applicationDocumentId: number,
      applicationDocumentUploadId: number,
    ): string =>
      `/applications/${applicationId}/documents/${applicationDocumentId}/uploads/${applicationDocumentUploadId}/confirm-upload`,
  },
  invites: {
    list: '/invites',
    show: (id: number): string => `/invites/${id}`,
    resend: (id: number): string => `/invites/${id}/resend`,
  },
  tenantMonitoring: {
    waitlistInvite: {
      create: `tenant-monitoring/waitlist-invite`,
      show: (id: number): string => `tenant-monitoring/waitlist-invite/${id}`,
      list: 'tenant-monitoring/waitlist-invite/list',
      analytics: (invite_id: number) =>
        `tenant-monitoring/waitlist-invite/${invite_id}/analytics`,
    },
    completeStep: (tenantId: number): string =>
      `tenant-monitoring/${tenantId}/complete`,
    show: (tenantId: number): string => `tenant-monitoring/${tenantId}`,
    fetchByUserId: (userId: number): string =>
      `tenant-monitoring/users/${userId}`,
    list: 'tenant-monitoring',
    remove: (tenantId: number): string =>
      `tenant-monitoring/${tenantId}/remove`,
    regenerate: (tenantId: number): string =>
      `tenant-monitoring/${tenantId}/re-generate-report`,
    updateEmployers: (tenantId: number): string =>
      `tenant-monitoring/${tenantId}/update-employers`,
  },
  guestCards: {
    list: '/guestcard',
    show: (id: number) => `/guestcard/${id}`,
    chats: (guestCardId: number) => ({
      list: `/guestcard/${guestCardId}/chats`,
      create: `/guestcard/${guestCardId}/chats/send-message`,
      markAsRead: `/guestcard/${guestCardId}/chats/mark-as-read`,
    }),
  },
  units: {
    list: '/units',
    show: (id: number): string => `/units/${id}`,
    termsConditions: (id: number): string =>
      `/units/${id}/terms-and-conditions`,
    getImages: (unit_id: number) => `/units/${unit_id}/images`,
    fetchPropertyDataByAddress: `/units/fetch-df-data`,
    contactLandlord: (unit_id: number) => `/units/${unit_id}/contact-landlord`,
  },
  organizations: {
    list: `/organisations`,
    show: (id: number): string => `/organisations/${id}`,
    getBrandUrl: (id: number): string => `/organisations/${id}/brandLogo`,
    generateApiKey: (organisationId: number): string =>
      `/organisations/${organisationId}/api-key`,
    deleteApiKey: (organisationId: number): string =>
      `/organisations/${organisationId}/api-key`,
    members: {
      list: (orgId: number): string => `/organisations/${orgId}/members`,
      update: (orgId: number, memberId: number): string =>
        `/organisations/${orgId}/members/${memberId}`,
      membersToShare: (orgId: number): string =>
        `/organisations/${orgId}/members-to-share`,
    },
    getPropertySignedUrl: '/organisations/signed-property-url',
    appendPropertyUrl: '/organisations/property-url',
    getPendingPropertyFilesGroupedByOrg: '/support/pending-property-files',
    getPropertyUrlsByOrgId: (organisationId: number): string =>
      `/support/organisation-pending-properties/${organisationId}`,
    rejectOrganisationPropertyUrl: (
      organisationPropertyUrlId: number,
    ): string =>
      `/support/organisation-pending-properties/${organisationPropertyUrlId}/reject`,
  },
  residenceHistories: {
    list: '/residence-histories',
    show: (id: number): string => `/residence-histories/${id}`,
  },
  mx: {
    getInstitutions: '/mx/institutions',
    getWidget: '/mx/institutions/:institutionCode/connect',
    importMember: '/mx/members/import',
    syncMember: '/mx/members/sync',
  },
  teller: {
    recordNewConnection: 'teller/record-connection',
  },
  citadel: {
    company: '/citadel/companies',
    bridgeToken: (companyMapping: string): string =>
      `citadel/companies/${companyMapping}/get-bridge-token`,
  },
  payroll: {
    show: '/payrolls',
    zeroIncomePayroll: '/payrolls/zero-income',
    showById: (payrollId: number) => `/payrolls/${payrollId}`,
    importPayroll: '/atomic/import',
    deletePayroll: (payrollId: number): string => `/atomic/${payrollId}`,
    getPayrollsByStatus: 'support/payrolls',
    getRejectedPayrolls: 'support/payrolls',
    getGroupedApplications: 'support/application-payrolls',
    getPendingPayrolls: (applicationId: number) =>
      `support/application-payrolls/${applicationId}`,
    manualUpload: 'support/payrolls/manual_upload',
    moveDocument: (documentId: number) => `support/move-document/${documentId}`,
    deleteTextractPayroll: (payrollId: number) =>
      `support/payrolls/${payrollId}`,
    deletePayrollDetail: (payrollDetailId: number) =>
      `support/payroll-detail/${payrollDetailId}`,
    requestDocument: (application: number) =>
      `support/${application}/documents`,
  },
  bank: {
    getRejectedBankStatements: 'support/rejected-bank-statements',
    fetchBanks: 'support/banks',
    deleteBankStatement: (BankStatementId: number) =>
      `support/bank-statement/${BankStatementId}`,
    bankAccounts: 'support/bank-accounts',
    bankAccount: 'support/bank-account',
    fetchTransactions: (documentId: number) =>
      `support/${documentId}/bank-transactions`,
    addTransaction: 'support/bank-transactions',
    editTransaction: 'support/bank-transactions',
    deleteTransaction: 'support/bank-transactions',
    createDummyAccount: '/bank-account/no-account',
    deleteDummyAccount: '/bank-account',
    getGroupedApplications: 'support/application-bank-statements',
    getRejectedBankStatementById: (applicationId: number) =>
      `support/application-bank-statement/${applicationId}`,
  },
  public: {
    units: {
      show: (id: number): string => `public/invitations/${id}`,
      guestApplyUnit: (id: number): string => `/public/guest-apply-units/${id}`,
    },
    phaInvite: {
      show: (inviteId: string): string => `/public/pha-invite/${inviteId}`,
      acceptInvite: (inviteId: string) =>
        `/public/pha-invite/${inviteId}/accept`,
    },
    waitlistInvite: {
      show: (id: number) => `public/waitlist-invite/${id}`,
    },
  },
  atomic: {
    accessToken: '/atomic/access-token',
    company: '/atomic/companies',
  },
  documentClassification: {
    classifier: '/document-classification',
  },
  unAssignedApplication: {
    list: '/applications/unassigned',
    assignToAgent: (applicationId: number): string =>
      `/applications/${applicationId}/assign-to-agent`,
    assignManagerOrAgent: (applicationId: number): string =>
      `/applications/${applicationId}/assign`,
  },
  unAssignedPhaAgentApplication: {
    assignToPhaAgent: (applicationId: number) =>
      `applications/${applicationId}/assignPhaAgent`,
  },

  insurancedApplications: {
    list: '/applications/insuranced',
  },
  applicants: {
    show: (id: number) => `/applicants/${id}`,
    revertStep: (id: number): string => `/applicants/${id}/revert-step`,
    skipCreditStep: (id: number): string =>
      `/applicants/${id}/skip-credit-step`,
    dtc: (id: number): string => `/applicants/${id}/dtc`,
    regenrateConsumerReport: (id: number): string =>
      `applicants/${id}/regenrate-consumer-report`,
  },
  referrals: {
    list: '/referrals',
    create: '/referrals/create',
    resend: (referralId: number) => `/referrals/${referralId}/resend-invite`,
  },
  insurance: {
    upload: (applicationId: number): string =>
      `/applications/${applicationId}/upload-insurance`,
    details: (applicationId: number): string =>
      `/applications/${applicationId}/insurance/details`,
    request: (applicationId: number): string =>
      `/applications/${applicationId}/request-insurance`,
    update: (applicationId: number): string =>
      `/applications/${applicationId}/insurance-status`,
    uwQuestions: (applicationId: number): string =>
      `/insurance/${applicationId}/uw-questions`,
    getQuote: (applicationId: number): string =>
      `/insurance/${applicationId}/quote`,
    getInsuranceDetail: (applicationId: number): string =>
      `/insurance/${applicationId}`,
    playmentPlan: (applicationId: number): string =>
      `/insurance/${applicationId}/payment-plan`,
    playmentDetails: (applicationId: number): string =>
      `/insurance/${applicationId}/payment-details`,
    getPolicy: (applicationId: number): string =>
      `/insurance/${applicationId}/policy`,
    getDiscloserMessage: (applicationId: number, type: string): string =>
      `/insurance/${applicationId}/${type}`,
  },
  coupons: {
    validate: `/coupons/validate`,
  },
  system: {
    log: {
      logs: '/systemLogs/logs',
    },
    integrationHealth: {
      list: '/health-check/list',
      check: (integrationHealthStatusId: number): string =>
        `/health-check/${integrationHealthStatusId}`,
    },
  },
  crs: {
    authenticationIframeUrl: (organisationId: number) =>
      `/crs/${organisationId}/iframe/url`,
    checkShareStatus: (organisationId: number, unitId: number) =>
      `/crs/${organisationId}/check-share-status/${unitId}`,
    checkIfReportExists: (
      organisationId: number,
      unitId: number,
      userId: number,
    ) => `/crs/${organisationId}/check-for-report/${unitId}/user/${userId}`,
    viewReportIframeUrl: (
      organisationId: number,
      unitId: number,
      userId: number,
    ) => `/crs/${organisationId}/report/${unitId}/user/${userId}/iframe/url`,
    checkRefreshStatus: '/crs/check-refresh-status',
  },
  phas: {
    list: '/phas',
    show: (id: number): string => `/phas/${id}`,
    invites: {
      list: '/phas/invites/list',
      create: (pha_id: number) => `/phas/${pha_id}/invite`,
      bulkCreate: (pha_id: number) => `/phas/${pha_id}/invites`,
      answerQuestionnaire: (phaInvitedId: string) =>
        `phas/invites/${phaInvitedId}/additional-information`,
      getTermsAndConditions: (organisationId: number) =>
        `phas/${organisationId}/terms-and-conditions`,
      updateTermsAnditions: (organisationId: number) =>
        `phas/${organisationId}/tnc-update`,
    },
    members: {
      list: (phaId: number): string => `/phas/${phaId}/members`,
      create: (phaId: number): string => `/phas/${phaId}/member`,
      update: (phaId: number, memberId: number): string =>
        `/phas/${phaId}/member/${memberId}`,
    },
    programmes: {
      createAndList: (pha_id) => `/phas/${pha_id}/programmes`,
      updateAndRemove: (pha_id: number, programme_id: number) =>
        `/phas/${pha_id}/programmes/${programme_id}`,
      getProgramme: (pha_id: number) => `/phas/${pha_id}/programme`,
    },
    subProgrammes: {
      getAllSubProgrammes: '/phas/all/sub-programmes',
      createAndList: (pha_id: number) => `/phas/${pha_id}/sub-programmes`,
      updateAndRemove: (pha_id: number, sub_programme_id: number) =>
        `/phas/${pha_id}/sub-programmes/${sub_programme_id}`,
      getSubProgramme: (pha_id: number) => `/phas/${pha_id}/sub-programme`,
      getById: (id: number) => `/phas/${id}/sub-program`,
    },
    analytics: (invite_id: number) => `phas/invites/${invite_id}/analytics`,
    w9Form: (applicationId: number): string => `phas/${applicationId}/w9-form`,
  },

  waitlistApplicantDocument: {
    list: (waitlistApplicantId: number): string =>
      `/tenant-monitoring/${waitlistApplicantId}/documents`,
    requestDocument: (waitlistApplicantId: number): string =>
      `/tenant-monitoring/${waitlistApplicantId}/support-documents`,
    renteDocumentsData: (
      userId: number,
      renterDocumentId: number,
      renterDocumentDataId: number,
    ): string =>
      `/tenant-monitoring/${userId}/documents/${renterDocumentId}/uploads/${renterDocumentDataId}`,
    signedUrlForExisting: (
      waitlistApplicantId: number,
      waitlistApplicantDocumentId: number,
    ): string =>
      `/tenant-monitoring/${waitlistApplicantId}/documents/${waitlistApplicantDocumentId}/get-signed-url`,
    signedUrlForNew: (waitlistApplicantId: number): string =>
      `/tenant-monitoring/${waitlistApplicantId}/documents/get-signed-url`,
    confirmUpload: (
      waitlistApplicantId: number,
      renterDocumentDataId: number,
      renterDocumentId: number,
    ): string =>
      `/tenant-monitoring/${waitlistApplicantId}/documents/${renterDocumentDataId}/uploads/${renterDocumentId}/confirm-upload`,
    complete: (
      waitlistApplicantId: number,
      WaitlistApplicantDocumentId: number,
    ): string =>
      `/tenant-monitoring/${waitlistApplicantId}/documents/${WaitlistApplicantDocumentId}/complete`,
    deleteRequestAndProcess: (waitlistApplicantId: number): string =>
      `/tenant-monitoring/${waitlistApplicantId}/documents/process`,
    deleteRequest: (waitlistApplicantId: number): string =>
      `/tenant-monitoring/${waitlistApplicantId}/documents/delete`,
  },
  user: {
    profile: (user_id: number) => `/me/${user_id}`,
    editProfile: (user_id: number) => `admin/users/${user_id}`,
  },
};
