import React from 'react';
import { Provider } from 'mobx-react';
import 'antd/dist/antd.less';
import { IntercomProvider } from 'react-use-intercom';
import SplashPage from './components/SplashPage';
import { REACT_APP_ENV } from './utils/secrets';
import { ENVIRONMENT } from './constants/constants';

import './styles/main.scss';
import 'react-phone-number-input/style.css';
import { createStores } from './stores';

declare global {
  interface Window {
    analytics: any;
  }
}

const INTERCOM_APP_ID = 'bkejkdey';

const ZENDESH_ENVS: string[] = [ENVIRONMENT.DEMO, ENVIRONMENT.PRODUCTION];

const App = () => {
  return (
    <Provider {...createStores()}>
      {ZENDESH_ENVS.includes(REACT_APP_ENV) ? (
        <SplashPage />
      ) : (
        <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
          <SplashPage />
        </IntercomProvider>
      )}
    </Provider>
  );
};

export default App;
