import { observable } from 'mobx';
import Model from '../Model';
import { GuestCardStore } from '../../stores/GuestCardStore';
import { GuestCardChat } from './GuestCardChatModel';
import { PaginatedModelList } from '../PaginatedModelList';

export class GuestCard extends Model {
  static _store: GuestCardStore;

  @observable id: number;

  @observable name: string;

  @observable email: string;

  @observable phone: string;

  @observable comments: number;

  @observable move_in_date: string;

  @observable source: string;

  @observable date_received: string;

  @observable property_address: string;

  @observable guest_card_url: string;

  @observable organisation_id: number;

  @observable created_at: string;

  @observable updated_at: string;

  @observable unread_count: number;

  @observable chats = new PaginatedModelList<GuestCardChat>(GuestCardChat);
}
