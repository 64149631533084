import { action, observable } from 'mobx';
import { PaginatedModelList } from 'models/PaginatedModelList';
import { QueuedDocumentUploadStatus } from 'models/entities/QueuedDoc';
import ApiRoutes from 'routes/api/ApiRoutes';
import { Payroll } from 'models/entities/Payroll';
import {
  PayrollDetailsValues,
  transformPayrollsData,
} from 'pages/app/support/shared/Utils';
import Store from './Store';

export class PayrollStore extends Store<Payroll> {
  constructor() {
    super();
    Payroll._store = this;
  }

  @observable
  payrollsPaginatedModelList = new PaginatedModelList<Payroll>(Payroll);

  @observable groupedApplications = [];

  @observable requestedDocuments = [];

  @observable nameOfApplicantWhoseDocumentIsBeingProcessed = '';

  @action
  setNameOfApplicantWhoseDocumentIsBeingProcessed(name: string) {
    this.nameOfApplicantWhoseDocumentIsBeingProcessed = name;
  }

  get payrolls() {
    return this.payrollsPaginatedModelList.items;
  }

  get requestedDocs() {
    return this.requestedDocuments;
  }

  @action
  async loadPayrollsByStatus(docStatus: string) {
    return this.payrollsPaginatedModelList.load(
      ApiRoutes.payroll.getPayrollsByStatus,
      { status: docStatus },
      undefined,
      transformPayrollsData,
    );
  }

  @action
  async getGroupedApplications(organisation?: number): Promise<void> {
    const response = await this.apiService.get(
      ApiRoutes.payroll.getGroupedApplications,
      { organisation },
    );
    this.groupedApplications = response.data;
  }

  async getPayrollsByApplicationId(applicationId: number): Promise<void> {
    await this.payrollsPaginatedModelList.load(
      ApiRoutes.payroll.getPendingPayrolls(applicationId),
      {},
      undefined,
      transformPayrollsData,
    );
  }

  @action
  async getRequestedApplications(params?: {
    [key: string]: any;
  }): Promise<void> {
    const response = await this.apiService.get(
      ApiRoutes.applications.getAllRequestedDocuments,
      params,
    );
    this.requestedDocuments = response.map((obj) => obj.requested_against);
  }

  @action
  async manualUploadPayroll(body: {
    payroll_id: number;
    pay_cycle: string;
    details: PayrollDetailsValues[];
  }): Promise<void> {
    const response = await this.apiService.patch(
      ApiRoutes.payroll.manualUpload,
      body,
    );
    if (response?.success) {
      this.payrolls.filter(
        (payroll) => payroll.id === body.payroll_id,
      )[0].status = QueuedDocumentUploadStatus.PROCESSED;
    }
  }

  @action
  async moveDocument(
    data: Payroll,
    userId: number,
    documentType: string,
    desiredDocumentType: string,
    taxReturnType?: string,
    move_reason?: string,
  ): Promise<any> {
    const body = {
      userId,
      documentType,
      desiredDocumentType,
      taxReturnType,
      move_reason,
    };
    const documentId = data.id;
    try {
      const response = await this.apiService.post(
        ApiRoutes.payroll.moveDocument(documentId),
        body,
      );
      this.payrollsPaginatedModelList.removeItem(data);
      return response;
    } catch (e) {
      if ((e as any).code === 9998) {
        this.payrollsPaginatedModelList.removeItem(data);
      }
      throw e;
    }
  }

  @action
  async rejectDocument(
    payroll: Payroll,
    rejection_reason?: string,
  ): Promise<any> {
    const response = await this.apiService.delete(
      ApiRoutes.payroll.deleteTextractPayroll(payroll.id),
      null,
      null,
      null,
      { rejection_reason },
    );
    this.payrollsPaginatedModelList.removeItem(payroll);
    return response;
  }

  @action
  async requestDocument(
    docName: string,
    request_description: string,
    userId: number,
    applicationId: number,
    upload_against?: number,
    default_template = false,
  ): Promise<void> {
    this.apiService.post(ApiRoutes.payroll.requestDocument(applicationId), {
      user_id: userId,
      request_description,
      request_message: docName,
      upload_against,
      default_template,
    });
    this.payrollsPaginatedModelList.items.filter(
      (item) => item.id === upload_against,
    )[0].status = QueuedDocumentUploadStatus.REQUESTED;
  }

  @action
  async deletePayrollDetailById(
    payrollDetailId: number,
    payroll: Payroll,
  ): Promise<Payroll> {
    const response = await this.apiService.delete(
      ApiRoutes.payroll.deletePayrollDetail(payrollDetailId),
    );

    payroll.updateFromJson(response);

    return response;
  }

  @action
  async fetchCompanies(query: string) {
    const response = await this.apiService.get(ApiRoutes.atomic.company, {
      query,
    });

    return response;
  }

  @action
  async fetchAtomicToken() {
    const response = await this.apiService.get(ApiRoutes.atomic.accessToken);
    return response;
  }

  @action
  clearStore(): void {
    this.payrollsPaginatedModelList = new PaginatedModelList<Payroll>(Payroll);
    this.requestedDocuments = [];
  }
}
