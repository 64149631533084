import { action } from 'mobx';
import ApiRoutes from 'routes/api/ApiRoutes';
import { Bank } from '../models/entities/BankModel';
import Store from './Store';

export class BankStore extends Store<Bank> {
  constructor() {
    super();
    Bank._store = this;
  }

  @action
  async fetchInstitutions(searchedBank: string) {
    const fetchingLimit = 100;
    const response = await this.apiService.get(ApiRoutes.mx.getInstitutions, {
      institution_name: searchedBank,
      fetchingLimit,
    });

    return response;
  }

  @action
  async deleteBank(bankId: number) {
    this.remove(bankId);
  }

  @action
  async getMXIframeUrl(institutionCode: string) {
    const response = await this.apiService.get(
      ApiRoutes.mx.getWidget.replace(':institutionCode', institutionCode),
    );

    return response;
  }

  @action
  clearStore(): void {}
}
