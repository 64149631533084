import { action, computed, observable } from 'mobx';
import { Referral } from 'models/entities/ReferralModel';
import { PaginatedModelList } from 'models/PaginatedModelList';
import ApiRoutes from 'routes/api/ApiRoutes';
import Store from './Store';

export class ReferralStore extends Store<Referral> {
  constructor() {
    super();
    Referral._store = this;
  }

  @observable loading = false;

  @observable loaded = false;

  @observable referralPaginatedList = new PaginatedModelList<Referral>(
    Referral,
  );

  @computed get referrals() {
    return this.referralPaginatedList.items;
  }

  @action
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @action
  setLoaded(loaded: boolean) {
    this.loaded = loaded;
  }

  @action
  async fetchReferrals(params = {}, force = false) {
    if ((!this.loading && !this.loaded) || force) {
      this.referralPaginatedList.setItems([]);
      this.setLoading(true);
      try {
        await this.referralPaginatedList.load(ApiRoutes.referrals.list, params);
        this.setLoading(false);
        this.setLoaded(true);
      } catch (e) {
        this.setLoading(false);
      }
    }
  }

  @action
  async createReferrals(referralBody: {
    invitee_name: string;
    invitee_email: string;
    note: string;
  }) {
    const response = await this.apiService.post(
      ApiRoutes.referrals.create,
      referralBody,
    );
    if (response && response.data) {
      this.referralPaginatedList.setItems([
        response.data,
        ...this.referralPaginatedList.items,
      ]);
    }
  }

  @action
  async resendReferral(referralId: number) {
    await this.apiService.post(ApiRoutes.referrals.resend(referralId));
  }

  @action
  clearStore(): void {
    this.referralPaginatedList = new PaginatedModelList<Referral>(Referral);
    this.setLoaded(false);
    this.setLoading(false);
  }
}
