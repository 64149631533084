import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { Route } from 'react-router';

import { PublicRoute } from 'guards';
import {
  LazyLoadedPropertyPreview,
  LazyLoadedGuestApply,
  LazyLoadedWaitingListApplyPreview,
} from 'pages';
import {
  LazyLoadedAuthContainer,
  LazyLoadedPhaApplyContainer,
} from 'containers';
import PageNotFound from 'components/PageNotFound/PageNotFound';
import WebRoutes from './WebRoutes';
import ProtectedRoutes from './ProtectedRoutes';

const webRoutes = (
  <Switch>
    <PublicRoute
      exact
      path={WebRoutes.public.unitsPreview}
      component={LazyLoadedPropertyPreview}
    />
    <PublicRoute
      exact
      path={WebRoutes.public.guestApply}
      component={LazyLoadedGuestApply}
    />
    <PublicRoute path="/auth" component={LazyLoadedAuthContainer} />
    <Route
      exact
      path={WebRoutes.user.phaUserInvitation}
      component={LazyLoadedPhaApplyContainer}
    />
    <Route
      exact
      path="/"
      render={() => <Redirect to={WebRoutes.app.applications} />}
    />
    <PublicRoute
      exact
      path={WebRoutes.public.waitingListApply}
      component={LazyLoadedWaitingListApplyPreview}
    />
    <ProtectedRoutes />
    <Route path="*" component={PageNotFound} />
  </Switch>
);

export default webRoutes;
