import { observable } from 'mobx';
import Model from 'models/Model';
import { ReferralStore } from 'stores/ReferralStore';

export class Referral extends Model {
  static _store: ReferralStore;

  @observable id: number;

  @observable agent_id: number;

  @observable invited_by: number;

  @observable invitee_name: string;

  @observable invitee_email: string;

  @observable note: string;

  @observable status: string;

  @observable createdAt: string;

  @observable updatedAt: string;
}
