import { ManualEntryFormValues } from '../payroll/PayrollManualEntryForm';

export interface PayrollDetailsValues {
  id?: number;
  employee?: {
    first_name?: string;
    last_name?: string;
  };
  employer?: {
    name?: string;
    address?: {
      line_1?: string;
      line_2?: string;
      city?: string;
      state_code?: string;
      postal_code?: string;
    };
  };
  job?: {
    title?: string;
    start_date?: string;
    end_date?: string;
  };
  pay?: {
    net?: number;
    date?: string;
  };
  tax?: {
    state?: number;
    medicare?: number;
    local?: number;
    social_security?: number;
    federal?: number;
  };
  benefit_insurance?: number;
  gross?: {
    pay?: number;
    ytd_gross?: number;
  };
}

export const getFilteredDetails = (details: ManualEntryFormValues[]) =>
  details.map((detail: ManualEntryFormValues) => {
    let newDetail: PayrollDetailsValues = {};
    Object.keys(detail).map((key) => {
      switch (key) {
        case 'id':
          return (newDetail = {
            ...newDetail,
            id: detail.id,
          });
        case 'employee_first_name':
          return (newDetail = {
            ...newDetail,
            employee: {
              ...newDetail.employee,
              first_name: detail.employee_first_name,
            },
          });
        case 'employee_last_name':
          return (newDetail = {
            ...newDetail,
            employee: {
              ...newDetail.employee,
              last_name: detail.employee_last_name,
            },
          });
        case 'employer_name':
          return (newDetail = {
            ...newDetail,
            employer: {
              ...newDetail.employer,
              name: detail.employer_name,
            },
          });
        case 'employer_address_city':
          return (newDetail = {
            ...newDetail,
            employer: {
              ...newDetail.employer,
              address: {
                ...newDetail.employer?.address,
                city: detail.employer_address_city,
              },
            },
          });
        case 'employer_address_state_code':
          return (newDetail = {
            ...newDetail,
            employer: {
              ...newDetail.employer,
              address: {
                ...newDetail.employer?.address,
                state_code: detail.employer_address_state_code,
              },
            },
          });
        case 'employer_address_postal_code':
          return (newDetail = {
            ...newDetail,
            employer: {
              ...newDetail.employer,
              address: {
                ...newDetail.employer?.address,
                postal_code: detail.employer_address_postal_code,
              },
            },
          });
        case 'employer_address_line_1':
          return (newDetail = {
            ...newDetail,
            employer: {
              ...newDetail.employer,
              address: {
                ...newDetail.employer?.address,
                line_1: detail.employer_address_line_1,
              },
            },
          });
        case 'employer_address_line_2':
          return (newDetail = {
            ...newDetail,
            employer: {
              ...newDetail.employer,
              address: {
                ...newDetail.employer?.address,
                line_2: detail.employer_address_line_2,
              },
            },
          });
        case 'job_title':
          return (newDetail = {
            ...newDetail,
            job: { ...newDetail.job, title: detail.job_title },
          });
        case 'job_start_date':
          return (newDetail = {
            ...newDetail,
            job: { ...newDetail.job, start_date: detail.job_start_date },
          });
        case 'job_end_date':
          return (newDetail = {
            ...newDetail,
            job: { ...newDetail.job, end_date: detail.job_end_date },
          });
        case 'net_pay':
          return (newDetail = {
            ...newDetail,
            pay: { ...newDetail.pay, net: detail.net_pay },
          });
        case 'pay_date':
          return (newDetail = {
            ...newDetail,
            pay: { ...newDetail.pay, date: detail.pay_date },
          });
        case 'state_tax':
          return (newDetail = {
            ...newDetail,
            tax: { ...newDetail.tax, state: +detail.state_tax },
          });
        case 'medicare_tax':
          return (newDetail = {
            ...newDetail,
            tax: { ...newDetail.tax, medicare: +detail.medicare_tax },
          });
        case 'local_tax':
          return (newDetail = {
            ...newDetail,
            tax: { ...newDetail.tax, local: +detail.local_tax },
          });
        case 'social_security_tax':
          return (newDetail = {
            ...newDetail,
            tax: {
              ...newDetail.tax,
              social_security: +detail.social_security_tax,
            },
          });
        case 'federal_tax':
          return (newDetail = {
            ...newDetail,
            tax: { ...newDetail.tax, federal: +detail.federal_tax },
          });

        case 'gross':
          return (newDetail = {
            ...newDetail,
            gross: { ...newDetail.gross, pay: +detail.gross },
          });
        case 'ytd_gross':
          return (newDetail = {
            ...newDetail,
            gross: { ...newDetail.gross, ytd_gross: +detail.ytd_gross },
          });
        case 'benefit_insurance':
          return (newDetail = {
            ...newDetail,
            benefit_insurance: +detail.benefit_insurance,
          });
      }
      return newDetail;
    });
    return newDetail;
  });

export const transformPayrollsData = (documents) => {
  documents.data.map((doc) => {
    doc.details.map((detail) => {
      Object.keys(detail).map((key) => {
        detail[key] =
          detail[key] === 'N/A' || detail[key] === -999
            ? undefined
            : detail[key];
      });
    });
  });
  return documents;
};
