import { observable } from 'mobx';
import Model from '../Model';
import { StateStore } from '../../stores/StateStore';

export class State extends Model {
  static _store: StateStore;

  @observable id: number;

  @observable state: string;

  @observable code: string;
}
