import { observable } from 'mobx';
import Model from '../Model';
import { InviteStore } from '../../stores/InviteStore';
import { InviteType } from '../../enums/InviteType';
import { User } from './UserModel';

export class Invite extends Model {
  static _store: InviteStore;

  @observable id: number;

  @observable unit_id: number;

  @observable renter_name: string;

  @observable renter_email: string;

  @observable note?: string;

  @observable invite_type: InviteType;

  @observable created_at: string;

  @observable updated_at: string;

  @observable accepted: boolean;

  @observable application_id: number;

  @observable inviter: User;

  @observable can_resend_invite: boolean;

  @observable last_attempt_at: Date;
}
