import { Route } from 'react-router';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { AUTH_STORE } from '../constants/stores';
import NotAuthorized from '../components/NotAuthorized/NotAuthorized';

const AdminOrManagerProtectedRoute: React.FC<any> = ({
  component: Component,
  ...rest
}) => {
  if (!rest.authStore.loggedInUser.isAdminOrManager) {
    return <Route {...rest} render={(props) => <NotAuthorized />} />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default inject(AUTH_STORE)(observer(AdminOrManagerProtectedRoute));