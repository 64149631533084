export enum UnitStatus {
  AVAILABLE = 'Available',
  UNAVAILABLE = 'Unavailable',
}

export class UnitStatusUtils {
  static getUnitStatus = (v: UnitStatus) => {
    switch (v) {
      case UnitStatus.AVAILABLE: {
        return 'Available';
      }
      case UnitStatus.UNAVAILABLE: {
        return 'Unavailable';
      }
    }
  };
}
