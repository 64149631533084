import React from 'react';
import cx from 'classnames';
import './HeadingText.scss';

interface HeadingTextProps {
  className?: string;
  heading?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'H6' | 'H7' | 'H8';
  color?: string;
}

const HeadingText: React.FC<HeadingTextProps> = ({
  heading = 'H1',
  color = 'darkText',
  className,
  ...props
}) => (
  <span {...props} className={cx(heading, `text-${color}`, className)}>
    {props.children}
  </span>
);

export default HeadingText;
