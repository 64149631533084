import { observable } from 'mobx';

import Model from '../Model';
import { CityStore } from '../../stores/CityStore';

export class City extends Model {
  static _store: CityStore;

  @observable id: number;

  @observable city: string;

  @observable slug: string;
}
