/* eslint-disable import/no-cycle */
import { observable } from 'mobx';
import { WaitingListSteps } from 'models/WaitingListStep';

import { WaitingListApplicantStore } from '../../stores/WaitingListApplicantStore';
import { Model } from '../Model';
import { ModelItem } from '../ModelItem';
import { ModelList } from '../ModelList';
import { DocumentModel } from './DocumentModel';
import { Bank } from './BankModel';
import { User } from './UserModel';
import { Building } from './BuildingModel';

export interface WaitingListEmployer {
  name: string;
  address: {
    state: {
      state: string;
      code: string;
    };
    city: string;
    zip: string;
  };
}

export enum WaitingListStatus {
  INCOMPLETE = 'in-complete',
  PROCESSING = 'processing',
  ACTIVE = 'active',
  IN_ACTIVE = 'in-active',
  FAILED = 'failed',
}

export class WaitingListApplicant extends Model {
  static _store: WaitingListApplicantStore;

  @observable id: number;

  @observable user_id: number;

  @observable first_name: string;

  @observable last_name: string;

  @observable phone_number: number;

  @observable email: string;

  @observable documents = new ModelList<DocumentModel>(DocumentModel);

  @observable banks = new ModelList<Bank>(Bank);

  @observable organisation_id: number;

  @observable step: WaitingListSteps;

  @observable user = new ModelItem<User>(User);

  @observable is_monitoring_user: boolean;

  @observable invite_id: number;

  @observable report_url: string;

  @observable building: Building;

  @observable employers: { data: WaitingListEmployer[] };

  @observable status: WaitingListStatus;

  @observable report_status: string;

  @observable terms_accepted_at: Date;

  get fullname(): string {
    if (this.last_name) {
      return `${this.first_name} ${this.last_name}`;
    }
    return this.first_name;
  }
}
