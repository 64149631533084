import { observable } from 'mobx';
import Model from 'models/Model';
import { WaitListInviteStore } from 'stores/WaitListInviteStore';

export class WaitListInviteAnalytics extends Model {
  static _store: WaitListInviteStore;

  @observable id: number;

  @observable event: string;

  @observable event_occured_at: Date;
}
