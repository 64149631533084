export const { REACT_APP_BASE_URL } = process.env;
export const { REACT_APP_BACKEND_URL } = process.env;

export const { REACT_APP_SEGMENT_KEY } = process.env;

export const { REACT_APP_ENV } = process.env;

// ATLAS Org Id
export const ENV_ATLAS_ORG_ID = process.env.REACT_APP_ATLAS_ORG_ID;

// HALO Org Id
export const ENV_HALO_ORG_ID = process.env.REACT_APP_HALO_ORG_ID;

// HPA Org Id
export const ENV_HPA_ORG_ID = process.env.REACT_APP_HPA_ORG_ID;

// Matrix Org Id
export const ENV_MATRIX_ORG_ID = process.env.REACT_APP_MATRIX_ORG_ID;
// Matrix DTC Unit Id
export const ENV_MATRIX_CONNECT_UNIT_ID =
  process.env.REACT_APP_MATRIX_CONNECT_UNIT_ID;

// Morgan Org Id
export const ENV_MORGAN_ORG_ID = process.env.REACT_APP_MORGAN_ORG_ID;

// Team Matrix Org Id ( for Support and internal user)
export const ENV_TEAM_MATRIX_ORG_ID = process.env.REACT_APP_TEAM_MATRIX_ORG_ID;

// McEnearney Org Id
export const ENV_MCENEARNEY_ORG_ID = process.env.REACT_APP_MCENEARNEY_ORG_ID;

export const ENV_MATRIX_TEAM_ORG_ID = process.env.REACT_APP_MATRIX_TEAM_ORG_ID;
// McEnearney Generic Unit Id (for unavailable units)
export const ENV_MCENEARNEY_GENERIC_UNIT_ID =
  process.env.REACT_APP_MCENEARNEY_GENERIC_UNIT_ID;

export const ENV_INSPECTIFY_EXPERIENCE_URL =
  process.env.REACT_APP_INSPECTIFY_EXPERIENCE_URL;

export const ENV_NOVA_PUBLIC_ID = process.env.REACT_APP_NOVA_PUBLIC_ID;

export const ENV_NOVA_PRODUCT_ID = process.env.REACT_APP_NOVA_PRODUCT_ID;

export const ENV_ZENDESK_API_KEY = process.env.REACT_APP_ZENDESK_API_KEY;

export const ENV_GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY;

// Greenwich close
export const ENV_GREENWICH_CLOSE_ORG_ID =
  process.env.REACT_APP_GREENWICH_CLOSE_ORG_ID;
export const ENV_GREENWICH_CLOSE_MONITORING_APP_UNIT_ID =
  process.env.REACT_APP_GREENWIC_CLOSE_MONITORING_APP_UNIT_ID;

export const ENV_TELLER_APPLICATION_ID =
  process.env.REACT_APP_TELLER_APPLICATION_ID;
