import { Route } from 'react-router';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { AUTH_STORE } from '../constants/stores';
import NotAuthorized from '../components/NotAuthorized/NotAuthorized';

const RenterOrSupportOrPHAOrInspectorProtectedRoute: React.FC<any> = ({
  component: Component,
  inverse,
  authStore,
  ...rest
}) => {
  let isRenterOrSupportOrPHAOrInspector =
    !authStore.isLoadingLoggedInUser &&
    authStore.loggedInUser.isRenterOrSupportOrPHAOrInspector;
  if (inverse) {
    isRenterOrSupportOrPHAOrInspector = !isRenterOrSupportOrPHAOrInspector;
  }
  if (!isRenterOrSupportOrPHAOrInspector) {
    return <Route {...rest} render={(props) => <NotAuthorized />} />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default inject(AUTH_STORE)(
  observer(RenterOrSupportOrPHAOrInspectorProtectedRoute),
);
