import { observable } from 'mobx';
import { PaginatedModelList } from 'models/PaginatedModelList';
import Model from '../Model';
import { BuildingStore } from '../../stores/BuildingStore';
import { Organization } from './OrganizationModel';
import { State } from './StateModel';
import { City } from './CityModel';
import { Unit } from './UnitModel';
import { ModelItem } from '../ModelItem';
import { ModelList } from '../ModelList';
import { BuildingImage } from './BuildingImage';

export enum BuildingType {
  SINGLE_UNIT = 'single_unit',
  MULTI_UNIT = 'multi_unit',
  VACATION = 'vacation',
  COMMERCIAL = 'commercial',
  MIXED_USE = 'mixed_use',
}

export class Building extends Model {
  static _store: BuildingStore;

  @observable id: number;

  @observable organisation_id: number;

  @observable building_images = new ModelList<BuildingImage>(BuildingImage);

  @observable name: string;

  @observable organisation: Organization;

  @observable address_line_1: string;

  @observable address_line_2: string;

  @observable type: BuildingType;

  @observable units = new PaginatedModelList<Unit>(Unit);

  @observable state = new ModelItem<State>(State);

  @observable city = new ModelItem<City>(City);

  @observable zip_code: string;

  @observable latitude: number;

  @observable longitude: number;

  @observable pha_voucher_enabled: boolean;

  @observable landlord_tax_form_url: string;

  @observable pha_sub_programme_id: number;

  @observable created_at: string;

  @observable updated_at: string;
}
