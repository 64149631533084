import { observable } from 'mobx';
import { ChatCategory } from '../../enums/ChatCategory.enum';
import { ChatType } from '../../enums/ChatType.enum';
import { RentDeterminationChatStore } from '../../stores/RentDeterminationChatStore';
import Model from '../Model';

export class RentDeterminationChat extends Model {
  static _store: RentDeterminationChatStore;

  @observable id: number;

  @observable application_id: number;

  @observable category: ChatCategory;

  @observable type: ChatType;

  @observable message_body: string;

  @observable read: boolean;

  @observable meta: JSON;

  @observable createdAt: Date;

  @observable updatedAt: Date;
}
