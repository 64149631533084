import { action, observable } from 'mobx';
import ApiRoutes from 'routes/api/ApiRoutes';
import { notification } from 'antd';
import Store from './Store';
import { Applicant } from '../models/entities/ApplicantModel';
import { PaginatedModelList } from '../models/PaginatedModelList';

export class ApplicantStore extends Store<Applicant> {
  constructor() {
    super();
    Applicant._store = this;
  }

  @observable applicantPaginatedList = new PaginatedModelList<Applicant>(
    Applicant,
  );

  @observable mxMonitoringTenantsPaginatedList = new PaginatedModelList<
    Applicant
  >(Applicant);

  @action
  async updateApplicant(
    id: number,
    body: { [key: string]: any },
  ): Promise<Applicant> {
    const applicant = Applicant.getOrNew(id) as Applicant;
    applicant.setUpdating(true);
    const response = await this.apiService.patch(
      ApiRoutes.applicants.show(id),
      body,
    );
    applicant.updateFromJson(response.data);
    applicant.setUpdating(false);
    return applicant;
  }

  @action
  async updateApplicantDTCRequirements(
    id: number,
    body: { [key: string]: any },
  ): Promise<Applicant> {
    const applicant = Applicant.getOrNew(id) as Applicant;
    applicant.setUpdating(true);
    const response = await this.apiService.patch(
      ApiRoutes.applicants.dtc(id),
      body,
    );
    applicant.updateFromJson(response.data);
    applicant.setUpdating(false);
    return applicant;
  }

  @action
  async revertStep(id: number) {
    const response = await this.apiService.patch(
      ApiRoutes.applicants.revertStep(id),
    );
    return response;
  }

  @action
  async skipCreditStep(id: number) {
    try {
      await this.apiService.patch(ApiRoutes.applicants.skipCreditStep(id));
    } catch (e) {
      notification.error({
        message: e?.message || 'Something went wrong',
        duration: 2.5,
      });
    }
  }

  @action
  async generateConsumerReport(id: number) {
    try {
      await this.apiService.patch(
        ApiRoutes.applicants.regenrateConsumerReport(id),
      );
    } catch (e) {
      notification.error({
        message: e?.message || 'Something went wrong!',
        duration: 2.5,
      });
    }
  }

  @action
  clearStore(): void {
    this.applicantPaginatedList = new PaginatedModelList<Applicant>(Applicant);
    this.mxMonitoringTenantsPaginatedList = new PaginatedModelList<Applicant>(
      Applicant,
    );
  }
}
