import { PayCycleType } from 'constants/constants';
import { observable } from 'mobx';
import { ModelList } from 'models/ModelList';
import { PayrollStore } from 'stores/PayrollStore';
import Model from '../Model';
import { PayrollDetail } from './PayrollDetail';
import { QueuedDocumentUploadStatus } from './QueuedDoc';

export class Payroll extends Model {
  static _store: PayrollStore;

  @observable id: number;

  @observable user_id: number;

  @observable source: string;

  @observable company_name: string;

  @observable company_id: string;

  @observable file_url: string;

  @observable source_id: string;

  @observable source_meta: {
    application_id: number;
    book_uuid: string;
    user_id: number;
    employed?: boolean;
  };

  @observable source_token: string;

  @observable createdAt: Date;

  @observable updatedAt: Date;

  @observable status: QueuedDocumentUploadStatus;

  @observable income_details: {
    income: number;
    payCycle: PayCycleType;
    incomeType: PayCycleType;
    annualIncome: number;
    hourlyIncome: number;
    netHourlyRate: number;
    nextExpectedPayDate: string;
  };

  distribution_deposits: any[];

  @observable pay_cycle: PayCycleType;

  @observable polling_attempts: number;

  @observable last_polled_at: string;

  @observable textract_dump: string;

  @observable rejection_reason: string;

  @observable details = new ModelList<PayrollDetail>(PayrollDetail);
}
