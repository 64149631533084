import { action, observable } from 'mobx';
import ApiRoutes from '../routes/api/ApiRoutes';
import { PaginatedModelList } from '../models/PaginatedModelList';
import { City } from '../models/entities/CityModel';
import Store from './Store';
import { ModelItem } from '../models/ModelItem';

export class CityStore extends Store<City> {
  constructor() {
    super();
    City._store = this;
  }

  @observable cityPaginatedList = new PaginatedModelList<City>(City);

  @observable cityItem = new ModelItem<City>(City);

  @action
  async fetchCities(params?: { [key: string]: any }, forceRefresh?: boolean) {
    return this.cityPaginatedList.load(ApiRoutes.cities.list, params, {
      forceRefresh,
    });
  }

  @action
  async fetchCityById(cityId: number, appendIntoStore = false) {
    const promise = this.cityItem.load(ApiRoutes.cities.show(cityId));
    if (appendIntoStore) {
      promise.then(() => {
        const alreadyPresent = this.cityPaginatedList.items.find(item => item.id === this.cityItem.item?.id);
        if (!alreadyPresent) {
          this.cityPaginatedList.appendItem(this.cityItem.item);
        }
      });
    }
    return promise;
  }

  @action
  clearStore(): void {
    this.cityPaginatedList = new PaginatedModelList<City>(City);
  }
}
