import { action } from 'mobx';
import Store from './Store';
import { BuildingImage } from '../models/entities/BuildingImage';

export class BuildingImageStore extends Store<BuildingImage> {
  constructor() {
    super();
    BuildingImage._store = this;
  }

  @action
  clearStore(): void {}
}
