import { action, observable } from 'mobx';
import { Insurance } from 'models/entities/Insurance';
import ApiRoutes from 'routes/api/ApiRoutes';
import { notification } from 'antd';
import type { Question, Quote } from 'pages/insurance/insurenceType';
import {
  DiscloserTypes,
  INSURANCE_STEPS,
  setNextStep,
  setPreviousStep,
} from 'pages/insurance/assurant-flow/constants';
import Store from './Store';

export class InsuranceStore extends Store<Insurance> {
  @observable private _currentStep: INSURANCE_STEPS = INSURANCE_STEPS.QUESTIONS;

  @observable private _completedStep: INSURANCE_STEPS;

  @observable loadedQuestions: boolean;

  @observable stepError: any;

  @observable uwQuestions: Question[];

  @observable uwAnswers;

  @observable quote: Quote;

  @observable loading = false;

  @observable applicationInsurance: Insurance;

  @observable fraudDiscloser: {
    messageText: string;
    content: string;
    documentName: string;
  };

  @observable payDiscloser: {
    messageText: string;
    content: string;
    documentName: string;
  };

  @observable cancellationNotice: {
    messageText: string;
    content: string;
    documentName: string;
  };

  @observable ebcDiscloser: {
    messageText: string;
    content: string;
    documentName: string;
  };

  @observable discloserLoading: boolean;

  get currentStep(): INSURANCE_STEPS {
    return this._currentStep;
  }

  get belonging(): string {
    return this.quote?.coverageDetails?.parameters?.find(
      (param) => param.name === 'personalPropertyAmount',
    )?.value;
  }

  get deductible(): string {
    return this.quote?.coverageDetails?.parameters?.find(
      (param) => param.name === 'deductible',
    )?.value;
  }

  get personalLiability(): string {
    return this.quote?.coverageDetails?.parameters?.find(
      (param) => param.name === 'liabilityAmount',
    )?.value;
  }

  @action
  setUwAnswers(answers): void {
    this.uwAnswers = answers;
  }

  @action setCurrentStep(
    step: INSURANCE_STEPS,
    next?: boolean,
    previous?: boolean,
  ): void {
    let updatedStep = step;
    if (next) {
      updatedStep = setNextStep(step);
    } else if (previous) {
      updatedStep = setPreviousStep(step);
    }
    this._currentStep = updatedStep;
  }

  get completedStep(): INSURANCE_STEPS {
    return this._completedStep;
  }

  @action setCompletedStep(
    step: INSURANCE_STEPS,
    next?: boolean,
    previous?: boolean,
  ): void {
    let updatedStep = step;
    if (next) {
      updatedStep = setNextStep(step);
    } else if (previous) {
      updatedStep = setPreviousStep(step);
    }
    this._completedStep = updatedStep;
  }

  @action
  async getApplicationInsuranceDetails(applicationId: number): Promise<void> {
    this.loading = true;
    try {
      const response = await this.apiService.get(
        ApiRoutes.insurance.getInsuranceDetail(applicationId),
      );
      if (response.data) {
        this.applicationInsurance = response.data;
      } else {
        this._currentStep = INSURANCE_STEPS.QUESTIONS;
        this._completedStep = INSURANCE_STEPS.CONFIRM_DETAILS;
      }
    } catch (error) {
      notification.error({
        message: error.responseMessage ?? error.message,
        duration: 3,
      });
    } finally {
      this.loading = false;
    }
  }

  @action
  async getUWQuestions(application_id: number): Promise<void> {
    this.loading = true;
    try {
      const response = await this.apiService.get(
        ApiRoutes.insurance.uwQuestions(+application_id),
      );
      if (response?.data?.questions?.length > 0) {
        this.uwQuestions = response.data?.questions;
      } else {
        notification.error({
          message:
            response.data.messageText ??
            response.message ??
            'something is wrong',
          duration: 3,
        });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: error.responseMessage ?? error.message,
        duration: 3,
      });
    } finally {
      this.loading = false;
      this.loadedQuestions = true;
    }
  }

  async getQuote(
    data: {
      policyEffectiveDate;
      questions: {
        questionName: string;
        answer: string;
      }[];
      coverages: { name: string; value: string }[];
      step: INSURANCE_STEPS;
      workItemId?: string;
    },
    application_id: number,
  ): Promise<void> {
    this.loading = true;
    try {
      const response = await this.apiService.post(
        ApiRoutes.insurance.getQuote(application_id),
        data,
      );
      if (
        response.data?.quote &&
        !response.data?.quote?.transactionDetails?.responseMessage
      ) {
        this.quote = response.data.quote;
        this.applicationInsurance = response.data.applicationInsurance;
        if (!data.workItemId) {
          this.setCompletedStep(INSURANCE_STEPS.CONFIRM_DETAILS, true);
          this.setCurrentStep(INSURANCE_STEPS.QUESTIONS, true);
        }
      } else {
        notification.error({
          message:
            response?.data?.quote?.transactionDetails?.responseMessage ??
            response.data.quote?.messageText ??
            response.message ??
            'quote failed',
          duration: 3,
        });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: error.responseMessage ?? error.message,
        duration: 3,
      });
    } finally {
      this.loading = false;
    }
  }

  async selectPaymentPlan(
    data: {
      paymentPlan: string;
      step: INSURANCE_STEPS;
    },
    application_id: number,
  ): Promise<void> {
    this.loading = true;
    try {
      const response = await this.apiService.post(
        ApiRoutes.insurance.playmentPlan(application_id),
        data,
      );
      if (response.data) {
        this.applicationInsurance = response.data;
        this.setCompletedStep(INSURANCE_STEPS.QUOTE, true);
        this.setCurrentStep(INSURANCE_STEPS.PAYMENT_PLANS, true);
      } else {
        notification.error({
          message: response?.data?.messageText,
          duration: 3,
        });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: error.responseMessage ?? error.message,
        duration: 3,
      });
    } finally {
      this.loading = false;
    }
  }

  async getPaymentDetails(
    data: {
      workItemId: string;
      cardDetails: {
        cardType: string;
        cardNumber: string;
        cardCvc: string;
        cardExpMonth: string;
        cardExpYear: string;
        cardHolder: string;
      };
      step: INSURANCE_STEPS;
      hasInterestedParty?: string;
    },
    application_id: number,
  ): Promise<void> {
    this.loading = true;
    try {
      const response = await this.apiService.post(
        ApiRoutes.insurance.playmentDetails(application_id),
        data,
      );

      if (response.data.transactionDetails.responseMessage === 'Success') {
        this.applicationInsurance.payment_token =
          response.data.transactionDetails.tokenizePayment;
        this.applicationInsurance.responses = {
          ...this.applicationInsurance.responses,
          cardDetails: data.cardDetails,
          hasInterestedParty: data.hasInterestedParty,
        };
        this.setCompletedStep(INSURANCE_STEPS.PAYMENT_PLANS, true);
        this.setCurrentStep(INSURANCE_STEPS.CHECK_OUT, true);
      } else {
        notification.error({
          message: response?.data?.messageText ?? response.message,
          duration: 3,
        });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: error.responseMessage ?? error.message,
        duration: 3,
      });
    } finally {
      this.loading = false;
    }
  }

  async getPolicy(
    data: {
      workItemId: string;
      cardDetails: { numberOfPayments: number; paymentAmount: string };
      canText: boolean;
      isPaperLess: boolean;
      hasInterestedParty: boolean;
    },
    application_id: number,
  ): Promise<void> {
    this.loading = true;
    try {
      const response = await this.apiService.post(
        ApiRoutes.insurance.getPolicy(application_id),
        data,
      );

      if (response.data.transactionDetails.policyId) {
        this.applicationInsurance.responses = {
          ...this.applicationInsurance.responses,
          policy: response.data,
        };
        this.setCompletedStep(INSURANCE_STEPS.CHECK_OUT, true);
        this.setCurrentStep(INSURANCE_STEPS.PREVIEW, true);
      } else {
        notification.error({
          message:
            response.data.transactionDetails?.responseMessage ??
            response.message,
          duration: 3,
        });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: error.responseMessage ?? error.message,
        duration: 3,
      });
    } finally {
      this.loading = false;
    }
  }

  async getDiscloserMessage(
    application_id: number,
    type: string,
  ): Promise<void> {
    this.discloserLoading = true;
    try {
      const response = await this.apiService.get(
        ApiRoutes.insurance.getDiscloserMessage(application_id, type),
      );

      if (response.data.messageText === 'Success') {
        if (type === DiscloserTypes.FRAUD) {
          this.fraudDiscloser = response.data;
        } else if (type === DiscloserTypes.ELECTRONIC_BUSSINESS_CONSENT) {
          this.ebcDiscloser = response.data;
        } else if (type === DiscloserTypes.PAY_AUTHORIZATION) {
          this.payDiscloser = response.data;
        } else if (type === DiscloserTypes.CANCELATION_NOTICE) {
          this.cancellationNotice = response.data;
        }
      } else {
        notification.error({
          message: response.data?.responseMessage ?? response.message,
          duration: 3,
        });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: error.responseMessage ?? error.message,
        duration: 3,
      });
    } finally {
      this.discloserLoading = false;
    }
  }

  @action setStepError = (e: any): void => {
    this.stepError = e;
  };

  @action
  clearStore(): void {
    this._currentStep = null;
    this.stepError = null;
    this.applicationInsurance = null;
    this.uwAnswers = null;
    this.loadedQuestions = false;
    this.uwAnswers = null;
    this.fraudDiscloser = null;
    this.ebcDiscloser = null;
    this.payDiscloser = null;
    this.cancellationNotice = null;
    this.quote = undefined;
    this.uwQuestions = undefined;
  }
}
