import { SystemLogs } from 'models/entities/SystemLogsModel';
import Store from './Store';
import { action, observable } from 'mobx';
import { PaginatedModelList } from 'models/PaginatedModelList';
import ApiRoutes from 'routes/api/ApiRoutes';

export class SystemLogsStore extends Store<SystemLogs> {
  constructor() {
    super();
    SystemLogs._store = this;
  }

  @observable loading = false;

  @observable loaded = false;

  @observable systemLogsPaginatedList = new PaginatedModelList<SystemLogs>(
    SystemLogs,
  );

  @action setLoading(loading: boolean) {
    this.loading = loading;
  }

  @action setLoaded(loaded: boolean) {
    this.loaded = loaded;
  }

  @action async fetchSystemLogs(
    params: { [key: string]: any },
    forceRefresh?: boolean,
  ): Promise<void> {
    try {
      const res = await this.systemLogsPaginatedList.load(
        ApiRoutes.system.log.logs,
        params,
        { forceRefresh },
      );
    } catch (e) {
      console.log(e);
    }
  }

  @action
  clearStore(): void {
    this.systemLogsPaginatedList = new PaginatedModelList<SystemLogs>(
      SystemLogs,
    );
    this.setLoaded(false);
    this.setLoading(false);
  }
}
