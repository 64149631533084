/* eslint-disable import/no-cycle */
import { observable } from 'mobx';
import Model from 'models/Model';
import {
  HealthCheckStatus,
  HealthStatusType,
} from 'pages/app/system/HealthCheck/constants';

import { IntegrationHealthStatusStore } from '../../stores/IntegrationHeathStatusStore';

export class IntegrationHealthStatus extends Model {
  static _store: IntegrationHealthStatusStore;

  @observable id: number;

  @observable last_check_attempt: string;

  @observable name: string;

  @observable meta: JSON;

  @observable status: HealthCheckStatus;

  @observable health_status: HealthStatusType;

  @observable createdAt: string;

  @observable updatedAt: string;
}
