import { message } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import {
  creditScoreRangeArray,
  ENVIRONMENT,
  HALO_ORG,
  HPA_ORG,
  IMAGE_PLACEHOLDER,
  MATRIX_ORG,
  MAX_FILE_SIZE_FOR_UPLOAD,
  MAX_FILE_SIZE_FOR_UPLOAD_IN_STRING,
  MCENEARNEY_ORG,
  MORGAN_ORG,
} from 'constants/constants';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { parsePhoneNumber } from 'react-phone-number-input';
import { capitalize } from 'lodash';
import { ApplicationStep } from 'models/ApplicationStep';
import { Unit } from 'models/entities/UnitModel';
import { UserRole } from 'models/entities/UserModel';
import moment from 'moment';
import { RcFile } from 'rc-upload/lib/interface';
import WebRoutes from 'routes/web/WebRoutes';
import { Application } from 'models/entities/ApplicationModel';
import { Applicant } from 'models/entities/ApplicantModel';
import { REACT_APP_ENV } from './secrets';
import { DataFinitiPropertyDetail } from 'interfaces/DataFinitiPropertyDetail.interface';

export const preventDefault = (fn: Function) => {
  return (e: any) => {
    e?.preventDefault?.();
    fn?.(e);
  };
};

export const stopPropagation = (fn: Function) => {
  return (e: any): void => {
    e?.stopPropagation?.();
    fn?.(e);
  };
};

export const titleCase = (str: string): string => {
  return str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
};

export const addIncludesToParams = (
  params: { [key: string]: any },
  include: any[],
): { [key: string]: any } => {
  let _params = params;
  if (include && include.length > 0) {
    _params = {
      ..._params,
      include: include.join(','),
    };
  }
  return _params;
};

export const getFullAddress = (
  address_line_1: string,
  address_line_2: string,
  city: string,
  state: string,
  zip_code: string | number,
  country?: string,
  // phone_number?: string,
): string => {
  const fullAddress = [];

  if (address_line_1) {
    fullAddress.push(address_line_1);
  }

  if (address_line_2) {
    fullAddress.push(address_line_2);
  }

  if (city) {
    fullAddress.push(city);
  }

  if (state) {
    fullAddress.push(state);
  }

  if (zip_code) {
    fullAddress.push(zip_code);
  }

  if (country) {
    fullAddress.push(country);
  }

  // if (phone_number) {
  //   fullAddress.push(phone_number);
  // }

  const result = fullAddress.join(', ');
  if (result === 'null') {
    return '';
  }
  return result;
};

export const getBase64 = (file: any): Promise<string | ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const removeObjectEmptyValues = (obj: {
  [key: string]: any;
}): { [key: string]: any } => {
  return Object.keys(obj)
    .filter((k) => obj[k] != null && obj[k] !== '')
    .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
};

export const validateEmail = (email: string): boolean => {
  const emailRE = /\b[\w\.\-\_]+@[\w\.\-]+\.\w{2,4}\b/gy;
  return emailRE.test(email);
};

export const validateInternalEmail = (email: string): boolean => {
  const emailRE = /\b[\w\.\+\-\_]+@[\w\.\-]+\.\w{2,4}\b/gy;
  return emailRE.test(email);
};

export const getArrayFilter = (
  params: URLSearchParams,
  filter: string,
): string[] => {
  const rawValue = params.get(filter) || '';

  if (!rawValue) {
    return [];
  }

  return rawValue.split(',') as any[];
};

export const canAccessStep = (
  currentStep: ApplicationStep,
  desiredStep: ApplicationStep,
): boolean => {
  const _map: { [currentStep: string]: ApplicationStep[] } = {
    [ApplicationStep.LANDING]: [],
    [ApplicationStep.TERMS_AND_CONDITIONS]: [ApplicationStep.LANDING],
    [ApplicationStep.CO_APPLICANTS_AND_GUARANTOR]: [
      ApplicationStep.LANDING,
      ApplicationStep.TERMS_AND_CONDITIONS,
    ],
    [ApplicationStep.GOVERNMENT_IDENTIFICATION]: [
      ApplicationStep.LANDING,
      ApplicationStep.TERMS_AND_CONDITIONS,
      ApplicationStep.CO_APPLICANTS_AND_GUARANTOR,
    ],
    [ApplicationStep.YOUR_INFORMATION]: [
      ApplicationStep.LANDING,
      ApplicationStep.TERMS_AND_CONDITIONS,
      ApplicationStep.CO_APPLICANTS_AND_GUARANTOR,
      ApplicationStep.GOVERNMENT_IDENTIFICATION,
    ],
    [ApplicationStep.PHA_VOUCHER]: [
      ApplicationStep.LANDING,
      ApplicationStep.TERMS_AND_CONDITIONS,
      ApplicationStep.CO_APPLICANTS_AND_GUARANTOR,
      ApplicationStep.GOVERNMENT_IDENTIFICATION,
      ApplicationStep.YOUR_INFORMATION,
    ],
    [ApplicationStep.PAYMENT_DETAILS]: [
      ApplicationStep.LANDING,
      ApplicationStep.TERMS_AND_CONDITIONS,
      ApplicationStep.CO_APPLICANTS_AND_GUARANTOR,
      ApplicationStep.GOVERNMENT_IDENTIFICATION,
      ApplicationStep.YOUR_INFORMATION,
      ApplicationStep.PHA_VOUCHER,
    ],
    [ApplicationStep.CREDIT_REPORTING_SERVICE]: [
      ApplicationStep.LANDING,
      ApplicationStep.TERMS_AND_CONDITIONS,
      ApplicationStep.CO_APPLICANTS_AND_GUARANTOR,
      ApplicationStep.GOVERNMENT_IDENTIFICATION,
      ApplicationStep.YOUR_INFORMATION,
      ApplicationStep.PHA_VOUCHER,
      ApplicationStep.PAYMENT_DETAILS,
    ],
    [ApplicationStep.BANK_STATEMENTS]: [
      ApplicationStep.LANDING,
      ApplicationStep.TERMS_AND_CONDITIONS,
      ApplicationStep.CO_APPLICANTS_AND_GUARANTOR,
      ApplicationStep.GOVERNMENT_IDENTIFICATION,
      ApplicationStep.YOUR_INFORMATION,
      ApplicationStep.PHA_VOUCHER,
      ApplicationStep.PAYMENT_DETAILS,
      ApplicationStep.CREDIT_REPORTING_SERVICE,
    ],
    [ApplicationStep.EMPLOYMENT_INFORMATION]: [
      ApplicationStep.LANDING,
      ApplicationStep.TERMS_AND_CONDITIONS,
      ApplicationStep.CO_APPLICANTS_AND_GUARANTOR,
      ApplicationStep.GOVERNMENT_IDENTIFICATION,
      ApplicationStep.YOUR_INFORMATION,
      ApplicationStep.PHA_VOUCHER,
      ApplicationStep.PAYMENT_DETAILS,
      ApplicationStep.CREDIT_REPORTING_SERVICE,
      ApplicationStep.BANK_STATEMENTS,
    ],
    [ApplicationStep.TAX_DOCUMENTS]: [
      ApplicationStep.LANDING,
      ApplicationStep.TERMS_AND_CONDITIONS,
      ApplicationStep.CO_APPLICANTS_AND_GUARANTOR,
      ApplicationStep.GOVERNMENT_IDENTIFICATION,
      ApplicationStep.YOUR_INFORMATION,
      ApplicationStep.PHA_VOUCHER,
      ApplicationStep.PAYMENT_DETAILS,
      ApplicationStep.CREDIT_REPORTING_SERVICE,
      ApplicationStep.BANK_STATEMENTS,
      ApplicationStep.EMPLOYMENT_INFORMATION,
    ],
    [ApplicationStep.RESIDENCE_HISTORY]: [
      ApplicationStep.LANDING,
      ApplicationStep.TERMS_AND_CONDITIONS,
      ApplicationStep.PHA_VOUCHER,
      ApplicationStep.CO_APPLICANTS_AND_GUARANTOR,
      ApplicationStep.GOVERNMENT_IDENTIFICATION,
      ApplicationStep.YOUR_INFORMATION,
      ApplicationStep.PAYMENT_DETAILS,
      ApplicationStep.CREDIT_REPORTING_SERVICE,
      ApplicationStep.BANK_STATEMENTS,
      ApplicationStep.EMPLOYMENT_INFORMATION,
      ApplicationStep.TAX_DOCUMENTS,
    ],
    [ApplicationStep.SUBMISSION]: [
      ApplicationStep.LANDING,
      ApplicationStep.TERMS_AND_CONDITIONS,
      ApplicationStep.PHA_VOUCHER,
      ApplicationStep.CO_APPLICANTS_AND_GUARANTOR,
      ApplicationStep.GOVERNMENT_IDENTIFICATION,
      ApplicationStep.YOUR_INFORMATION,
      ApplicationStep.PAYMENT_DETAILS,
      ApplicationStep.CREDIT_REPORTING_SERVICE,
      ApplicationStep.BANK_STATEMENTS,
      ApplicationStep.EMPLOYMENT_INFORMATION,
      ApplicationStep.TAX_DOCUMENTS,
      ApplicationStep.RESIDENCE_HISTORY,
    ],
    [ApplicationStep.CONFIRM_DETAILS]: [],
  };

  if (currentStep === desiredStep) {
    return true;
  }

  const allowedSteps = _map[currentStep];

  if (allowedSteps.includes(desiredStep)) {
    return true;
  }

  return false;
};

export const getRentalApplicationStepUrl = (
  step: ApplicationStep | string,
): string => {
  const _map: { [currentStep: string]: string } = {
    [ApplicationStep.LANDING]: WebRoutes.app.upload.onboarding,
    [ApplicationStep.TERMS_AND_CONDITIONS]: WebRoutes.app.upload.terms,
    [ApplicationStep.GOVERNMENT_IDENTIFICATION]: WebRoutes.app.upload.govtId,
    [ApplicationStep.YOUR_INFORMATION]: WebRoutes.app.upload.yourInformation,
    [ApplicationStep.PHA_VOUCHER]: WebRoutes.app.upload.phaVoucher,
    [ApplicationStep.CO_APPLICANTS_AND_GUARANTOR]:
      WebRoutes.app.upload.coapplicants,
    [ApplicationStep.PAYMENT_DETAILS]: WebRoutes.app.upload.paymentDetails,
    [ApplicationStep.CREDIT_REPORTING_SERVICE]: WebRoutes.app.upload.crs,
    [ApplicationStep.BANK_STATEMENTS]: WebRoutes.app.upload.bankStatements,
    [ApplicationStep.EMPLOYMENT_INFORMATION]:
      WebRoutes.app.upload.employmentInformation,
    [ApplicationStep.TAX_DOCUMENTS]: WebRoutes.app.upload.taxReturns,
    [ApplicationStep.RESIDENCE_HISTORY]: WebRoutes.app.upload.residenceHistory,
    [ApplicationStep.SUBMISSION]: WebRoutes.app.upload.submission,
  };

  return _map[step];
};

export const getRenterStepFromStepUrlEndPoint = (
  urlEndPoint: string,
): ApplicationStep => {
  const _map = {
    [WebRoutes.app.upload.onboarding.split('/').pop()]: ApplicationStep.LANDING,
    [WebRoutes.app.upload.terms
      .split('/')
      .pop()]: ApplicationStep.TERMS_AND_CONDITIONS,
    [WebRoutes.app.upload.govtId
      .split('/')
      .pop()]: ApplicationStep.GOVERNMENT_IDENTIFICATION,
    [WebRoutes.app.upload.yourInformation
      .split('/')
      .pop()]: ApplicationStep.YOUR_INFORMATION,
    [WebRoutes.app.upload.phaVoucher
      .split('/')
      .pop()]: ApplicationStep.PHA_VOUCHER,
    [WebRoutes.app.upload.coapplicants
      .split('/')
      .pop()]: ApplicationStep.CO_APPLICANTS_AND_GUARANTOR,
    [WebRoutes.app.upload.paymentDetails
      .split('/')
      .pop()]: ApplicationStep.PAYMENT_DETAILS,
    [WebRoutes.app.upload.crs
      .split('/')
      .pop()]: ApplicationStep.CREDIT_REPORTING_SERVICE,
    [WebRoutes.app.upload.bankStatements
      .split('/')
      .pop()]: ApplicationStep.BANK_STATEMENTS,
    [WebRoutes.app.upload.employmentInformation
      .split('/')
      .pop()]: ApplicationStep.EMPLOYMENT_INFORMATION,
    [WebRoutes.app.upload.taxReturns
      .split('/')
      .pop()]: ApplicationStep.TAX_DOCUMENTS,
    [WebRoutes.app.upload.residenceHistory
      .split('/')
      .pop()]: ApplicationStep.RESIDENCE_HISTORY,
    [WebRoutes.app.upload.submission
      .split('/')
      .pop()]: ApplicationStep.SUBMISSION,
  };

  return _map[urlEndPoint];
};

const checkForImageExtentions = (data: string) => {
  if (!data) {
    return false;
  }

  const extentions: string[] = [
    '.jpg',
    '.jpeg',
    '.png',
    '.svg',
    '.jfif',
    '.pjpeg',
    '.pjp',
    '.webp',
    '.apng',
    '.avif',
    '.gif',
  ];
  return extentions.some((extention) => data.toLowerCase().includes(extention));
};

export const checkForImage = (url: string, title?: string): boolean => {
  if (url && checkForImageExtentions(url)) {
    return true;
  }
  if (title && checkForImageExtentions(title)) {
    return true;
  }
  return false;
};

export const getGoogleViewUrl = (url: string, title?: string): string => {
  return checkForImage(url, title)
    ? url
    : `https://drive.google.com/viewerng/viewer?embedded=true&url=${url}`;
};

export const isOrgHalo = (unit?: Unit, organisationId?: number): boolean => {
  if (unit?.organisation_id === HALO_ORG || organisationId === HALO_ORG) {
    return true;
  }
  return false;
};

export const isMatrixOrg = (unit: Unit): boolean => {
  return unit?.organisation_id === MATRIX_ORG;
};

export const isOrgHPA = (unit?: Unit, organisationId?: number): boolean => {
  if (unit?.organisation_id === HPA_ORG || organisationId === HPA_ORG) {
    return true;
  }
  return false;
};

export const isOrgMatrix = (unit?: Unit, organisationId?: number): boolean =>
  unit?.organisation_id === MATRIX_ORG || organisationId === MATRIX_ORG;

export const isOrgMcEnearney = (
  unit?: Unit,
  organisationId?: number,
): boolean => {
  return (
    unit?.organisation_id === MCENEARNEY_ORG ||
    organisationId === MCENEARNEY_ORG
  );
};

export const isOrgMorgan = (unit?: Unit, organisationId?: number): boolean => {
  return unit?.organisation_id === MORGAN_ORG || organisationId === MORGAN_ORG;
};

export const isOrgHaloType = (
  unit?: Unit,
  organisationId?: number,
): boolean => {
  const orgId = unit?.organisation_id || organisationId;
  if ([HPA_ORG, HALO_ORG].includes(orgId)) {
    return true;
  }
  return false;
};

const phoneUtil = PhoneNumberUtil.getInstance();

export const isValidUsPhoneNo = (phoneNo: string): boolean => {
  if (!phoneNo || phoneNo?.length < 10) {
    return false;
  }

  try {
    return phoneUtil.isValidNumberForRegion(
      phoneUtil.parse(phoneNo, 'US'),
      'US',
    );
  } catch (e) {
    console.error(e);
    return false;
  }
};

// This function expect number with region code
export const isValidPhoneNo = (phoneNo: string): boolean => {
  if (!phoneNo || phoneNo?.length < 12) {
    return false;
  }

  return !!parsePhoneNumber(phoneNo)?.country;
};

export const getPhoneCodeAndNumber = (
  phoneNo: string,
): { phone_number: string; code: string } => {
  const phone = parsePhoneNumber(phoneNo);

  return { phone_number: phone.nationalNumber, code: phone.countryCallingCode };
};

export const isProdEnvironment = REACT_APP_ENV === ENVIRONMENT.PRODUCTION;

export const allowedFileTypes = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'application/pdf',
];

export const getAllowedFiles = (
  fileList: UploadFile[],
  allowedExtensions = ['jpeg', 'jpg', 'png', 'pdf'],
): UploadFile[] => {
  const allowedFiles: UploadFile[] = [];
  for (const file of fileList) {
    const isValidFormat =
      allowedExtensions.includes(file.name.split('.').pop()?.toLowerCase()) &&
      isFileSizePermissible(file);
    if (isValidFormat || file.url) {
      allowedFiles.push(file);
    }
  }

  return allowedFiles;
};

export const getRoleNameByRoleId = (
  role_id: UserRole,
  isMCEOrg = false,
): string => {
  if (isMCEOrg) {
    if (role_id === UserRole.MANAGER) return 'Admin';
    if (role_id === UserRole.AGENT) return 'Manager';
    if (role_id === UserRole.ASSOCIATE_AGENT) return 'Agent';
  }
  return capitalize(
    Object.keys(UserRole).find((key) => UserRole[key] === role_id),
  );
};

export const getFormattedDate = (date: string): string =>
  moment.utc(date).local().format('MM/DD/YYYY hh:mm:ss A');

export const getFormattedDateWithoutTime = (date: string): string =>
  moment.utc(date).local().format('MM/DD/YYYY');

export const getTimeStampOfDate = (date: string): number =>
  moment(getFormattedDateWithoutTime(date)).unix();

// removing extra spaces throughout
export const getCleanString = (str: string): string => {
  const newStr = str.trim();
  if (!newStr.includes('  ')) {
    return newStr;
  }
  return getCleanString(newStr.replace('  ', ' '));
};

export const formatPhoneNumber = (str = '', countryCode = '+1'): string => {
  if (str === '' || !str) {
    return '---';
  }

  const numberWithoutCountryCode = str.slice(-10);
  const _countryCode = str.slice(0, -10);

  const _str = `${numberWithoutCountryCode.slice(
    0,
    3,
  )}-${numberWithoutCountryCode.slice(3, 6)}-${numberWithoutCountryCode.slice(
    6,
  )}`;
  return `${_countryCode}-${_str}`;
};

export const formatPhoneNumberUsingLib = (phoneNumber = ''): string => {
  if (phoneNumber === '' || !phoneNumber) return '---';

  try {
    const _phone = parsePhoneNumber(phoneNumber);
    return _phone.format('NATIONAL');
  } catch (e) {
    return '---';
  }
};

export const getCleanInteger = (str: string): number => {
  let numberString = '0';
  if (!str) {
    return Number(numberString);
  }
  // remove all spaces and split characters
  const characterTokens = str?.trim().replace(' ', '').split('');
  characterTokens.forEach((char) => {
    if (!Number.isNaN(+char) || char === '.') {
      numberString += char;
    }
  });

  return Number(numberString);
};

export const getUnitFullAddress = (unit, building): string => {
  const address = [
    unit.name,
    building.address_line_1,
    building.address_line_2,
    building.city_name,
    building.state_code,
    building.zip_code,
  ];
  return address.filter((a) => !!a).join(', ');
};

export const getCapitalizeString = (str: string): string => {
  return str ? str[0].toUpperCase() + str.slice(1) : str;
};

export const isFileSizePermissible = (
  file: RcFile | File | UploadFile,
  maxSize = MAX_FILE_SIZE_FOR_UPLOAD,
): boolean => {
  if (!file) {
    return false;
  }
  return file?.size < maxSize;
};

export const isFileHasContent = (file: RcFile | File | UploadFile): boolean => {
  if (!file) {
    return false;
  }
  return file?.size > 0;
};

export const showFileSizeError = (
  maxSize = MAX_FILE_SIZE_FOR_UPLOAD_IN_STRING,
): void => {
  message.error(`File size should not exceed ${maxSize}`);
};
const isFileEncrypted = async (file: any): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (file.originFileObj) {
      reader.readAsArrayBuffer(file.originFileObj as RcFile);
    } else {
      reader.readAsArrayBuffer(file as RcFile);
    }
    reader.onload = () => {
      const files = new Blob([reader.result], { type: 'application/pdf' });
      files.text().then((x) => {
        const encrypted =
          x.includes('Encrypt') &&
          x
            .substring(x.lastIndexOf('<<'), x.lastIndexOf('>>'))
            .includes('/Encrypt');
        resolve(encrypted);
      });
    };
  });
};

export const isValidFile = async (
  file: any,
  allowedFileExtensions: string[],
  skipMessages = false,
): Promise<boolean> => {
  if (!file.type) {
    return true;
  }
  const isValidFormat = allowedFileExtensions.includes(
    file?.type?.split('/')[1],
  );
  const fileSizePermissible = isFileSizePermissible(file);
  if (!fileSizePermissible && !skipMessages) {
    showFileSizeError();
  }
  const encrypted = await isFileEncrypted(file);
  const fileHasContent = isFileHasContent(file);

  if (!fileHasContent && !skipMessages && !file?.originFileObj) {
    message.error(
      `Invalid file ${file.name}. Please try uploading another file.`,
    );
  }

  if (!isValidFormat && !skipMessages) {
    message.error(
      `Supported file formats are ${allowedFileExtensions
        .map((_ext) => `.${_ext}`)
        .join(', ')}`,
    );
  }
  if (encrypted && !file?.originFileObj) {
    message.error('Cannot upload a password protected file');
  }
  return isValidFormat && fileSizePermissible && !encrypted && fileHasContent;
};

export const getUnitBedroomBathroom = (unit: Unit): string => {
  let bedBathCount = '- / -';
  if (unit?.bedrooms === 0) {
    bedBathCount = 'Studio';
  } else if (unit?.bedrooms > 0) {
    bedBathCount = `${unit.bedrooms} bd`;
  }

  if (unit?.bathrooms) {
    bedBathCount = `${bedBathCount} / ${unit.bathrooms} ba`;
  }

  return bedBathCount;
};

export const getPropertyBedroomBathroom = (
  property: DataFinitiPropertyDetail,
) => {
  let bedBathCount = '- / -';
  if (
    property?.num_of_bedrooms !== null &&
    property?.num_of_bathrooms !== null
  ) {
    bedBathCount = `${property.num_of_bedrooms} bd / ${property.num_of_bathrooms} ba`;
  } else if (
    property?.num_of_bathrooms === null &&
    property?.num_of_bedrooms !== null
  ) {
    bedBathCount = `${property.num_of_bedrooms} bd / - `;
  } else if (
    property?.num_of_bedrooms === null &&
    property?.num_of_bathrooms !== null
  ) {
    bedBathCount = ` - / ${property.num_of_bathrooms} ba`;
  }

  return bedBathCount;
};

export const getUnitFirstImageUrl = (unit: Unit): string => {
  if (unit?.unit_images?.items?.length > 0) {
    return unit?.unit_images?.items[0]?.url;
  }
  if (unit?.building?.item?.building_images?.items?.length > 0) {
    return unit?.building?.item?.building_images?.items[0]?.url;
  }

  return IMAGE_PLACEHOLDER;
};

export const getUnitAllImageUrls = (unit: Unit): string[] => {
  const urls: string[] = [];
  unit?.unit_images?.items?.forEach((unitImage) => urls.push(unitImage?.url));
  unit?.building?.item?.building_images?.items?.forEach((buildingImage) =>
    urls.push(buildingImage?.url),
  );

  if (urls.length === 0) {
    urls.push(IMAGE_PLACEHOLDER);
  }

  return urls;
};

export const getDatafinitPropertyAllImageUrls = (
  property: DataFinitiPropertyDetail,
) => {
  const urls = [];
  if (property.images) {
    property.images.forEach((image) => urls.push(image.url));
  }

  if (urls.length === 0) {
    urls.push(IMAGE_PLACEHOLDER);
  }
  return urls;
};

export const getMaskedPhoneNumber = (val: string): string =>
  val.replace(/\D+/g, '').replace(/^(\d{3})(\d{3})(\d{4}).*/, '$1-$2-$3');

export const getMaskedSSN = (val: string): string =>
  val.replace(/\D+/g, '').replace(/^(\d{3})(\d{2})(\d{4}).*/, '$1-$2-$3');

export const getMaskedEIN = (val: string): string =>
  val.replace(/\D+/g, '').replace(/^(\d{2})(\d{7}).*/, '$1-$2');

export const getPrimaryApplicant = (application: Application): Applicant => {
  let primaryApplicant = application.applicants.items.find(
    (applicant) => applicant.is_primary,
  );
  const { applicants } = application;
  if (!primaryApplicant) {
    const { items } = applicants;
    // eslint-disable-next-line prefer-destructuring
    primaryApplicant = items[0];
  }
  return primaryApplicant;
};

export const getCreditScoreRemark = (score: number): string =>
  capitalize(
    creditScoreRangeArray.find(
      (range) => (range?.high || 900) > score && (range?.low || 0) < score,
    )?.score,
  );

export const getStartDate = (storedDate: string | Date): string => {
  if (moment(storedDate).isBefore(moment().add(1, 'day'))) {
    return moment().add(1, 'days').format('YYYY-MM-DD').toString();
  }
  return (
    moment(storedDate).format('YYYY-MM-DD').toString() ||
    moment().add(1, 'days').format('YYYY-MM-DD').toString()
  );
};

export const concatenateStringsWithSeparator = (
  separator: string,
  ...str: string[]
): string => str.filter((v) => !!v).join(separator);

export const getPlainTextFromHTML = (htmlString: string): string => {
  const document = new DOMParser().parseFromString(htmlString, 'text/html');
  return document.body.innerText;
};
