import { observable } from 'mobx';
import Model from '../Model';
import { PHAStore } from '../../stores/PHAStore';
import { ModelItem } from '../ModelItem';
import { User } from './UserModel';
import type { PHAStepConfiguration } from '../../interfaces/PHAStepConfiguration.interface';

export class PHA extends Model {
  static _store: PHAStore;

  @observable id: number;

  @observable address: string;

  @observable pha_name: string;

  @observable primary_name: string;

  @observable primary_contact: string;

  @observable primary_email: string;

  @observable owner: User;

  @observable address_line_1: string;

  @observable address_line_2: string;

  @observable city_name: string;

  @observable state_code: string;

  @observable zip_code: string;

  @observable step_configuration: PHAStepConfiguration;
}
