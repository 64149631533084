import { AuthStore } from './AuthStore';
import { CityStore } from './CityStore';
import { UserStore } from './UserStore';
import { UnitStore } from './UnitStore';
import { ApplicationStore } from './ApplicationStore';
import { OrganizationStore } from './OrganizationStore';
import { InviteStore } from './InviteStore';
import { StateStore } from './StateStore';
import { BuildingStore } from './BuildingStore';
import { StepStore } from './StepStore';
import { BankStore } from './BankStore';
import { ResidenceHistoryStore } from './ResidenceHistoryStore';
import { DocumentStore } from './DocumentStore';
import { UnitImageStore } from './UnitImageStore';
import { BuildingImageStore } from './BuildingImageStore';
import { PaymentStore } from './PaymentStore';
import { InsuranceStore } from './InsuranceStore';
import { PayrollStore } from './PayrollStore';

import {
  APPLICANT_STORE,
  APPLICATION_STORE,
  APPLICATION_LOGS_STORE,
  AUTH_STORE,
  BANK_STORE,
  BUILDING_IMAGE_STORE,
  BUILDING_STORE,
  CITY_STORE,
  DOCUMENT_STORE,
  INVITE_STORE,
  INSURANCE_STORE,
  ORGANIZATION_STORE,
  PAYMENT_STORE,
  RESIDENCE_HISTORY_STORE,
  STATE_STORE,
  STEP_STORE,
  UNIT_IMAGE_STORE,
  UNIT_STORE,
  USER_STORE,
  GUEST_CARD_STORE,
  GUEST_CARD_CHAT_STORE,
  REFERRAL_STORE,
  PAYROLL_STORE,
  BANK_TRANSACTION_STORE,
  PAYROLL_DETAIL_STORE,
  APPLICATION_INSURANCE_STORE,
  RENT_DETERMINATION_CHAT_STORE,
  SYSTEM_LOGS_STORE,
  INTEGRATION_HEALTH_STATUS,
  PHA_STORE,
  WAITLIST_INVITE_STORE,
  WAITING_LIST_APPLICANT_STORE,
} from '../constants/stores';
import { GuestCardStore } from './GuestCardStore';
import { ApplicantStore } from './ApplicantStore';
import { ApplicationLogsStore } from './ApplicationLogsStore';
import { GuestCardChatStore } from './GuestCardChatStore';
import { ReferralStore } from './ReferralStore';
import { BankTransactionStore } from './BankTransactionStore';
import { PayrollDetailStore } from './PayrollDetails';
import { ApplicationInsuranceStore } from './ApplicationInsuranceStore';
import { RentDeterminationChatStore } from './RentDeterminationChatStore';
import { SystemLogsStore } from './systemLogsStore';
import { IntegrationHealthStatusStore } from './IntegrationHeathStatusStore';
import { PHAStore } from './PHAStore';
import { WaitListInviteStore } from './WaitListInviteStore';
import { WaitingListApplicantStore } from './WaitingListApplicantStore';

let stores = null;

export function createStores() {
  if (!stores) {
    stores = createStoresInstance();
  }
  return stores;
}

export function createStoresInstance() {
  return {
    [AUTH_STORE]: new AuthStore(),
    [APPLICANT_STORE]: new ApplicantStore(),
    [APPLICATION_STORE]: new ApplicationStore(),
    [APPLICATION_INSURANCE_STORE]: new ApplicationInsuranceStore(),
    [APPLICATION_LOGS_STORE]: new ApplicationLogsStore(),
    [BANK_STORE]: new BankStore(),
    [BUILDING_STORE]: new BuildingStore(),
    [BUILDING_IMAGE_STORE]: new BuildingImageStore(),
    [CITY_STORE]: new CityStore(),
    [DOCUMENT_STORE]: new DocumentStore(),
    [INVITE_STORE]: new InviteStore(),
    [INSURANCE_STORE]: new InsuranceStore(),
    [INTEGRATION_HEALTH_STATUS]: new IntegrationHealthStatusStore(),
    [ORGANIZATION_STORE]: new OrganizationStore(),
    [PAYMENT_STORE]: new PaymentStore(),
    [PHA_STORE]: new PHAStore(),
    [REFERRAL_STORE]: new ReferralStore(),
    [RENT_DETERMINATION_CHAT_STORE]: new RentDeterminationChatStore(),
    [RESIDENCE_HISTORY_STORE]: new ResidenceHistoryStore(),
    [STATE_STORE]: new StateStore(),
    [STEP_STORE]: new StepStore(),
    [SYSTEM_LOGS_STORE]: new SystemLogsStore(),
    [UNIT_STORE]: new UnitStore(),
    [GUEST_CARD_STORE]: new GuestCardStore(),
    [GUEST_CARD_CHAT_STORE]: new GuestCardChatStore(),
    [UNIT_IMAGE_STORE]: new UnitImageStore(),
    [USER_STORE]: new UserStore(),
    [PAYROLL_STORE]: new PayrollStore(),
    [BANK_TRANSACTION_STORE]: new BankTransactionStore(),
    [PAYROLL_DETAIL_STORE]: new PayrollDetailStore(),
    [WAITING_LIST_APPLICANT_STORE]: new WaitingListApplicantStore(),
    [WAITLIST_INVITE_STORE]: new WaitListInviteStore(),
  };
}

// Follow alphabetical order
