export const QW_QUESTIONS = {
  dwellingType: {
    question: 'What type of property are you renting?',
    options: [
      { key: 'A', value: 'Apartment/Condo' },
      { key: 'S', value: 'Single Family Home' },
      { key: 'T', value: 'Townhome' },
      { key: 'D', value: 'Dormitory/Student Housing' },
    ],
  },
  manufacturedMobileHome: {
    question: 'Is this a Manufactured/Mobilehome?',
    options: [
      { key: 'Y', value: 'YES' }, // not qualify
      { key: 'N', value: 'NO' },
    ],
    defaultAnswer: 'N',
  },
  mandatoryInsuranceRequirement: {
    question: 'Are you required to have renters insurance?',
    options: [
      { key: 'Y', value: 'YES' },
      { key: 'N', value: 'NO' },
    ],
  },
  losses: {
    question:
      'Excluding storms, floods and other natural causes, have you had any losses in the past three years?',
    options: [
      { key: 'Y', value: 'YES' },
      { key: 'N', value: 'NO' },
    ],
  },
  numberOfLosses: {
    question: 'How many claims?',
    options: [
      { key: '1', value: '1' },
      { key: '2', value: '2 or more' }, // not qualify
    ],
    defaultAnswer: '0',
  },
  lossDate: {
    question: 'What was the Date of Loss?',
    options: [],
    type: 'date',
    defaultAnswer: '',
  },
  animalInjury: {
    question: 'Do You Own or Care For An Animal That Has Harmed Someone?',
    options: [
      { key: 'Y', value: 'YES' }, // not qualify
      { key: 'N', value: 'NO' },
    ],
  },
  coverageMasonry: {
    question: 'Please select the construction type of dwelling',
    options: [
      { key: 'N', value: 'Wood frame' },
      { key: 'Y', value: 'Masonry' },
    ],
  },
  Arson: {
    question:
      'During the last 10 years, has any applicant been formally charged or convicted of any degree of crime or fraud, bribery or arson in connection with this or any other property?',
    options: [
      { key: 'Y', value: 'YES' },
      { key: 'N', value: 'NO' },
    ],
  },
};

export const stepperSteps = [
  'confirm_details',
  'questions',
  'quote',
  'payment_plans',
  'checkout',
  'preview',
  'purchased',
];

export enum INSURANCE_STEPS {
  CONFIRM_DETAILS = 'confirm_details',
  QUESTIONS = 'questions',
  QUOTE = 'quote',
  PAYMENT_PLANS = 'payment_plans',
  CHECK_OUT = 'checkout',
  PREVIEW = 'preview',
  PURCHASED = 'purchased',
}

export const setNextStep = (step: INSURANCE_STEPS): INSURANCE_STEPS => {
  switch (step) {
    case INSURANCE_STEPS.CONFIRM_DETAILS:
      return INSURANCE_STEPS.QUESTIONS;
    case INSURANCE_STEPS.QUESTIONS:
      return INSURANCE_STEPS.QUOTE;
    case INSURANCE_STEPS.QUOTE:
      return INSURANCE_STEPS.PAYMENT_PLANS;
    case INSURANCE_STEPS.PAYMENT_PLANS:
      return INSURANCE_STEPS.CHECK_OUT;
    case INSURANCE_STEPS.CHECK_OUT:
      return INSURANCE_STEPS.PREVIEW;
    case INSURANCE_STEPS.PREVIEW:
      return INSURANCE_STEPS.PURCHASED;
    case INSURANCE_STEPS.PURCHASED:
      return INSURANCE_STEPS.PURCHASED;
    default:
      return INSURANCE_STEPS.QUESTIONS;
  }
};

export const setPreviousStep = (step: INSURANCE_STEPS): INSURANCE_STEPS => {
  switch (step) {
    case INSURANCE_STEPS.QUOTE:
      return INSURANCE_STEPS.QUESTIONS;
    case INSURANCE_STEPS.PAYMENT_PLANS:
      return INSURANCE_STEPS.QUOTE;
    case INSURANCE_STEPS.CHECK_OUT:
      return INSURANCE_STEPS.PAYMENT_PLANS;
    case INSURANCE_STEPS.PREVIEW:
      return INSURANCE_STEPS.CHECK_OUT;
    default:
      return INSURANCE_STEPS.CONFIRM_DETAILS;
  }
};

export const isStepValid = (step: INSURANCE_STEPS): INSURANCE_STEPS[] => {
  switch (step) {
    case INSURANCE_STEPS.QUESTIONS:
      return [INSURANCE_STEPS.QUESTIONS];
    case INSURANCE_STEPS.QUOTE:
      return [INSURANCE_STEPS.QUOTE, INSURANCE_STEPS.QUESTIONS];
    case INSURANCE_STEPS.PAYMENT_PLANS:
      return [
        INSURANCE_STEPS.PAYMENT_PLANS,
        INSURANCE_STEPS.QUESTIONS,
        INSURANCE_STEPS.QUOTE,
      ];
    case INSURANCE_STEPS.CHECK_OUT:
      return [
        INSURANCE_STEPS.QUESTIONS,
        INSURANCE_STEPS.QUOTE,
        INSURANCE_STEPS.PAYMENT_PLANS,
        INSURANCE_STEPS.CHECK_OUT,
      ];
    case INSURANCE_STEPS.PREVIEW:
      return [
        INSURANCE_STEPS.QUESTIONS,
        INSURANCE_STEPS.QUOTE,
        INSURANCE_STEPS.PAYMENT_PLANS,
        INSURANCE_STEPS.CHECK_OUT,
        INSURANCE_STEPS.PREVIEW,
      ];
    case INSURANCE_STEPS.PURCHASED:
      return [INSURANCE_STEPS.PURCHASED];
    default:
      return [];
  }
};

export const LIABILTY_DETAILS = {
  heading: 'Your Liability',
  description:
    "This protects you in case you cause damage to your home or neighboring property because of smoke, fire, water and explosion. And, if a guest gets hurt or bitten by your dog we'll cover necessary medical and legal expenses up to the liability amount",
  options: ['$50000', '$100000'],
};

export const BELONGINGS_DETAILS = {
  heading: 'Your Belongings',
  description:
    "How much would it cost to replace your belongings (e.g. furniture, clothes, electronics)? Select your coverage based on your answer. The amount you choose can cover a roommate's belongings too. Just be sure to add them to your policy at checkout.",
};

export const DEDUCTIBLE_DETAILS = {
  heading: 'DEDUCTIBLE',
  description:
    "A deductible is a lump sum you have to pay upfront, but with us, we'll subtract it from your claim payout. A higher deductible will lower your monthly payment, but also means you'll receive less for a covered loss. For example, if you choose a $250 deductible and you have a table worth $800, we'll give you $550 back.",
  options: ['$100', '$250', '$500'],
};

export const coverageDefaultValues = [
  {
    name: 'hasEarthquake',
    value: 'N',
  },
  {
    name: 'hasIdentityFraud',
    value: 'N',
  },
  {
    name: 'iuiType',
    value: 'N',
  },
  {
    name: 'liabilityAmount',
    value: '100000',
  },
  {
    name: 'personalPropertyAmount',
    value: '15000',
  },
  {
    name: 'hasPetDamage',
    value: 'N',
  },
  {
    name: 'hasReplacementCost',
    value: 'Y',
  },
  {
    name: 'hasSewerBackup',
    value: 'Y',
  },
  {
    name: 'deductible',
    value: '250',
  },
];

export enum QUESTION_KEY_MAPPING {
  dwellingType = 'dwellingType',
  manufacturedMobileHome = 'isManufacturedHome',
  mandatoryInsuranceRequirement = 'mustHaveInsurance',
  losses = 'losses',
  numberOfLosses = 'howManyLosses',
  lossDate = 'lastLossDate',
  animalInjury = 'hadAnimalInjury',
  coverageMasonry = 'coverageMasonry',
  Arson = 'Arson',
}

export enum QUESTION_KEY_MAPPING_REVERSE {
  dwellingType = 'dwellingType',
  isManufacturedHome = 'manufacturedMobileHome',
  mustHaveInsurance = 'mandatoryInsuranceRequirement',
  losses = 'losses',
  howManyLosses = 'numberOfLosses',
  lastLossDate = 'lossDate',
  hadAnimalInjury = 'animalInjury',
  coverageMasonry = 'coverageMasonry',
  Arson = 'Arson',
}

export const PANELS = [
  {
    key: 'hasReplacementCost',
    heading: 'Replacement Cost',
    text:
      'Coverage includes reimbursement for the actual cost of replacing belongings.',
    isEditable: false,
  },
  {
    key: 'hasIdentityFraud',
    heading: 'Fraud Expense Protection',
    text:
      'Identity fraud expense coverage includes up to $15,000 for your expenses as a result of being a victim of identity fraud. A $100 deductible applies.',
    isEditable: true,
  },
  {
    key: 'hasWaterBackup',
    heading: 'Water Sewer Backup',
    text:
      'Coverage includes up to $2,500 in damage if a sewer or drain backs up into your home. A $250 deductible applies',
    isEditable: true,
  },
];

export enum DiscloserTypes {
  FRAUD = 'fraud',
  ELECTRONIC_BUSSINESS_CONSENT = 'ebc',
  LEGAL_NOTICE = 'legal',
  UNDERWRITTERS = 'underwriters',
  PRIVACY_POLICY = 'privacypolicy',
  RAGULATORY_NOTICE = 'regulatorynotice',
  CONSUMER_RIGHTS = 'consumerrights',
  COVERAGE_DESCRIPTION = 'coveragedescription',
  ACCESSIBILITY = 'accessibility',
  CANCELATION_NOTICE = 'cancelationnotice',
  PAY_AUTHORIZATION = 'payauthorization',
}

export const ADDITIONAL_PARTIES_PANELS = [
  {
    key: 'hasInterestedParty',
    heading: 'Interested Party',
    text:
      "This is typically your landlord or property manager. Put down their information and we'll automatically notify them that you've got coverage.",
    isEditable: true,
    linkText: 'Read more',
  },
];
