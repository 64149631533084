import { ModelItem } from 'models/ModelItem';
import { action, observable } from 'mobx';
import Store from './Store';
import { User } from '../models/entities/UserModel';
import { PaginatedModelList } from '../models/PaginatedModelList';
import ApiRoutes from '../routes/api/ApiRoutes';

export enum UserStoreIncludes {
  BANKS = 'banks',
  DOCUMENTS = 'documents',
  WAITING_LIST_APPLICANT = 'waiting_list_applicants',
}

export class UserStore extends Store<User> {
  constructor() {
    super();
    User._store = this;
  }

  @observable user = new ModelItem<User>(User);

  @observable userPaginatedList = new PaginatedModelList<User>(User);

  @observable userLoading = true;

  @action
  setLoadingUser(val: boolean) {
    this.userLoading = val;
  }

  @action
  addUserPaginatedListValue(val: User) {
    this.userPaginatedList.appendItem(val);
  }

  @action
  async fetchUsers(params?: { [key: string]: any }, forceRefresh?: boolean) {
    return this.userPaginatedList.load(ApiRoutes.admin.users.list, params, {
      forceRefresh,
    });
  }

  @action async fetchById(id: number) {
    this.setLoadingUser(true);
    const response = await this.apiService.get(ApiRoutes.admin.users.show(id));
    if (response.data) {
      const alreadyPresent = this.userPaginatedList.items.find(
        (item) => item.id === response.data.id,
      );
      if (!alreadyPresent) {
        const user = User.fromJson(response.data) as User;
        User.getStore().push(user);
        this.userPaginatedList.appendItem(user);
      }
    }
    this.setLoadingUser(false);
    return response.data;
  }

  @action showById(id: number) {
    return User.getOrNew(id) as User;
  }

  @action async getUserProfile(user_id: number) {
    try {
      await this.user.load(ApiRoutes.user.profile(user_id));
      return this.user.item;
    } catch (e) {
      this.user.setLoaded(false);
      this.user.setLoading(false);
      return e;
    }
  }

  @action async updateUserProfile(
    user_id: number,
    body: { [key: string]: any },
  ) {
    const formData = new FormData();
    Object.keys(body).forEach((key) => formData.append(key, body[key]));
    const response = await this.apiService.patch(
      ApiRoutes.user.editProfile(user_id),
      formData,
    );
    const user = User.fromJson(response.data) as User;
    this.user.setItem(user);
    return user;
  }

  @action
  clearStore(): void {
    this.userPaginatedList = new PaginatedModelList<User>(User);
  }
}
