import OnboardingPage from 'components/Dashboard/renter/OnboardingPage/OnboardingPage';
import PageNotFound from 'components/PageNotFound/PageNotFound';
import {
  LazyLoadedAppContainer,
  LazyLoadedInsuranceContainer,
  LazyLoadedRenterContainer,
  LazyLoadedUserContainer,
  LazyLoadedWaitingListContainer,
} from 'containers';
import ProtectedRoute from 'guards/ProtectedRoute';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import WebRoutes from './WebRoutes';

const ProtectedRoutes = () => {
  return (
    <Switch>
      <ProtectedRoute path="/app/*" component={LazyLoadedAppContainer} />
      <ProtectedRoute
        path={WebRoutes.app.upload.onboarding}
        component={OnboardingPage}
      />
      <ProtectedRoute
        path="/rental-application/*"
        component={LazyLoadedRenterContainer}
      />
      <ProtectedRoute
        path="/documents/*"
        component={LazyLoadedInsuranceContainer}
      />
      <ProtectedRoute
        path="/waiting-list/apply*"
        component={LazyLoadedWaitingListContainer}
      />
      <ProtectedRoute path="/user/*" component={LazyLoadedUserContainer} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};

export default React.memo(ProtectedRoutes);
