/* eslint-disable import/no-cycle */
import { notification } from 'antd';
import { ApplicationDocumentStatus } from 'enums/ApplicationDocumentStatus';
import { ApplicationInsuranceStatus } from 'enums/ApplicationInsuranceStatus';
import { ChatCategory } from 'enums/ChatCategory.enum';
import { action, observable } from 'mobx';
import moment from 'moment/moment';
import { PHASteps } from 'constants/constants';
import { DataFinitiPropertyDetail } from 'interfaces/DataFinitiPropertyDetail.interface';
import { Application } from '../models/entities/ApplicationModel';
import { Unit } from '../models/entities/UnitModel';
import { ModelItem } from '../models/ModelItem';
import { PaginatedModelList } from '../models/PaginatedModelList';
import ApiRoutes from '../routes/api/ApiRoutes';
import { Store } from './Store';
import { RentDeterminationChat } from '../models/entities/RentDeterninationChatModel';

export class ApplicationStore extends Store<Application> {
  constructor() {
    super();
    Application._store = this;
  }

  @observable applicationPaginatedList = new PaginatedModelList<Application>(
    Application,
  );

  @observable
  unassignedApplicationPaginatedList = new PaginatedModelList<Application>(
    Application,
  );

  @observable
  insurancedApplicationPaginatedList = new PaginatedModelList<Application>(
    Application,
  );

  @observable applicationItem = new ModelItem<Application>(Application);

  @observable suggestedUnitList = new PaginatedModelList<Unit>(Unit);

  @observable fetchApplicationDocuments = true;

  @observable fetchRentBasedMAIAReports = true;

  @observable showGenerateMAIAScoreOption = true;

  @observable isAgentFilterApplied = false;

  @observable suggestedUnitStatus: 'processing' | 'processed' | null = null;

  get applications(): Application[] {
    return this.applicationPaginatedList.items;
  }

  get application(): Application {
    return this.applicationItem.item;
  }

  @action
  async fetchApplicationFromId(
    id: number,
    params?: { [key: string]: any },
    forceRefresh?: boolean,
  ) {
    return this.applicationItem.load(ApiRoutes.applications.show(id), params, {
      forceRefresh,
    });
  }

  async loadApplications(params?: { [key: string]: any }) {
    return this.applicationPaginatedList.load(
      ApiRoutes.applications.list,
      params,
    );
  }

  @action
  async toggleIsAgentFilterApplied(value: boolean) {
    this.isAgentFilterApplied = value;
  }

  @action
  async updateApplication(id: number, body: { [key: string]: any }) {
    const application = Application.getOrNew(id) as Application;
    application.setUpdating(true);
    const response = await this.apiService.patch(
      ApiRoutes.applications.show(id),
      body,
    );
    application.updateFromJson(response.data);
    application.setUpdating(false);
    return application;
  }

  @action
  async updateApplicationIntoStore(updatedApplication: Application) {
    const application = Application.getOrNew(
      updatedApplication.id,
    ) as Application;
    application.setUpdating(true);
    application.updateFromJson(updatedApplication);
    application.setUpdating(false);
  }

  @action
  async shareReport(id: number, name: string, email: string) {
    return this.apiService.post(ApiRoutes.applications.shareReport(id), {
      name,
      email,
    });
  }

  @action
  async getPaymentDetailsForApplication(applicationId: number) {
    const response = await this.apiService.get(
      ApiRoutes.applications.paymentDetails(applicationId),
    );
    const application = Application.getOrNew(applicationId) as Application;
    application.updateFromJson({
      ...application,
      fee_processed: response.fee_processed,
    });
  }

  @action
  async processApplicationFee(
    application_id: number,
    payment_intent_id: string,
    source_id: string,
    organisation_id: number,
  ): Promise<Application> {
    const response = await this.apiService.post(
      ApiRoutes.applications.processFee(application_id),
      { payment_intent_id, source_id, organisation_id },
    );
    const application = Application.getOrNew(application_id) as Application;
    application.updateFromJson(response.data);
    return application;
  }

  @action
  async processInternalInsuranceFee(
    application_id: number,
    subscription_id: string,
    source_id: string,
    frequency: string,
    start_date: string,
    organisation_id: number,
  ): Promise<Application> {
    const response = await this.apiService.post(
      ApiRoutes.applications.processInternalInsuranceFee(application_id),
      {
        subscription_id,
        source_id,
        frequency,
        start_date,
        organisation_id,
      },
    );
    const application = Application.getOrNew(application_id) as Application;
    application.updateFromJson(response.data);
    return application;
  }

  // REMOVED USAGE
  // @action
  // async makeApplicationFeePayment(
  //   applicationId: number,
  //   sourceId: string,
  //   isExisting: boolean,
  // ): Promise<Application> {
  //   const response = await this.apiService.post(
  //     ApiRoutes.applications.makeApplicationFeePayment(applicationId),
  //     { source_id: sourceId, is_existing: isExisting },
  //   );
  //   const application = Application.getOrNew(applicationId) as Application;
  //   application.updateFromJson(response.data);
  //   return application;
  // }

  @action
  async generatePaymentIntent(id: number) {
    return this.apiService.post(
      ApiRoutes.applications.generatePaymentIntent(id),
    );
  }

  @action
  async generateSubscriptionPaymentIntent(id: number, data: any) {
    return this.apiService.post(
      ApiRoutes.applications.generateSubscriptionPaymentIntent(id),
      data,
    );
  }

  @action
  async getLeaseDocumentUrl(applicationId: number) {
    return this.apiService.get(
      ApiRoutes.applications.getLeaseDocumentUrl(applicationId),
    );
  }

  @action
  async getSubscriptionStatus(applicationId: number) {
    return this.apiService.get(
      ApiRoutes.applications.getSubscriptionStatus(applicationId),
    );
  }

  @action
  async getPreviousApplication(userId) {
    return this.apiService.get(
      ApiRoutes.applications.getPreviousApplication(userId),
    );
  }

  @action
  async submitComps(applicationId: number, body: { [key: string]: any }) {
    const application = Application.getOrNew(applicationId) as Application;
    application.setUpdating(true);
    const response = await this.apiService.post(
      ApiRoutes.applications.submitComps(applicationId),
      body,
    );
    application.updateFromJson(response.data);
    application.setUpdating(false);
    return application;
  }

  @action
  async submitPHAComps(applicationId: number, body: { [key: string]: any }) {
    const application = Application.getOrNew(applicationId) as Application;
    application.setUpdating(true);
    const response = await this.apiService.post(
      ApiRoutes.applications.submitPHAComps(applicationId),
      body,
    );
    application.updateFromJson(response.data);
    application.setUpdating(false);
    return application;
  }

  @action async updatePhaStep(applicationId: number) {
    const application = Application.getOrNew(applicationId) as Application;
    const response = await this.apiService.post(
      ApiRoutes.applications.pha.updateStep(applicationId),
    );
    application.updateFromJson(response.data);
    return application;
  }

  @action
  async getNewApplicationDocumentSignedUrl(
    file: File,
    applicationId: number,
    userId: number,
    docType: string,
  ): Promise<any> {
    const body = {
      file_name: file.name,
      content_type: file.type,
      user_id: userId,
      doc_type: docType,
    };

    const response = await this.apiService.post(
      ApiRoutes.applicationDocuments.signedUrlForNew(applicationId),
      body,
    );
    return response;
  }

  @action
  async uploadApplicationDocument(
    url: string,
    file: File,
    applicationId: number,
    documentId: number,
    onUploadProgress: any,
  ): Promise<string> {
    await this.apiService.put(
      url,
      file,
      false,
      false,
      {
        'Content-Type': file.type,
        'Content-Disposition': `filename=${file.name}`,
      },
      '',
      '',
      onUploadProgress,
    );
    this.toggleFetchApplicationDocument(true);
    return url.split('?')[0];
  }

  @action
  async completeApplicationDocumentUpload(
    applicationId: number,
    documentId: number,
    uploadCount?: number,
  ) {
    await this.apiService.post(
      ApiRoutes.applicationDocuments.complete(applicationId, documentId),
      {
        upload_count: uploadCount,
      },
    );
  }

  @action
  toggleFetchApplicationDocument(val: boolean) {
    this.fetchApplicationDocuments = val;
  }

  @action
  toggleFetchRentBasedMAIAReports(val: boolean) {
    this.fetchRentBasedMAIAReports = val;
  }

  @action
  toggleShowGenerateMAIAScoreOption(val: boolean) {
    this.showGenerateMAIAScoreOption = val;
  }

  @action
  async getApplicationDocuments(
    applicationId: number,
    status: ApplicationDocumentStatus,
  ) {
    const response = await this.apiService.get(
      ApiRoutes.applicationDocuments.list(applicationId),
      { status },
    );

    return response.data;
  }

  @action
  async updateDocumentDescription(
    applicationId: number,
    docId: number,
    fileUploadId: number,
    desc: string,
  ): Promise<void> {
    await this.apiService.put(
      ApiRoutes.applicationDocumentUploads.applicationDocumentUpload(
        applicationId,
        docId,
        fileUploadId,
      ),
      {
        description: desc,
      },
    );
    this.toggleFetchApplicationDocument(true);
  }

  @action
  async confirmDocumentUpload(
    applicationId: number,
    docId: number,
    fileUploadId: number,
  ): Promise<void> {
    await this.apiService.put(
      ApiRoutes.applicationDocumentUploads.confirmUpload(
        applicationId,
        docId,
        fileUploadId,
      ),
    );
  }

  @action
  async requestDocument(
    docName: string,
    request_description: string,
    userId: number,
    applicationId: number,
    upload_against?: number,
  ): Promise<any> {
    const response = await this.apiService.post(
      ApiRoutes.applicationDocuments.list(applicationId),
      {
        user_id: userId,
        request_description,
        request_message: docName,
        upload_against,
      },
    );
    if (response.data) {
      this.application.updateFromJson(response.data);
      notification.success({
        message: `${docName} document requested successfully.`,
        duration: 2,
      });
    }
    return response;
  }

  @action
  async getExistingApplicationDocumentSignedUrl(
    applicationId: number,
    docId: number,
    file: File,
  ): Promise<any> {
    const body = {
      file_name: file.name,
      content_type: file.type,
    };
    return this.apiService.post(
      ApiRoutes.applicationDocuments.signedUrlForExisting(applicationId, docId),
      body,
    );
  }

  @action
  async rejectApplication(applicationId: number, body: { [key: string]: any }) {
    const application = Application.getOrNew(applicationId) as Application;
    application.setUpdating(true);
    const response = await this.apiService.post(
      ApiRoutes.applications.rejectApplication(applicationId),
      body,
    );
    application.updateFromJson(response.data);
    application.setUpdating(false);
    return application;
  }

  @action
  async getSuggestedUnits(applicationId: number) {
    const response = await this.apiService.get(
      ApiRoutes.applications.applicationSuggestions.suggestions(applicationId),
    );
    const units = [];
    response.data.units.data.forEach((unit) => {
      units.push(Unit.fromJson(unit) as Unit);
    });
    return {
      score: response.data.score,
      units,
    };
  }

  @action
  async getApplicationTermsAndConditions(unitId: number): Promise<string> {
    const response = await this.apiService.get(
      ApiRoutes.units.termsConditions(unitId),
    );
    return response.tc_url;
  }

  @action
  async reSubmitApplication(
    applicationId: number,
    unitId: number,
    isDTCSuggestedUnit?: boolean,
  ): Promise<Application> {
    const response = await this.apiService.post(
      ApiRoutes.applications.applicationSuggestions.resubmit(applicationId),
      { unit_id: unitId, isDTCSuggestedUnit },
    );
    const application = Application.fromJson(response.data) as Application;
    this.applicationPaginatedList.appendItem(application);
    return application;
  }

  @action
  async attachGuestCard(
    application: Application,
    body: { [key: string]: string | any },
  ) {
    const response = await this.apiService.post(
      ApiRoutes.applications.guestCards.attach(application.id),
      body,
    );

    // Attach Guest Card to primary applicant
    const primaryApplicant = application.applicants.items.find(
      (applicant) => applicant.is_primary,
    );
    primaryApplicant.guest_card_id = response.data.id;
    primaryApplicant.guestCard = response.data;
  }

  @action
  async createGuestCard(application: Application) {
    const response = await this.apiService.post(
      ApiRoutes.applications.guestCards.create(application.id),
    );

    // Attach Guest Card to primary applicant
    const primaryApplicant = application.applicants.items.find(
      (applicant) => applicant.is_primary,
    );
    primaryApplicant.guest_card_id = response.data.id;
    primaryApplicant.guestCard = response.data;
  }

  @action
  async getQueuedDocs(applicationId: number) {
    const response = await this.apiService.get(
      ApiRoutes.applications.queuedDocs(applicationId),
    );
    return response.data;
  }

  @action
  async fetchUnassignedApplications(params?: {
    [key: string]: any;
  }): Promise<void> {
    return this.unassignedApplicationPaginatedList.load(
      ApiRoutes.unAssignedApplication.list,
      params,
    );
  }

  @action
  async fetchInsurancedApplications(params?: {
    [key: string]: any;
  }): Promise<void> {
    return this.insurancedApplicationPaginatedList.load(
      ApiRoutes.insurancedApplications.list,
      params,
    );
  }

  @action
  async updateApplicationPhaAgent(
    application: Application,
    pha_agent_id: number,
  ): Promise<void> {
    application.setUpdating(true);
    const response = await this.apiService.put(
      ApiRoutes.unAssignedPhaAgentApplication.assignToPhaAgent(application.id),
      {
        pha_agent_id,
      },
    );
    application.setUpdating(false);
    application.updateFromJson(response.data);
    this.unassignedApplicationPaginatedList.removeItem(application);
    this.applicationPaginatedList.setItems([
      application,
      ...this.applicationPaginatedList.items,
    ]);
  }

  @action
  async updateApplicationAgent(
    application: Application,
    agent_id: number,
  ): Promise<void> {
    application.setUpdating(true);
    const response = await this.apiService.put(
      ApiRoutes.unAssignedApplication.assignToAgent(application.id),
      {
        agent_id,
      },
    );
    application.setUpdating(false);
    application.updateFromJson(response.data);
    this.unassignedApplicationPaginatedList.removeItem(application);
    if (this.isAgentFilterApplied) {
      this.applicationPaginatedList.removeItem(application);
    } else {
      this.applicationPaginatedList.setItems([
        application,
        ...this.applicationPaginatedList.items,
      ]);
    }
  }

  @action async updateApplicationManagerOrAgent(
    application: Application,
    agent_id?: number,
    manager_id?: number,
  ) {
    const response = await this.apiService.put(
      ApiRoutes.unAssignedApplication.assignManagerOrAgent(application.id),
      {
        agent_id,
        manager_id,
      },
    );
    if (response.data) {
      application.updateFromJson(response.data);
    }
    return response;
  }

  @action
  async deleteApplicationDocument(
    applicationId: number,
    docId: number,
    fileUploadId: number,
  ) {
    await this.apiService.delete(
      ApiRoutes.applicationDocumentUploads.applicationDocumentUpload(
        applicationId,
        docId,
        fileUploadId,
      ),
    );
  }

  @action
  async fetchDTCSuggestions(
    application_id: number,
    params: { [key: string]: any },
  ): Promise<any> {
    const res = await this.apiService.get(
      ApiRoutes.applications.applicationSuggestions.dtcSuggestions(
        application_id,
      ),
      params,
    );
    return res?.data.map((item) => Unit.fromJson(item));
  }

  @action
  async fetchDTCSuggestionStatus(application_id: number): Promise<any> {
    const res = await this.apiService.get(
      ApiRoutes.applications.applicationSuggestions.dtcSuggestionsStatus(
        application_id,
      ),
    );
    this.suggestedUnitStatus = res.status;
    return res;
  }

  @action
  async fetchCompListings(
    application_id: number,
  ): Promise<DataFinitiPropertyDetail[]> {
    const res = await this.apiService.get(
      ApiRoutes.applications.dataFinitiComps(application_id),
    );
    return res?.data;
  }

  @action
  async getRentBasedMAIAScore(
    applicationId: number,
    body: { [key: string]: any },
  ): Promise<number> {
    const res = await this.apiService.post(
      ApiRoutes.applications.rentBased.getMAIAScore(applicationId),
      body,
    );

    return res.score;
  }

  @action
  async generateRentBasedMAIAScore(
    applicationId: number,
    body: { [key: string]: any },
  ): Promise<void> {
    try {
      const application = Application.getOrNew(applicationId) as Application;
      const response = await this.apiService.post(
        ApiRoutes.applications.rentBased.generateMAIAReport(applicationId),
        body,
      );
      application.updateFromJson(response.data);
      return response;
    } catch (e) {
      notification.error({
        message: (e as any)?.message || 'Something went wrong',
        duration: 2.5,
      });
      throw e;
    }
  }

  @action
  async getRentBasedMAIAReport(applicationId: number): Promise<any> {
    const response: any = await this.apiService.get(
      ApiRoutes.applications.rentBased.getMAIAReport(applicationId),
    );
    this.toggleFetchRentBasedMAIAReports(false);
    if (response.data) {
      this.toggleShowGenerateMAIAScoreOption(false);
    }

    return response;
  }

  async generateMAIAReport(application_id: number): Promise<void> {
    await this.apiService.post(
      ApiRoutes.applications.generateReport(application_id),
    );
    const application = Application.getOrNew(application_id) as Application;
    application.updateFromJson({
      id: application_id,
      report_url: null,
      matrix_score: null,
      mini_report_url: null,
    });
  }

  async triggerMAIAReport(application_id: number): Promise<void> {
    const application = Application.getOrNew(application_id) as Application;
    application.setUpdating(true);
    const response = await this.apiService.post(
      ApiRoutes.applications.triggerMAIAReport(application_id),
    );
    if (response.data) {
      application.updateFromJson(response.data);
    }
    application.setUpdating(false);
  }

  @action
  async uploadInsuranceFile(file, applicationId: number): Promise<void> {
    const formData = new FormData();
    formData.append('insurance', file);
    return this.apiService.post(
      ApiRoutes.insurance.upload(applicationId),
      formData,
    );
  }

  @action
  async acceptApplicationForUnitData(
    applicationId: number,
    unitDataId: number,
  ): Promise<any> {
    const application = Application.getOrNew(applicationId) as Application;
    application.setUpdating(true);
    const response = await this.apiService.post(
      ApiRoutes.applications.rentBased.acceptByUnitDataId(
        applicationId,
        unitDataId,
      ),
    );
    application.updateFromJson(response.data);
    application.setUpdating(false);

    return response;
  }

  async fetchInsuranceDetails(applicationId: number) {
    return this.apiService.get(ApiRoutes.insurance.details(applicationId));
  }

  @action
  async fetchPaymentSources(showAllCards: boolean, org_id: number) {
    return this.apiService.get(ApiRoutes.me.listPaymentSources, {
      show_all: showAllCards,
      org_id,
    });
  }

  @action
  async createExternalInsuranceFeePayment(
    applicationId: number,
    body: { [key: string]: any },
  ) {
    return this.apiService.post(
      ApiRoutes.applications.externalInsuranceFeePayment(+applicationId),
      body,
    );
  }

  // REMOVED USAGE
  // @action
  // async createInternalInsuranceFeePayment(
  //   applicationId: number,
  //   body: { [key: string]: any },
  // ) {
  //   return this.apiService.post(
  //     ApiRoutes.applications.internalInsuranceFeePayment(+applicationId),
  //     body,
  //   );
  // }

  @action
  async requestInsurance(application: Application) {
    const response = await this.apiService.post(
      ApiRoutes.insurance.request(application.id),
    );
    if (response.data) {
      application.insurance.setItem(response.data);
    }
    return response;
  }

  @action
  async updateInsuranceStatus(
    application: Application,
    body: { [key: string]: any },
    showingInsurancedApplication: boolean,
  ) {
    const response = await this.apiService.patch(
      ApiRoutes.insurance.update(+application.id),
      body,
    );

    if (response.data) {
      application.insurance.setItem(response.data);
    }

    if (showingInsurancedApplication) {
      this.insurancedApplicationPaginatedList.removeItem(application);
    } else if (response.data.status === ApplicationInsuranceStatus.ACTIVE) {
      this.insurancedApplicationPaginatedList.removeItem(application);
    }

    return response;
  }

  // key in the body should be present in Application Model
  @action
  async updateApplicationValue(
    id: number,
    body: { [key: string]: any },
  ): Promise<Application> {
    const application = Application.getOrNew(id) as Application;
    application.setUpdating(true);
    Object.keys(body).forEach((key) => {
      application[key] = body[key];
    });
    application.setUpdating(false);
    return application;
  }

  @action
  async deleteInspectionReport(applicationId: number): Promise<void> {
    const response = await this.apiService.delete(
      ApiRoutes.applications.deleteInspectionReport(applicationId),
    );
    if (response.success) {
      this.updateApplicationValue(applicationId, {
        inspection_report_url: null,
      });
    }
    return response;
  }

  @action
  async fetchChat(application: Application) {
    const key = `${application.id}_CHAT_LAST_FETCHED_AT`;
    localStorage.setItem(key, moment().utc().toDate().toString());
    await application.chats.load(
      ApiRoutes.applications.rent_determination.chats(application.id).list,
    );
  }

  @action
  async loadMoreChats(application: Application) {
    await application.chats.loadNext();
  }

  @action
  async sendMessage(
    message_body: string | null,
    category: string,
    application: Application,
    meta = null,
    challenged_against = undefined,
  ) {
    const response = await this.apiService.post(
      ApiRoutes.applications.rent_determination.chats(application.id).create,
      { message_body, category, meta, challenged_against },
    );
    if (response.data) {
      if (category === ChatCategory.RENT_PROPOSAL) {
        application.updateFromJson({
          ...application,
          can_create_new_proposal: false,
        });
      }

      application.chats.items.forEach((_chat) => {
        if (_chat.id === challenged_against) {
          _chat.updateFromJson({
            ..._chat,
            meta: { ..._chat.meta, status: 'rejected' },
          });
        }
      });
    }
    const items = [response.data, ...application.chats.items];
    application.chats.setItems(items);
  }

  @action
  async markAsRead(application: Application) {
    const applicationId = application.id;
    const key = `${applicationId}_CHAT_LAST_FETCHED_AT`;
    try {
      let lte = localStorage.getItem(key);
      if (!lte) {
        lte = moment().utc().toDate().toString();
      }
      await this.apiService.post(
        ApiRoutes.applications.rent_determination.chats(applicationId)
          .markAsRead,
        { lte },
      );
      application.setUpdating(true);
      application.updateFromJson({
        ...application,
        unread_count: 0,
      });
      application.setUpdating(false);
      localStorage.removeItem(key);
      await this.markChatInStoreAsRead(application);
    } catch (e) {
      console.log(e);
    }
  }

  @action
  async markChatInStoreAsRead(application: Application) {
    application.chats.items.forEach((_chat) => {
      if (_chat.application_id === application.id && !_chat.read) {
        _chat?.updateFromJson({
          ..._chat,
          read: true,
        });
      }
    });
  }

  @action async acceptProposal(application: Application, chatId: number) {
    application.setUpdating(true);
    const response = await this.apiService.post(
      ApiRoutes.applications.rent_determination
        .chats(application.id)
        .acceptProposal(chatId),
    );
    if (response.data) {
      window.location.reload();
    }

    return response;
  }

  @action
  async applyCoupon(applicationId: number, coupon_code: string) {
    const response = await this.apiService.post(
      ApiRoutes.applications.applyCoupon(applicationId),
      {
        coupon_code,
      },
    );
    const application = Application.getOrNew(applicationId) as Application;
    application.updateFromJson(response.data);
    return application;
  }

  @action
  async updateApplicationUnit(application: Application, unitId: number) {
    const response = await this.apiService.patch(
      ApiRoutes.applications.updateApplicationUnit(application.id),
      {
        unit_id: unitId,
      },
    );
    if (response.data) {
      application.updateFromJson(response.data);
    }
    return response;
  }

  @action
  async mergeApplications(data: {
    application_id_x: number;
    application_id_y: number;
    primary_applicant_id: number;
    co_applicant_ids: number[];
    delete_pending_invites: boolean;
  }): Promise<Application> {
    const {
      application_id_x,
      application_id_y,
      primary_applicant_id,
      co_applicant_ids,
      delete_pending_invites,
    } = data;
    const response = await this.apiService.post(ApiRoutes.applications.merge, {
      application_id_x,
      application_id_y,
      primary_applicant_id,
      co_applicant_ids,
      delete_pending_invites,
    });
    if (response.data) {
      const application = Application.getOrNew(response.data.id) as Application;
      application.updateFromJson(response.data);
      await this.applicationItem.load(
        ApiRoutes.applications.show(response.data.id),
        {},
        {
          forceRefresh: true,
        },
      );
      return this.applicationItem.item;
    }
    return response.data;
  }

  @action
  removeApplicationFromStore(applicationId: number) {
    const newApplications = this.applicationPaginatedList.items.filter(
      (application) => application.id !== applicationId,
    );
    this.applicationPaginatedList.setItems(newApplications);
  }

  @action
  async batchCreate(data: {
    personalInformationData: any;
    residenceHistoryData: any;
    questionnaireData: any;
    couponCodeData: any;
    createAppWithExistingUser: boolean;
  }) {
    const response = await this.apiService.post(
      ApiRoutes.applications.batchCreate,
      data,
    );
    const application = Application.fromJson(response.data) as Application;
    this.applicationPaginatedList.items.push(application);
    return application;
  }

  async deleteDocRequestAndProcess(
    applicationId: number,
    userId: number,
    requestMessage: string,
  ): Promise<void> {
    await this.apiService.post(
      ApiRoutes.applicationDocuments.deleteRequestAndProcess(applicationId),
      { userId, requestMessage },
    );
  }

  async deleteDocRequest(
    applicationId: number,
    userId: number,
    requestMessage: string,
  ): Promise<void> {
    await this.apiService.post(
      ApiRoutes.applicationDocuments.deleteRequest(applicationId),
      { userId, requestMessage },
    );
  }

  @action
  async getApplicationDetailForUser() {
    return this.applicationItem.load(ApiRoutes.me.moveInDate);
  }

  @action
  clearStore(): void {
    for (const application of [
      this.applicationItem.item,
      ...this.applicationPaginatedList.items,
    ]) {
      if (!application) {
        // eslint-disable-next-line no-continue
        continue;
      }
      /**
       * Clearing Application Chats
       */
      application.chats = new PaginatedModelList<RentDeterminationChat>(
        RentDeterminationChat,
      );
    }
    this.applicationPaginatedList = new PaginatedModelList<Application>(
      Application,
    );
    this.unassignedApplicationPaginatedList = new PaginatedModelList<
      Application
    >(Application);
    this.insurancedApplicationPaginatedList = new PaginatedModelList<
      Application
    >(Application);
    this.applicationItem = new ModelItem<Application>(Application);
    this.suggestedUnitList = new PaginatedModelList<Unit>(Unit);
    this.fetchApplicationDocuments = false;
  }
}
