import { observable } from 'mobx';
import { Model } from '../Model';
import { PHAStore } from 'stores/PHAStore';

export class PHAInviteAnalytics extends Model {
  static _store: PHAStore;

  @observable id: number;

  @observable event: string;

  @observable event_occured_at: Date;
}
