import { action } from 'mobx';
import Store from './Store';
import { BankTransaction } from 'models/entities/BankTransactionModel';

export class BankTransactionStore extends Store<BankTransaction> {
  constructor() {
    super();
    BankTransaction._store = this;
  }

  @action
  clearStore(): void {}
}