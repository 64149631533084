import { action, computed, observable } from 'mobx';

import moment from 'moment';
import Store from './Store';
import { ModelItem } from '../models/ModelItem';
import ApiRoutes from '../routes/api/ApiRoutes';
import { PaginatedModelList } from '../models/PaginatedModelList';
import { GuestCard } from '../models/entities/GuestCardModel';

export class GuestCardStore extends Store<GuestCard> {
  constructor() {
    super();
    GuestCard._store = this;
  }

  @observable guestCardList = new PaginatedModelList<GuestCard>(GuestCard);

  @observable guestCardItem = new ModelItem<GuestCard>(GuestCard);

  @computed get guestCards() {
    return this.guestCardList.items;
  }

  @computed get guestCard() {
    return this.guestCardItem.item;
  }

  @action
  async fetchGuestCards(params?: { [key: string]: any }) {
    await this.guestCardList.load(ApiRoutes.guestCards.list, params);
    const uniqueGuestCards = [
      ...new Map(
        this.guestCardList.items.map((item) => [item.email, item]),
      ).values(),
    ];
    return this.guestCardList.setItems(uniqueGuestCards);
  }

  @action async fetchGuestCardFromId(id: number) {
    return this.guestCardItem.load(ApiRoutes.guestCards.show(id));
  }

  @action
  async updateElement(guestCard: GuestCard, body: { [key: string]: any }) {
    guestCard.setUpdating(true);
    const response = await this.apiService.patch(
      ApiRoutes.guestCards.show(guestCard.id),
      body,
    );
    guestCard.setUpdating(false);
    guestCard.updateFromJson(response.data);
    return guestCard;
  }

  @action
  async createGuestCard(body: { [key: string]: any }) {
    const formData = new FormData();
    Object.keys(body).forEach((key) => formData.append(key, body[key]));
    const response = await this.apiService.post(
      ApiRoutes.guestCards.list,
      formData,
    );
    const guestCard = GuestCard.fromJson(response.data) as GuestCard;
    // TODO: add only if filters allows.
    this.guestCardList.appendItem(guestCard);
    return guestCard;
  }

  @action
  async fetchChats(guestCard: GuestCard) {
    const key = `${guestCard.id}_CHAT_LAST_FETCHED_AT`;
    localStorage.setItem(key, moment().utc().toDate().toString())
    await guestCard.chats.load(ApiRoutes.guestCards.chats(guestCard.id).list);
  }

  @action
  async loadMoreChats(guestCard: GuestCard) {
    await guestCard.chats.loadNext();
  }

  @action
  async sendMessage(guestCard: GuestCard, message_body: string) {
    const response = await this.apiService.post(
      ApiRoutes.guestCards.chats(guestCard.id).create,
      { message_body },
    );
    const items = [response.data, ...guestCard.chats.items];
    guestCard.chats.setItems(items);
  }

  @action
  async markAsRead(guestCard: GuestCard) {
    const guestCardId = guestCard.id;
    const key = `${guestCardId}_CHAT_LAST_FETCHED_AT`;
    try {
      let lte = localStorage.getItem(key);
      if (!lte) {
        lte = moment().utc().toDate().toString();
      }
      await this.apiService.post(
        ApiRoutes.guestCards.chats(guestCardId).markAsRead,
        { lte },
      );
      guestCard.setUpdating(true);
      guestCard.updateFromJson({
        ...guestCard,
        unread_count: 0,
      });
      guestCard.setUpdating(false);
      localStorage.removeItem(key);
      await this.markChatsInStoreAsRead(guestCard);
    } catch (e) {
      console.error(e);
    }
  }

  @action
  async markChatsInStoreAsRead(guestCard: GuestCard) {
    guestCard.chats.items.forEach((_chat) => {
      if (_chat.guest_card_id === guestCard.id && !_chat.read) {
        _chat?.updateFromJson({
          ..._chat,
          read: true,
        });
      }
    });
  }

  @action
  clearStore(): void {
    this.guestCardList = new PaginatedModelList<GuestCard>(GuestCard);
    this.guestCardItem = new ModelItem<GuestCard>(GuestCard);
  }
}
