import { observable } from 'mobx';
import Model from '../Model';
import { DocumentStore } from '../../stores/DocumentStore';
import { DocumentType } from '../../enums/DocumentType';
import { TAX_RETURN_DOCS } from '../../constants/constants';
import { getFormattedDate } from '../../utils/helpers';

export class DocumentModel extends Model {
  static _store: DocumentStore;

  @observable document: string;

  @observable document_type: string;

  @observable document_type_id: number;

  @observable id: number;

  @observable key: string;

  @observable valid: boolean;

  @observable verified: boolean;

  @observable createdAt: string;

  @observable updatedAt: string;

  @observable applicant_data_id: number;

  @observable deleted_at: string;

  @observable last_polled_at: string;

  @observable output: string;

  @observable polling_attempts: number;

  @observable renter_id: number;

  @observable status: string;

  @observable source_meta: {
    application_id: number;
    waitlist_applicant_id: number;
    description: string;
  };

  @observable renter_documents_data_id: number;

  get isGovtId() {
    return this.document_type_id === DocumentType.GOVT_ID;
  }

  get isPayStub() {
    return this.document_type_id === DocumentType.PAY_STUB;
  }

  get isBankDocument() {
    return this.document_type_id === DocumentType.BANK_STATEMENT;
  }

  get isTaxReturn() {
    return TAX_RETURN_DOCS.includes(+this.document_type_id);
  }

  get isCreditReport() {
    return this.document_type_id === DocumentType.CREDIT_REPORT;
  }

  get isEvictionReport() {
    return this.document_type_id === DocumentType.EVICTION_REPORT;
  }

  get isCriminalReport() {
    return this.document_type_id === DocumentType.CRIMINAL_REPORT;
  }

  get getUpdatedAt() {
    return getFormattedDate(this.updated_at ? this.updated_at : this.updatedAt);
  }

  get isAdditionalDocument() {
    return this.document_type_id === DocumentType.ADDITIONAL_DOCUMENT;
  }

  get filename() {
    return this.document.split('/').pop();
  }
}
