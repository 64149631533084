import { observable } from 'mobx';
import { BankProviderType } from '../../pages/renter/bank-statements/constants';
import { BankStore } from '../../stores/BankStore';
import Model from '../Model';

export class Bank extends Model {
  static _store: BankStore;

  @observable id: number;

  @observable name: string;

  @observable provider: BankProviderType;
}
