import { action, observable } from 'mobx';
import Store from './Store';
import { ModelItem } from '../models/ModelItem';
import { ApplicationInsurance } from '../models/entities/ApplicationInsuranceModel';

export class ApplicationInsuranceStore extends Store<ApplicationInsurance> {
  constructor() {
    super();
    ApplicationInsurance._store = this;
  }

  @observable applicationInsuranceItem = new ModelItem<ApplicationInsurance>(
    ApplicationInsurance,
  );

  @action
  clearStore(): void {
    this.applicationInsuranceItem = new ModelItem<ApplicationInsurance>(
      ApplicationInsurance,
    );
  }
}
