export enum TrackerEventType {
  // Generic Application Actions
  PREVIOUS_BUTTON_CLICKED = 'PREVIOUS_BUTTON_CLICKED',
  NEXT_BUTTON_CLICKED = 'NEXT_BUTTON_CLICKED',
  SKIP_BUTTON_CLICKED = 'SKIP_BUTTON_CLICKED',
  FORM_FIELD_FOCUSED = 'FORM_FIELD_FOCUSED',
  FORM_FIELD_BLURRED = 'FORM_FIELD_BLURRED',
  DIRECTLY_NAVIGATED = 'DIRECTLY_NAVIGATED',

  // Auth Actions
  LOG_IN_BUTTON_CLICKED = 'LOG_IN_BUTTON_CLICKED',
  LOGGED_IN_SUCCESSFULLY = 'LOGGED_IN_SUCCESSFULLY',
  SIGN_UP_BUTTON_CLICKED = 'SIGN_UP_BUTTON_CLICKED',
  SIGNED_UP_SUCCESSFULLY = 'SIGNED_UP_SUCCESSFULLY',

  // Landing Page Actions
  LETS_BEGIN_BUTTON_CLICKED = 'LETS_BEGIN_BUTTON_CLICKED',

  // TnC Page Actions
  TNC_CHECKED = 'TNC_CHECKED',
  TNC_UN_CHECKED = 'TNC_UN_CHECKED',

  // Upload Govt ID Page Actions
  GOVERNMENT_ID_UPLOAD_STARTED = 'GOVERNMENT_ID_UPLOAD_STARTED',
  GOVERNMENT_ID_UPLOAD_COMPLETED = 'GOVERNMENT_ID_UPLOAD_COMPLETED',
  GOVERNMENT_ID_UPLOAD_FAILED = 'GOVERNMENT_ID_UPLOAD_FAILED',
  GOVERNMENT_ID_REMOVED = 'GOVERNMENT_ID_REMOVED',

  // Your Info Page
  APPLYING_GENERALLY_CHECKED = 'APPLYING_GENERALLY_CHECKED',
  APPLYING_GENERALLY_UN_CHECKED = 'APPLYING_GENERALLY_UN_CHECKED',

  // Payment Details Page Actions
  PAYMENT_INITIATED = 'PAYMENT_INITIATED',
  PAYMENT_COMPLETED = 'PAYMENT_COMPLETED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',

  // Credit Report Page Actions
  IMPORT_CREDIT_HISTORY_CLICKED = 'IMPORT_CREDIT_HISTORY_CLICKED',
  IMPORT_CREDIT_HISTORY_SUCCESS = 'IMPORT_CREDIT_HISTORY_SUCCESS',
  IMPORT_CREDIT_HISTORY_ERROR = 'IMPORT_CREDIT_HISTORY_ERROR',
  IMPORT_CREDIT_HISTORY_EXIT = 'IMPORT_CREDIT_HISTORY_EXIT',

  // Bank Account Page Actions
  BANK_SEARCH_OPENED = 'BANK_SEARCH_OPENED',
  BANK_SEARCH_CLOSED = 'BANK_SEARCH_CLOSED',
  SELECTED_BANK_FROM_LIST = 'SELECTED_BANK_FROM_LIST',
  BANK_NOT_LISTED_CLICKED = 'BANK_NOT_LISTED_CLICKED',
  DIRECTLY_UPLOAD_BANK_STATEMENT_CLICKED = 'DIRECTLY_UPLOAD_BANK_STATEMENT_CLICKED',
  CONNECT_TO_BANK_PROVIDER_CHOSEN = 'CONNECT_TO_BANK_PROVIDER_CHOSEN',
  MANUAL_UPLOAD_BANK_STATEMENT_CHOSEN = 'MANUAL_UPLOAD_BANK_STATEMENT_CHOSEN',
  BANK_CONNECTED_SUCCESSFULLY = 'BANK_CONNECTED_SUCCESSFULLY',
  BANK_CONNECTION_FAILED = 'BANK_CONNECTION_FAILED',
  REMOVE_BANK_CONNECTION_INITIATED = 'REMOVE_BANK_CONNECTION_INITIATED',
  REMOVE_BANK_CONNECTION_SUCCESSFUL = 'REMOVE_BANK_CONNECTION_SUCCESSFUL',
  REMOVE_BANK_CONNECTION_FAILED = 'REMOVE_BANK_CONNECTION_FAILED',
  BANK_STATEMENT_UPLOAD_INITIATED = 'BANK_STATEMENT_UPLOAD_INITIATED',
  BANK_STATEMENT_UPLOAD_SUCCESSFUL = 'BANK_STATEMENT_UPLOAD_SUCCESSFUL',
  BANK_STATEMENT_UPLOAD_FAILED = 'BANK_STATEMENT_UPLOAD_FAILED',
  REMOVE_BANK_STATEMENT_INITIATED = 'REMOVE_BANK_STATEMENT_INITIATED',
  REMOVE_BANK_STATEMENT_SUCCESSFUL = 'REMOVE_BANK_STATEMENT_SUCCESSFUL',
  REMOVE_BANK_STATEMENT_FAILED = 'REMOVE_BANK_STATEMENT_FAILED',
  NO_BANK_ACCOUNT_SELECTED = 'NO_BANK_ACCOUNT_SELECTED',
  NO_BANK_ACCOUNT_UNSELECTED = 'NO_BANK_ACCOUNT_UNSELECTED',

  // Income & Employment Page Actions
  EMPLOYER_SEARCH_OPENED = 'EMPLOYER_SEARCH_OPENED',
  EMPLOYER_SEARCH_CLOSED = 'EMPLOYER_SEARCH_CLOSED',
  SELECTED_COMPANY_FROM_LIST = 'SELECTED_COMPANY_FROM_LIST',
  COMPANY_NOT_LISTED_CLICKED = 'COMPANY_NOT_LISTED_CLICKED',
  DIRECTLY_UPLOAD_PAYSTUB_CLICKED = 'DIRECTLY_UPLOAD_PAYSTUB_CLICKED',
  SELF_EMPLOYED_SELECTED = 'SELF_EMPLOYED_SELECTED',
  SELF_EMPLOYED_UN_SELECTED = 'SELF_EMPLOYED_UN_SELECTED',
  CONNECT_TO_PAYROLL_PROVIDER_CHOSEN = 'CONNECT_TO_PAYROLL_PROVIDER_CHOSEN',
  MANUAL_UPLOAD_PAYSTUB_CHOSEN = 'MANUAL_UPLOAD_PAYSTUB_CHOSEN',
  INITIALIZED_CONNECTION_TO_PROVIDER = 'INITIALIZED_CONNECTION_TO_PROVIDER',
  REACHED_PROVIDER_LOGIN_PAGE = 'REACHED_PROVIDER_LOGIN_PAGE',
  STARTED_AUTHENTICATION_AGAINST_PROVIDER = 'STARTED_AUTHENTICATION_AGAINST_PROVIDER',
  DECLARED_AUTHENTICATED_BY_PROVIDER = 'DECLARED_AUTHENTICATED_BY_PROVIDER',
  DECLARED_UN_AUTHENTICATED_BY_PROVIDER = 'DECLARED_UN_AUTHENTICATED_BY_PROVIDER',
  PAYROLL_UPLOAD_INITIATED = 'PAYROLL_UPLOAD_INITIATED',
  PAYROLL_UPLOAD_SUCCESSFUL = 'PAYROLL_UPLOAD_SUCCESSFUL',
  PAYROLL_UPLOAD_FAILED = 'PAYROLL_UPLOAD_FAILED',
  REMOVE_UPLOADED_PAYSTUB_INITIATED = 'REMOVE_UPLOADED_PAYSTUB_INITIATED',
  REMOVE_UPLOADED_PAYSTUB_SUCCESSFUL = 'REMOVE_UPLOADED_PAYSTUB_SUCCESSFUL',
  REMOVE_UPLOADED_PAYSTUB_FAILED = 'REMOVE_UPLOADED_PAYSTUB_FAILED',
  REMOVE_PAYROLL_PROVIDER_INITIATED = 'REMOVE_PAYROLL_PROVIDER_INITIATED',
  REMOVE_PAYROLL_PROVIDER_SUCCESSFUL = 'REMOVE_PAYROLL_PROVIDER_SUCCESSFUL',
  REMOVE_PAYROLL_PROVIDER_FAILED = 'REMOVE_PAYROLL_PROVIDER_FAILED',

  // Tax Document Page Actions
  TAX_DOCUMENT_TYPE_CHANGED = 'TAX_DOCUMENT_TYPE_CHANGED',
  TAX_DOCUMENT_UPLOAD_INITIATED = 'TAX_DOCUMENT_UPLOAD_INITIATED',
  TAX_DOCUMENT_UPLOAD_SUCCESSFUL = 'TAX_DOCUMENT_UPLOAD_SUCCESSFUL',
  TAX_DOCUMENT_UPLOAD_FAILED = 'TAX_DOCUMENT_UPLOAD_FAILED',
  REMOVE_TAX_DOCUMENT_INITIATED = 'REMOVE_TAX_DOCUMENT_INITIATED',
  REMOVE_TAX_DOCUMENT_SUCCESSFUL = 'REMOVE_TAX_DOCUMENT_SUCCESSFUL',
  REMOVE_TAX_DOCUMENT_FAILED = 'REMOVE_TAX_DOCUMENT_FAILED',

  // Residence History Page Actions
  SAVE_RESIDENCE_HISTORY_INITIATED = 'SAVE_RESIDENCE_HISTORY_INITIATED',
  SAVE_RESIDENCE_HISTORY_SUCCESSFUL = 'SAVE_RESIDENCE_HISTORY_SUCCESSFUL',
  SAVE_RESIDENCE_HISTORY_FAILED = 'SAVE_RESIDENCE_HISTORY_FAILED',
  REMOVE_RESIDENCE_HISTORY_INITIATED = 'REMOVE_RESIDENCE_HISTORY_INITIATED',
  REMOVE_RESIDENCE_HISTORY_SUCCESSFUL = 'REMOVE_RESIDENCE_HISTORY_SUCCESSFUL',
  REMOVE_RESIDENCE_HISTORY_FAILED = 'REMOVE_RESIDENCE_HISTORY_FAILED',
  EDIT_RESIDENCE_HISTORY_BUTTON_CLICKED = 'EDIT_RESIDENCE_HISTORY_BUTTON_CLICKED',

  // Submission Page Actions
  SUBMIT_BUTTON_CLICKED = 'SUBMIT_BUTTON_CLICKED',
}
