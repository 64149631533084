import React from 'react';
import Image from 'assets/loader-bg.png';
import Logo from 'assets/logo-white.svg';
import HeadingText from '../HeadingText/HeadingText';

import './Loader.scss';

const Loader: React.FC = () => (
  <div className="loader flex items-center justify-center h-screen w-screen">
    <div className="loader-container fixed w-full">
      <div className="absolute h-full w-full -z-999">
        <img
          src={Image}
          alt="loading-bg"
          className="h-full w-full object-cover"
        />
        <div className="loader-blur absolute left-0 top-0 h-full w-full" />
      </div>
      <div className="flex items-center justify-center h-full py-16 px-4">
        <div className="flex flex-col items-center justify-center h-full w-full">
          <img src={Logo} alt="Logo" className="logo object-cover" />
          <HeadingText className="company-name mt-6" color="white">
            Matrix Rental Solutions
          </HeadingText>
        </div>
      </div>
    </div>
    <HeadingText className="loading-text text-center">Loading...</HeadingText>
  </div>
);

export default Loader;
